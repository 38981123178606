import { ChainProxy } from 'models/chains'
import { DataTypeColumn } from '../Common/commonColumnHelpers'

// Fields that are calculated, or not present in the model
type CustomFields = 'menu' | 'is_chain'

export const CHAIN_COLUMNS = {
  chain: {
    header: 'Chain',
    group: 'Opportunity Attributes',
  },
  count: {
    header: 'Chain Size',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_chain_count',
  },
  menu: {
    header: 'Menu',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_menu',
  },
  menu_length: {
    header: 'Menu Size',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_menu_size',
  },
  menu_matches: {
    header: 'Menu Matches',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_menu_matches',
  },
  total_ltv: {
    header: 'Lifetime Total Value',
    group: 'Predicted Values',
    tooltipName: 'opps_total_ltv',
  },
  brand_ltv: {
    header: 'Lifetime Brand Value',
    group: 'Predicted Values',
    tooltipName: 'opps_brand_ltv',
  },
  revenue_ltv: {
    header: 'Lifetime Revenue Value',
    group: 'Predicted Values',
    tooltipName: 'opps_revenue_ltv',
  },
  one_year_total_value: {
    header: '1yr Total Value',
    group: 'Predicted Values',
    tooltipName: 'opps_taro_plus_tabo',
  },
  taro: {
    header: '1yr Revenue Value',
    group: 'Predicted Values',
    tooltipName: 'opps_taro',
  },
  tabo: {
    header: '1yr Brand Value',
    group: 'Predicted Values',
    tooltipName: 'opps_tabo',
  },
  pounds_per_year: {
    header: '1yr Volume',
    group: 'Predicted Values',
    tooltipName: 'opps_pounds_per_year',
  },
  note_count: {
    header: 'Notes',
    group: 'Campaigns and Deals',
    tooltipName: 'opps_note_count',
    filterTitle: 'Note Count',
  },
  contact_count: {
    header: 'Contacts',
    group: 'Campaigns and Deals',
    tooltipName: 'opps_contact_count',
  },
  deal_count: {
    header: 'Deals',
    group: 'Campaigns and Deals',
    tooltipName: 'opps_deals_count',
    filterTitle: 'Deal Count',
  },
  sales_stages: {
    header: 'Sales Stages',
    group: 'Campaigns and Deals',
    tooltipName: 'opps_sales_stages',
  },
  account_owners: {
    header: 'Account Owners',
    group: 'Campaigns and Deals',
    tooltipName: 'opps_account_owners',
  },
  tags: {
    header: 'Tags',
    group: 'Campaigns and Deals',
    tooltipName: 'opps_tags',
  },
  domain: {
    header: 'Domain',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_domain',
  },
  cuisine_50: {
    header: 'Cuisine',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_cuisine_50',
    filterTitle: 'Cuisine 50',
  },
  velocity_group: {
    header: 'Predicted Traffic',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_velocity_group',
  },
  rating: {
    header: 'Rating',
    group: 'Reputation Data',
    tooltipName: 'opps_rating',
  },
  reviews_count: {
    header: 'Total Reviews',
    group: 'Reputation Data',
    tooltipName: 'opps_reviews_count',
    filterTitle: 'Review Count',
  },
  expense_category: {
    header: 'Expense Category',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_expense_category',
  },
  ingredients_matrix: {
    header: 'Menu Characteristics',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_ingredients_matrix',
  },
  instagram_url: {
    header: 'Instagram Handle',
    group: 'Reputation Data',
    tooltipName: 'opps_instagram_url',
  },
  instagram_followers: {
    header: 'Instagram Followers',
    group: 'Reputation Data',
    tooltipName: 'opps_instagram_followers',
  },
  instagram_following: {
    header: 'Instagram Following',
    group: 'Reputation Data',
    tooltipName: 'opps_instagram_followers',
  },
  instagram_posts: {
    header: 'Instagram Posts',
    group: 'Reputation Data',
    tooltipName: 'opps_instagram_posts',
  },
  instagram_text_percentile: {
    header: 'Instagram Ranking',
    group: 'Reputation Data',
    tooltipName: 'opps_instagram_text_percentile',
  },
  distributors: {
    header: 'Known Distributors',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_distributors',
  },
  country_summary: {
    header: 'Country Summary',
    group: 'Location Data',
    tooltipName: 'opps_country_summary',
    filterTitle: 'Country',
  },
  state_summary: {
    header: 'State Summary',
    group: 'Location Data',
    tooltipName: 'opps_state_summary',
    filterTitle: 'State',
  },
  city_summary: {
    header: 'City Summary',
    group: 'Location Data',
    tooltipName: 'opps_city_summary',
    filterTitle: 'City',
  },
  dma_summary: {
    header: 'DMA Summary',
    group: 'Location Data',
    tooltipName: 'opps_dma_summary',
    filterTitle: 'DMA',
  },
  zip_summary: {
    header: 'Zip Summary',
    group: 'Location Data',
    tooltipName: 'opps_zip_summary',
    filterTitle: 'ZIP code radius',
  },
  median_hhi: {
    header: 'Median Household Income',
    group: 'Location Data',
    tooltipName: 'opps_median_hhi',
  },
  pop_density: {
    header: 'Population Density',
    group: 'Location Data',
    tooltipName: 'opps_pop_density',
  },
  hh_gt100k: {
    header: 'High Household Income',
    group: 'Location Data',
    tooltipName: 'opps_hh_gt100k',
  },
  reputation_data: {
    header: 'Featured On',
    group: 'Reputation Data',
    tooltipName: 'opps_chain_featured_on',
  },
  michelin_stars_count: {
    header: 'Michelin Stars',
    group: 'Reputation Data',
    tooltipName: 'opps_chain_michelin_stars',
  },
  campaigns: {
    header: 'Campaigns',
    group: 'Campaigns and Deals',
  },
  is_chain: {
    header: 'Is Chain',
    group: 'Opportunity Attributes',
    filterTitle: '2+ Unit Chain',
  },
} satisfies Partial<Record<keyof ChainProxy & CustomFields, DataTypeColumn>>
