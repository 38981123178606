import { DealsTable } from 'components/Tables/DealsTable/DealsTable'
import { CompanyTypeSlug } from 'models/companies'
import { useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import { queryClient } from '../../../services/queryClient'
import FbButton from '../../FbUI/FbButton'
import { CollapsibleSection } from '../../UI/CollapsibleSection/CollapsibleSection'
import CreateDealModal from '../CreateDealModal/CreateDealModal'

export default function DealsSection({
  forCompany,
  pagination,
  companyTypeSlug,
  isUserCreated,
}: {
  forCompany?: { id?: number }
  pagination?: boolean
  companyTypeSlug: CompanyTypeSlug
  isUserCreated?: boolean
}) {
  const TABLE_KEY = `company-deals-table-${forCompany?.id}`
  const [showNewDeal, setShowNewDealModal] = useState(false)
  const [dealsCount, setDealsCount] = useState(0)

  return (
    <CollapsibleSection
      forceClose={dealsCount < 1}
      summary={
        <div className={'flex justify-between items-center w-full'}>
          <div>
            Deals <span className={'font-thin'}>({dealsCount})</span>
          </div>
          <FbButton onClick={() => setShowNewDealModal(true)}>
            <FaPlus size={14} /> Add Deals
          </FbButton>
        </div>
      }
    >
      {showNewDeal && (
        <CreateDealModal
          optimisticUiTarget="deals-section"
          show={showNewDeal}
          handleClose={() => setShowNewDealModal(false)}
          targetCompanyId={forCompany?.id}
          companyTypeSlug={companyTypeSlug}
          isUserCreated={isUserCreated}
          onDealCreated={() => {
            queryClient.refetchQueries({ queryKey: [TABLE_KEY] })
          }}
        />
      )}

      <DealsTable
        tableKey={TABLE_KEY}
        forCompany={forCompany}
        setTotalRowsCount={setDealsCount}
        enablePagination={pagination}
        disableUrlPagination
        showCompanyColumn={false}
      />
    </CollapsibleSection>
  )
}
