import { GreenBold } from 'components/Tables/ActivityTableV2/CellEvent/CellEventFormatter'
import {
  CellEventFormatterProps,
  convertSnakeCaseToSentenceCase,
  getFieldFormatter,
} from '../../utils'
import { useMemo } from 'react'

type DefaultUpdateEventProps = CellEventFormatterProps & {
  // Return either a string or a ReactNode

  customFormatter?: (value: any) => string | React.ReactNode
  customFieldName?: string
}

export const DefaultUpdateEvent = ({
  event,
  customFormatter,
  customFieldName,
}: DefaultUpdateEventProps) => {
  const fieldName = useMemo(
    () => customFieldName ?? convertSnakeCaseToSentenceCase(event.field_name),
    [event.field_name, customFieldName]
  )
  const formatter = useMemo(
    () =>
      customFormatter ??
      getFieldFormatter(event.field_name, event.content_type.model),
    [event.field_name, event.content_type.model, customFormatter]
  )
  const value = useMemo(() => formatter(event.value), [event.value, formatter])
  // const previousValue = useMemo(() => {
  //   if (event.bulk_size === 1) {
  //     return event?.changes?.[0]?.previous_value
  //       ? formatter(event.changes[0].previous_value)
  //       : null
  //   }
  //   return null
  // }, [event.changes, event.bulk_size, formatter])

  return (
    <DefaultUpdateEventCell
      fieldName={fieldName}
      value={value}
      // previousValue={previousValue}
    />
  )
}

export const DefaultUpdateEventCell = ({
  fieldName,
  value,
  previousValue,
}: {
  fieldName: string
  value: string | React.JSX.Element
  previousValue?: string
}) => {
  if (previousValue) {
    return (
      <>
        changed <GreenBold>{fieldName}</GreenBold> from <b>{previousValue}</b>{' '}
        to <b>{value}</b>
      </>
    )
  }

  return (
    <>
      changed <GreenBold>{fieldName}</GreenBold> to <b>{value}</b>
    </>
  )
}
