import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { FbLink } from 'components/FbUI/FbLink'
import { ActivityV2 } from 'models/activityV2'
import CellEventFormatterV2 from './CellEvent/CellEventFormatterV2'
import { MdPersonOutline } from 'react-icons/md'
import { formatInteger } from 'utils/formatting'
import { contentTypeMap } from './utils'
import { createTableColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { ACTIVITY_V2_COLUMNS } from 'components/DataExpansionComponents/Activity/activityV2ColumnInfo'
import { OverflownTextTooltip } from 'components/OverflownTextTooltip'
import { Fragment } from 'react'

export const useActivityTableColumnsV2 = (): ColumnDef<ActivityV2, any>[] => {
  const columnHelper = createColumnHelper<ActivityV2>()

  const activityColumnHelper = createTableColumnHelper({
    columns: ACTIVITY_V2_COLUMNS,
  })
  return [
    columnHelper.accessor('user_name', {
      ...activityColumnHelper.retrieveColumn('user'),
      cell: (info) => {
        return (
          <div className="d-flex align-items-center gap-1">
            <MdPersonOutline size={20} />
            <div>{info.getValue()}</div>
          </div>
        )
      },
    }),
    columnHelper.display({
      ...activityColumnHelper.retrieveColumn('event'),
      size: 300,
      cell: ({ row }) => {
        return CellEventFormatterV2({ event: row.original })
      },
    }),
    columnHelper.accessor('bulk_size', {
      ...activityColumnHelper.retrieveColumn('bulk_size'),
      size: 100,
      cell: ({ getValue }) => {
        const value = getValue()
        return formatInteger(value)
      },
    }),
    columnHelper.accessor('content_type', {
      ...activityColumnHelper.retrieveColumn('content_type'),
      cell: ({ row }) => {
        return contentTypeMap[row.original.content_type.model]
      },
    }),
    columnHelper.display({
      ...activityColumnHelper.retrieveColumn('company'),
      cell: ({ row }) => {
        console.log(row.original.companies, 'og')
        if (!row.original.companies.length) return null

        if (row.original.bulk_size === 1) {
          return (
            <FbLink
              to={`/company/${row.original.companies[0][0]}`}
              target="_blank"
              rel="noreferrer"
            >
              {row.original.companies[0][1]}
            </FbLink>
          )
        }
        const tooltipText = row.original.companies
          .map((company) => company[1])
          .join(', ')

        const companiesNotShown =
          row.original.bulk_size - row.original.companies.length
        return (
          <OverflownTextTooltip
            tooltipText={`${tooltipText}, ... (${companiesNotShown} more)`}
            maxLines={2}
          >
            {row.original.companies.map((company, index) => (
              <Fragment
                key={'contact-companies-link-activity-table' + company[0]}
              >
                <FbLink to={`/company/${company[0]}`}>{company[1]}</FbLink>
                {index < row.original.companies.length - 1 && ', '}
              </Fragment>
            ))}
          </OverflownTextTooltip>
        )
      },
    }),
    columnHelper.accessor('created_at', {
      ...activityColumnHelper.retrieveColumn('created_at'),
      cell: ({ row }) => {
        const date = new Date(row.original.created_at)
        // Date needs to be formatted like this: May 16, 2023,  4:14 PM
        return date.toLocaleString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        })
      },
    }),
  ]
}
