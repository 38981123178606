import { useQuery } from '@tanstack/react-query'
import to from 'await-to-js'
import { AxiosError } from 'axios'
import TableButtonAction from 'components/Buttons/TableButtons/TableButtonAction'
import TableButtonProduct from 'components/Buttons/TableButtons/TableButtonProduct'
import {
  DataTable,
  DataTableProvider,
  useDataTableContext,
} from 'components/DataTable'
import { IColumnVisibility } from 'components/DataTable/types'
import {
  filterStoreRepo,
  useFilterParams,
} from 'components/Filters/FilterStore'
import { FilterChips } from 'components/Filters/components/FilterChips/FilterChips'
import DistributorAssignModal from 'components/Modals/AssignmentModal/DistributorAssignModal'
import TagAssignModal from 'components/Modals/AssignmentModal/TagAssignModal'
import { ColumnSelectorRecipient } from 'components/Modals/ColumnModal/ColumnModal'
import {
  CreateContactRequestJobModal,
  MAX_OPPORTUNITIES_REQUESTED,
} from 'components/Modals/CreateContactRequestJobModal'
import ChainExportModal from 'components/Modals/ExportModal/ChainExportModal'
import MenuModal from 'components/Modals/MenuModal/MenuModal'
import OpportunitiesTableFooterControls from 'components/Opportunities/OpportunitiesTableFooterControls'
import { ChainsFilterset } from 'components/Tables/ChainsTable/ChainsFilterset'
import { CHAIN_SUMMARY_KEY } from 'constants/tableQueryKeys'
import { ChainsSummary } from 'models/summaries'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  BsBriefcase,
  BsDownload,
  BsPhone,
  BsTags,
  BsTruck,
  BsXCircle,
} from 'react-icons/bs'
import apiService from 'services/api'
import {
  bulkEditDistributorsParams,
  bulkEditTagsParams,
} from 'services/apiTypes'
import { notifyError } from 'services/toast'
import { ColumnsStoreProvider } from 'stores/ColumnsStore/ColumnsStoreProvider'
import { useSelectedProductIds } from 'stores/SelectedProductsStore/SelectedProductsStore'
import { featureFlagService } from 'utils/featureFlagService'
import { paramsContainAccountFilters } from 'utils/filterUtils'
import { usePaginationURLParams } from 'utils/usePaginationURLParams'
import { AssignToCampaignModal } from '../../../features/campaigns/AssignToCampaignModal'
import { useCampaignUnassigner } from '../../../features/campaigns/useCampaignUnassigner'
import { FilterIdentifier } from '../../../models/saved_view'
import { useOpportunitiesContext } from '../../../pages/app/opportunities/opportunities-context'
import { queryClient } from '../../../services/queryClient'
import { getTableQueryKey } from '../../../utils/getTableQueryKey'
import { CreateDealModalForTable } from '../../Deals/CreateDealModal/CreateDealModal'

import { TableSearchWithElastic } from '../../Filters/TableSearchWithElastic'
import { IDropdownItem } from '../../UI/Dropdown/Dropdown'
import { Switch } from '../../UI/Switch/Switch'
import { ChangeProductViewModal } from '../../change-product-view-modal'
import * as S from '../CommonTable.styles'

import { usePreferences } from 'context/preferences/PreferencesContext'
import { dataTableSortingStoreRepo } from '../../DataTable/DataTableSorting/DataTableSortingStore'
import { DataTableContainer } from '../../DataTable/UI'
import { cn } from '../../UI/cn'
import { useChainsTableColumns } from './ChainsTableColumns'

const sortableFields = [
  'chain',
  'count',
  'total_ltv',
  'brand_ltv',
  'revenue_ltv',
  'pounds_per_year',
  'domain',
  'cuisine_50',
  'expense_category',
  'reviews_count',
  'rating',
  'velocity_group',
  'campaign_attributed_deal_count',
  'deal_count',
  'contact_count',
  'taro',
  'tabo',
  'one_year_total_value',
  'distributors',
  'menu_length',
  'menu_matches',
  'michelin_stars_count',
  'sales_stages',
  'state_summary_length',
  'instagram_text_percentile',
]

const PAGE_SIZE = 100

interface IChainTable {
  setChainsSummary?: (summary: ChainsSummary) => void
  baseFilters?: Record<string, any>
  tableKey: string
  filterIdentifierModifier?: FilterIdentifier
  hideCreateCompanyButton?: boolean
}

function ChainsTableComponent(props: IChainTable) {
  const api = apiService()
  const featureFlag = featureFlagService()
  const hasCampaign = !!props.baseFilters?.campaigns
  const IDENTIFIER = 'CHAINS_TABLE' + (props.filterIdentifierModifier ?? '')
  const chainsFilterStore = filterStoreRepo.getStore(
    IDENTIFIER as FilterIdentifier
  )

  const { sorting } = dataTableSortingStoreRepo.getStore(props.tableKey)()
  const {
    state: { rowSelection, totalSelectedRows, isAllRowsSelected },
    methods: { clearSelectedRows, setTotalRowsInBackend },
  } = useDataTableContext()

  const { setRestaurantsView, chainsTabSelected } = useOpportunitiesContext()
  const { preferences } = usePreferences()

  const [pagination, setPagination] = usePaginationURLParams(PAGE_SIZE)
  const [showProductSelection, setShowProductSelection] = useState(false)
  const [showAssignTags, setShowAssignTags] = useState(false)
  const [showAssignDistributors, setShowAssignDistributors] = useState(false)
  const [showContactRequest, setShowContactRequest] = useState(false)
  const [menuModal, setMenuModal] = useState<
    | {
        chainId: number
        chainName?: string
        itemsIds?: number[]
        menuLength?: number
      }
    | undefined
  >(undefined)

  const selectedProductsIds = useSelectedProductIds()

  const sortParams = useMemo(() => {
    const params: Record<string, unknown> = {}

    if (sorting?.length) {
      params['sort'] = sorting[0].desc ? '-' + sorting[0]?.id : sorting[0]?.id
    }

    return params
  }, [sorting])

  const filtersAsParams = {
    ...useFilterParams(chainsFilterStore),
    ...props.baseFilters,
  }

  const filterAndSortParams = useMemo(() => {
    return {
      ...filtersAsParams,
      ...sortParams,
    }
  }, [filtersAsParams, sorting, props.baseFilters])

  const TABLE_QUERY_KEY = getTableQueryKey({
    tableKey: props.tableKey,
    filterParams: filterAndSortParams,
    page: pagination.pageIndex + 1,
    products: selectedProductsIds,
  })

  // DATA FETCHING
  const { isFetching, data } = useQuery({
    staleTime: Infinity,
    queryKey: TABLE_QUERY_KEY,
    queryFn: async ({ signal }) => {
      clearSelectedRows()

      const [err, res] = await to(
        api.getChainsListV2(
          {
            ...filterAndSortParams,
            page: pagination.pageIndex + 1,
            product_id: selectedProductsIds,
          },
          signal
        )
      )

      if ((err as AxiosError)?.code === 'ERR_NETWORK') {
        notifyError('Sorry! Unable to complete this search, please try again.')
      }
      if (err) throw err

      return res
    },
  })
  // END DATA FETCHING

  const unassignFromCampaign = useCampaignUnassigner('restaurants-bars', {
    data: data?.results ?? [],
    accessorKey: 'contact_company_id',
    tableQueryKey: TABLE_QUERY_KEY,
    requestParams: filterAndSortParams,
    campaignId: props?.baseFilters?.campaigns,
    setPagination: setPagination,
    totalCount: data?.count ?? 0,
    pageSize: PAGE_SIZE,
  })

  useEffect(() => {
    clearSelectedRows()
  }, [data])

  const [openDealModal, setOpenDealModal] = React.useState(false)

  const summaryAbortControllerV2 = useMemo(() => new AbortController(), [])

  const totalRowsSelected = useMemo(() => {
    return isAllRowsSelected
      ? totalSelectedRows
      : Object.keys(rowSelection).length
  }, [isAllRowsSelected, rowSelection])

  const { data: chainsSummary, isLoading: chainsSummaryLoading } =
    api.useGetChainsSummaryV2(
      {
        ...filtersAsParams,
        product_id: selectedProductsIds.filter((p) => p !== -1),
      },
      summaryAbortControllerV2.signal
    )

  useEffect(() => {
    if (chainsSummaryLoading) return
    props.setChainsSummary?.({
      total_ltv: chainsSummary?.total_ltv ?? 0,
      places_count: chainsSummary?.places_count ?? 0,
      count: chainsSummary?.count ?? 0,
      avg_pounds_per_year: chainsSummary?.avg_pounds_per_year ?? 0,
      avg_total_ltv: chainsSummary?.avg_total_ltv ?? 0,
      isLoading: chainsSummaryLoading,
    })
    setTotalRowsInBackend(chainsSummary?.count ?? 0)
  }, [chainsSummaryLoading, chainsSummary])

  const handleCreateDeals = () => {
    if (!data) return
    setOpenDealModal(true)
  }

  const [showExportModal, setShowExportModal] = useState<boolean>(false)
  const [showChainExportModal, setShowChainExportModal] =
    useState<boolean>(false)

  const selectedContactCompanyIds = useMemo(() => {
    const accessor = 'contact_company_id'
    return (
      data?.results

        ?.filter((_: any, i: number) => rowSelection[i])

        ?.map((r: any) => {
          return accessor.split('.').reduce((acc, key) => acc[key], r)
        }) ?? []
    )
  }, [data, rowSelection])

  const unselectedContactCompanyIds = useMemo(() => {
    if (!isAllRowsSelected) return []
    return (
      data?.results
        ?.filter((_, i) => !rowSelection[i])
        ?.map((r) => r.contact_company_id) ?? []
    )
  }, [data, rowSelection, isAllRowsSelected])

  console.log({ props })
  const columns = useChainsTableColumns(
    chainsSummary,
    setMenuModal,
    sorting,
    data,
    selectedProductsIds,
    preferences,
    props.baseFilters?.campaigns
  )

  const refetchChainsSummary = useCallback(() => {
    if (paramsContainAccountFilters(filterAndSortParams)) {
      queryClient.refetchQueries({ queryKey: [CHAIN_SUMMARY_KEY] })
    }
  }, [filterAndSortParams, queryClient])

  const apiTagsAction = useCallback(
    async (values: bulkEditTagsParams) => {
      const apiAction = api.chainsBulkEditTagsV2
      const response = await apiAction(values)
      refetchChainsSummary()
      return response
    },
    [api, refetchChainsSummary]
  )

  const apiDistributorsAction = useCallback(
    async (values: bulkEditDistributorsParams) => {
      const apiAction = api.chainsBulkEditDistributorsV2
      const response = await apiAction(values)
      refetchChainsSummary()
      return response
    },
    [api, refetchChainsSummary]
  )

  const actions = useMemo(() => {
    const items: IDropdownItem[] = [
      {
        label: 'Create Deals',
        icon: <BsBriefcase size={20} />,
        callback: handleCreateDeals,
      },
      {
        label: 'Manage Tags',
        icon: <BsTags size={20} />,
        callback: () => setShowAssignTags(true),
      },
      {
        label: 'Add Known Distributors',
        icon: <BsTruck size={20} />,
        callback: () => setShowAssignDistributors(true),
      },
      {
        label: 'Export Selected',
        icon: <BsDownload size={20} />,
        callback: () => setShowExportModal(true),
      },
    ]

    if (featureFlag.enableContactRequests) {
      items.push({
        label: 'Request Contacts',
        icon: <BsPhone size={20} />,
        callback: () => setShowContactRequest(true),
        disabled: totalRowsSelected > MAX_OPPORTUNITIES_REQUESTED,
        disabledTooltipText: `To request contacts, select less than ${MAX_OPPORTUNITIES_REQUESTED} opportunities.`,
      })
    }

    if (featureFlag.enableCampaigns) {
      if (!hasCampaign) {
        items.push({
          label: 'Push to Campaign',
          icon: <BsDownload size={20} />,
          subContent: (
            <AssignToCampaignModal
              data={data?.results ?? []}
              accessorKey={'contact_company_id'}
              tableQueryKey={TABLE_QUERY_KEY}
              requestParams={filterAndSortParams}
              companyTypeSlug={'restaurants-bars'}
              buttonLess
            />
          ),
        })
      } else {
        items.push({
          label: 'Unassign from Campaign',
          icon: <BsXCircle size={20} />,
          callback: () => unassignFromCampaign.unassign(),
        })
      }
    }

    return items
  }, [data, featureFlag, hasCampaign])

  return (
    <DataTableContainer>
      <DataTable
        tableHeader={
          <S.SearchContainer>
            <div className="flex w-full gap-2 items-center">
              <TableSearchWithElastic
                filterStore={chainsFilterStore}
                historyKey="chains"
                options={[
                  {
                    label: 'Menu',
                    searchKey: 'menu',
                    searchPlaceholder: 'Type menu item',
                  },
                  {
                    label: 'Name',
                    searchKey: 'chain_name',
                    searchPlaceholder: 'Type chain name',
                  },
                ]}
              />

              {!hasCampaign && (
                <Switch
                  type={'checkbox'}
                  checked={chainsTabSelected}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setRestaurantsView('chain')
                    } else {
                      setRestaurantsView('door')
                    }
                  }}
                  labelRight={chainsTabSelected ? 'Chain View' : 'Door View'}
                  className={'min-w-[120px]'}
                />
              )}

              <TableButtonProduct
                selectedProducts={selectedProductsIds}
                onClick={() => setShowProductSelection(true)}
              />
              <ChainsFilterset
                hideCampaignsFilter={props.baseFilters?.campaigns}
                filterIdentifier={IDENTIFIER as FilterIdentifier}
              />
              <ColumnSelectorRecipient tableKey={props.tableKey} />
            </div>

            <div className={cn('flex flex-row justify-between gap-4')}>
              <TableButtonAction
                items={actions}
                disabled={!totalSelectedRows}
                selectedRowsCount={totalSelectedRows ?? 0}
              />
              <FilterChips
                identifier={IDENTIFIER as FilterIdentifier}
                store={chainsFilterStore}
                showActive
                clearAllButton
              />
            </div>
          </S.SearchContainer>
        }
        tableKey={props.tableKey}
        loading={isFetching || unassignFromCampaign.isUnassigning}
        data={data?.results ?? []}
        columns={columns}
        sortableFields={[
          ...sortableFields,
          ...(featureFlag.enableTagsSort ? ['tags'] : []),
        ]}
        virtualizeRows
        enableRowSelection
        isPaginationEnabled={true}
        defaultSort={[{ id: 'count' as any, desc: true }]}
        paginationOptions={{
          pageCount: Math.ceil((chainsSummary?.count ?? 0) / PAGE_SIZE),
          setPagination: setPagination,
          pagination: pagination,
          isPaginationLoading: isFetching,
        }}
        selectAllText="Select Opportunities"
        footerControls={
          <OpportunitiesTableFooterControls
            hideCreateCompanyButton={props.hideCreateCompanyButton}
            companyTypeSlug={'restaurants-bars'}
            onDownloadButtonClick={() =>
              chainsTabSelected
                ? setShowChainExportModal(true)
                : setShowExportModal(true)
            }
          />
        }
      />

      <CreateContactRequestJobModal
        data={data?.results ?? []}
        open={showContactRequest}
        onClose={() => setShowContactRequest(false)}
        // v1 and v2 keys
        accessorKeys={['contact_company_id', 'contact_company[0].id']}
        tableQueryKey={TABLE_QUERY_KEY}
        requestParams={filterAndSortParams}
        companyTypeSlug={'restaurants-bars'}
      />

      <TagAssignModal
        show={showAssignTags}
        handleClose={() => setShowAssignTags(false)}
        tableQueryKey={TABLE_QUERY_KEY}
        data={data?.results ?? []}
        filterAndSortParams={filterAndSortParams}
        apiTagsAction={apiTagsAction}
        idAccessor="contact_company_id"
      />

      <DistributorAssignModal
        show={showAssignDistributors}
        handleClose={() => setShowAssignDistributors(false)}
        tableQueryKey={TABLE_QUERY_KEY}
        data={data?.results ?? []}
        idAccessor="contact_company_id"
        filterAndSortParams={filterAndSortParams}
        apiDistributorsAction={apiDistributorsAction}
      />

      <ChainExportModal
        count={
          (showExportModal
            ? selectedContactCompanyIds?.length
            : chainsSummary?.count) ?? 0
        }
        show={showExportModal || showChainExportModal}
        _handleClose={() => {
          setShowExportModal(false)
          setShowChainExportModal?.(false)
        }}
        filters={filterAndSortParams}
        productSelected={selectedProductsIds[0]}
        selectedIds={!isAllRowsSelected ? selectedContactCompanyIds : undefined}
        excludeIds={isAllRowsSelected ? unselectedContactCompanyIds : undefined}
      />

      {menuModal?.chainId && (
        <MenuModal
          show={!!menuModal}
          handleClose={() => setMenuModal(undefined)}
          chainId={menuModal.chainId!}
          chainName={menuModal.chainName!}
          menuLength={menuModal?.menuLength}
          filterStore={chainsFilterStore}
        />
      )}

      <CreateDealModalForTable
        show={openDealModal}
        handleClose={() => setOpenDealModal(false)}
        queryKey={TABLE_QUERY_KEY}
        data={data?.results ?? []}
        idAccessor="contact_company_id"
        optimisticUiTarget="chains"
        filterAndSortParams={filterAndSortParams}
        onDealCreated={refetchChainsSummary}
        originCampaignId={props.baseFilters?.campaigns}
        companyTypeSlug="restaurants-bars"
      />

      <ChangeProductViewModal
        show={showProductSelection}
        handleClose={() => setShowProductSelection(false)}
      />
    </DataTableContainer>
  )
}

export function ChainsTable(
  props: IChainTable & {
    defaultColumnVisibility?: IColumnVisibility<any>
  }
) {
  const { tableKey, defaultColumnVisibility, ...rest } = props

  return (
    <ColumnsStoreProvider
      tableKey={tableKey}
      defaultColumnVisibility={defaultColumnVisibility}
      savedPresetsIdentifier={'CHAINS_TABLE'}
      preferredGroupOrder={[
        'Opportunity Attributes',
        'Campaigns and Deals',
        'Predicted Values',
        'Reputation Data',
        'Location Data',
      ]}
    >
      <DataTableProvider tableKey={tableKey}>
        <ChainsTableComponent tableKey={tableKey} {...rest} />
      </DataTableProvider>
    </ColumnsStoreProvider>
  )
}
