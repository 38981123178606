import styled from 'styled-components/macro'
import { useParams } from 'react-router-dom'
import { handleFormError } from '../../../utils/handleFormError'
import { TextInput } from '../../../components/FormUtils/TextInput'
import { useForm } from 'react-hook-form'
import { useAuth } from 'context/authentication/useAuth'
import to from 'await-to-js'
import { useState } from 'react'
import FbButton from '../../../components/FbUI/FbButton'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { RouterLink } from 'components/UI/Anchor'

interface ApiError extends Error {
  status?: number
}

const resetPasswordConfirmFormShape = z
  .object({
    email: z.string().email(),
    password1: z
      .string()
      .min(8, { message: 'Password must contain at least 8 characters' }),
    password2: z
      .string()
      .min(8, { message: 'Password must contain at least 8 characters' }),
  })
  .refine(
    (data) => {
      // Only validate if both fields have values
      if (data.password1 && data.password2) {
        return data.password1 === data.password2
      }
      return true
    },
    {
      message: "Passwords don't match",
      path: ['password2'],
    }
  )

function ResetPasswordConfirm() {
  const { resetPasswordConfirm } = useAuth()
  const [successMsg, setSuccessMsg] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const urlParams = useParams()

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    setError,
  } = useForm({
    defaultValues: {
      email: '',
      non_field_errors: '',
      password1: '',
      password2: '',
    },
    resolver: zodResolver(resetPasswordConfirmFormShape),
    mode: 'all',
  })

  const onSubmit = handleSubmit(
    async (values) => {
      setErrorMsg('')
      setSuccessMsg('')

      if (!urlParams?.uid || !urlParams?.token)
        return setError('non_field_errors', { message: 'Invalid URL' })

      const [err, res] = await to(
        resetPasswordConfirm(
          values.email,
          urlParams?.uid,
          urlParams?.token,
          values?.password1,
          values?.password2
        )
      )
      if ((err as ApiError)?.status === 400) {
        setErrorMsg('Invalid password reset token.')
        return
      }
      if (err) {
        handleFormError(err, setError)
        return
      }

      if (res) setSuccessMsg(res.detail)
    },
    (errors) => console.warn(errors)
  )

  return (
    <div style={{ width: '400px', flexShrink: 0 }}>
      <Title>Reset Password</Title>
      {successMsg && (
        <div className="mb-4">
          {successMsg}&nbsp;<RouterLink to="/login">Click here</RouterLink> to
          return to Login page
        </div>
      )}
      {errorMsg && (
        <div className="mb-4 text-gray-600">
          <span className="text-red-600">{errorMsg}&nbsp;</span>
          <RouterLink to="/reset-password">Click here</RouterLink> to resend
          password confirmation email
        </div>
      )}
      <TextInput control={control} name="email" label="Email" />
      <TextInput
        type="password"
        control={control}
        name="password1"
        label="New Password"
      />
      <TextInput
        type="password"
        control={control}
        name="password2"
        label="Confirm New Password"
      />
      <ButtonContainer>
        <FbButton
          type="submit"
          onClick={onSubmit}
          loading={isSubmitting}
          disabled={!!errors.password2}
        >
          Reset
        </FbButton>
      </ButtonContainer>
    </div>
  )
}

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 32px;
`

const ButtonContainer = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  flex-direction: row-reverse;
`

export default ResetPasswordConfirm
