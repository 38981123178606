import { TextInput } from 'components/FormUtilsV2/TextInput/text-input'
import BaseStep from './BaseStep'
import { useProductModalContext } from './ProductModalContext'
import * as S from './styles'
import { CiSquarePlus } from 'react-icons/ci'
import { NumericInputWithUnitSelector } from 'components/FormUtilsV2/NumericInputWithUnitSelector/numeric-input-with-unit-selector'
import { useState } from 'react'
import { ChevronDown, ChevronUp } from 'lucide-react'
import { useFieldArray } from 'react-hook-form'
import { FaRegTrashAlt } from 'react-icons/fa'
import { Variant } from 'models/product'

const unitOptions = [
  { label: 'lbs', value: 'lbs' },
  { label: 'kg', value: 'kg' },
  { label: 'oz', value: 'oz' },
]

export function ProductVariantsStep({ onBack }: { onBack: () => void }) {
  const {
    methods: { handleClose, productForm, onSubmit },
    state: { selectedProductID },
  } = useProductModalContext()

  const [isExpandedArray, setIsExpandedArray] = useState<boolean[]>([])

  const {
    control,
    formState: { isValid },
    watch,
  } = productForm

  const {
    fields: variantsFields,
    append: appendVariant,
    remove: removeVariant,
  } = useFieldArray({
    control,
    name: 'variants',
  })

  const variants = watch('variants')

  // Odd, but we don't actually want to store this in a useMemo, because watch('variants') will
  // only update when the array size changes, not when the name of a variant changes
  const variantByIdx = variants?.reduce(
    (acc, variant, idx) => ({
      ...acc,
      [idx]: variant,
    }),
    {} as Record<string, Variant>
  )

  const handleAppendVariant = () => {
    const totalVariants = variantsFields.length
    const variantName = String.fromCharCode(65 + totalVariants) // 65 is the char code for 'A'
    setIsExpandedArray([...isExpandedArray, true])
    appendVariant({
      name: 'Variant ' + variantName,
      case_weight_unit: 'lbs',
      unit_weight_unit: 'lbs',
    })
  }

  const handleRemoveVariant = (index: number) => {
    removeVariant(index)
    setIsExpandedArray((prev) => {
      const newArr = [...prev]
      newArr.splice(index, 1)
      return newArr
    })
  }

  return (
    <BaseStep
      handleClose={handleClose}
      onContinue={onSubmit}
      onBack={onBack}
      title={selectedProductID ? 'Update Product' : 'Create Product'}
      description="Add optional product variants, like different flavors or sizes."
      continueButtonText="Save"
      cancelButtonText="Previous"
      currentStep={2}
      totalSteps={3}
      continueDisabled={!isValid}
    >
      <div className="space-y-4">
        <S.Card>
          <button className="w-full flex items-center justify-between">
            <div className="flex items-center gap-2">
              <h3 className="text-sm font-medium text-gray-900">
                Product Variants
              </h3>
              <S.Badge>Optional</S.Badge>
            </div>
          </button>

          {/* Scrollable section for product variant, having  */}
          <div className="overflow-y-auto max-h-[500px]">
            {variantsFields.map((field, index) => {
              return (
                <div className="my-4 mx-10 bg-white p-4" key={field.id}>
                  <button
                    onClick={() =>
                      setIsExpandedArray((prev) => {
                        const newArr = [...prev]
                        newArr[index] = !newArr[index]
                        return newArr
                      })
                    }
                    className="w-full flex items-center justify-between"
                  >
                    <div className="flex items-center gap-2">
                      <h3 className="text-base font-normal">
                        {variantByIdx?.[index]?.id
                          ? `${variantByIdx?.[index]?.name} (${variantByIdx?.[index]?.id})`
                          : variantByIdx?.[index]?.name}
                      </h3>
                    </div>
                    <div className="flex items-center gap-2">
                      {isExpandedArray[index] ? (
                        <ChevronUp className="h-5 w-5 text-gray-500" />
                      ) : (
                        <ChevronDown className="h-5 w-5 text-gray-500" />
                      )}
                      {/* Trash icon to delete */}
                      <button
                        onClick={(e) => {
                          e.stopPropagation()
                          handleRemoveVariant(index)
                        }}
                        className="text-gray-500 hover:text-red-500"
                      >
                        <FaRegTrashAlt />
                      </button>
                    </div>
                  </button>
                  {isExpandedArray[index] && (
                    <div className="grid grid-cols-6 gap-y-2 gap-x-4 mt-2">
                      <div className="col-span-6">
                        <TextInput
                          name={`variants.${index}.name`}
                          control={control}
                          type="text"
                          label="Variant Name"
                          placeholder="Variant Name"
                          // Arrays of objects with react form hook is a bit tricky, we need to trigger the form validation manually
                          onChange={() =>
                            productForm.trigger(`variants.${index}.name`)
                          }
                        />
                      </div>
                      <div className="col-span-6">
                        <TextInput
                          name={`variants.${index}.description`}
                          control={control}
                          type="text"
                          label="Description"
                          placeholder="Description"
                          onChange={() =>
                            productForm.trigger(`variants.${index}.description`)
                          }
                        />
                      </div>
                      <div className="col-span-2">
                        <TextInput
                          name={`variants.${index}.gtin`}
                          control={control}
                          type="text"
                          label="GTIN"
                          placeholder="GTIN"
                          onChange={() =>
                            productForm.trigger(`variants.${index}.gtin`)
                          }
                        />
                      </div>
                      <div className="col-span-2">
                        <TextInput
                          name={`variants.${index}.sku`}
                          control={control}
                          type="text"
                          label="Item # / Internal mfg. SKU ID code"
                          placeholder="SKU"
                          onChange={() =>
                            productForm.trigger(`variants.${index}.sku`)
                          }
                        />
                      </div>
                      <div className="col-span-2">
                        <TextInput
                          name={`variants.${index}.units_per_case`}
                          control={control}
                          type="number"
                          label="Units / Case"
                          placeholder="Units / Case"
                          onChange={() =>
                            productForm.trigger(
                              `variants.${index}.units_per_case`
                            )
                          }
                        />
                      </div>
                      <div className="col-span-3">
                        <NumericInputWithUnitSelector
                          min={0}
                          name={`variants.${index}.case_weight`}
                          control={control}
                          label="Case weight"
                          placeholder="500"
                          unit_name={`variants.${index}.case_weight_unit`}
                          unitOptions={unitOptions}
                          onChange={() =>
                            productForm.trigger(`variants.${index}.case_weight`)
                          }
                          onUnitChange={() =>
                            productForm.trigger(
                              `variants.${index}.case_weight_unit`
                            )
                          }
                        />
                      </div>
                      <div className="col-span-3">
                        <NumericInputWithUnitSelector
                          min={0}
                          name={`variants.${index}.unit_weight`}
                          control={control}
                          label="Unit weight"
                          placeholder="500"
                          unit_name={`variants.${index}.unit_weight_unit`}
                          unitOptions={unitOptions}
                          onChange={() =>
                            productForm.trigger(`variants.${index}.unit_weight`)
                          }
                          onUnitChange={() =>
                            productForm.trigger(
                              `variants.${index}.unit_weight_unit`
                            )
                          }
                        />
                      </div>
                      <div className="col-span-6">
                        <TextInput
                          name={`variants.${index}.format_pack_size`}
                          control={control}
                          type="text"
                          label="Format / Pack size"
                          placeholder="Format / Pack size"
                          onChange={() =>
                            productForm.trigger(
                              `variants.${index}.format_pack_size`
                            )
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
              )
            })}
          </div>

          {/* Create variant button with plus icon */}
          <div
            className="flex items-center gap-2 cursor-pointer py-2 mt-2"
            onClick={handleAppendVariant}
          >
            <CiSquarePlus color={'#005A31'} size={13.5} />
            <div className="text-sm text-[#005A31]">Create Variant</div>
          </div>
        </S.Card>
      </div>
    </BaseStep>
  )
}
