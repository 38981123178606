import * as React from 'react'
import { Check, ChevronsUpDown, CirclePlus } from 'lucide-react'
import { Popover, PopoverContent, PopoverTrigger } from './popover'
import { ComboboxButton } from './combobox-button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from './command'
import { cn } from '../cn'
import { Spinner } from 'react-bootstrap'
import { BsChevronDown } from 'react-icons/bs'

type ComboboxOption = {
  label: string
  value: string | number
  icon?: React.JSX.Element
}

export interface ComboboxProps {
  placeholder: string
  searchPlaceholder: string
  options: ComboboxOption[]
  onSelectAltAction?: (value: string | number) => void
  onAddNew?: () => void
  onSelect?: React.Dispatch<React.SetStateAction<string | number | undefined>>
  selected?: string | number
  notFoundPlaceholder?: string
  buttonClassName?: string
  buttonComponent?: React.JSX.Element
  disabled?: boolean
  loading?: boolean
  buttonLess?: boolean
}

export function Combobox(props: ComboboxProps) {
  const [open, setOpen] = React.useState(false)

  const content = (
    <Command>
      <CommandInput
        placeholder={props.searchPlaceholder ?? 'Nothing found...'}
      />
      <CommandList>
        <CommandEmpty>{props.notFoundPlaceholder}</CommandEmpty>
        <CommandGroup>
          {props.onAddNew && (
            <CommandItem
              key={'new'}
              value={'new'}
              onSelect={() => {
                setOpen(false)
                props.onAddNew?.()
              }}
            >
              <CirclePlus size={12} className={'mr-1'} />
              Add new
            </CommandItem>
          )}
          {props.options.map((option) => (
            <CommandItem
              key={option.value}
              value={option.label + option.value}
              onSelect={() => {
                if (props.onSelectAltAction) {
                  props.onSelectAltAction(option.value)
                } else {
                  props.onSelect?.(
                    option.value === props?.selected?.toString()
                      ? ''
                      : option.value
                  )
                }
                setOpen(false)
              }}
            >
              {option.icon}
              {option.label}
              <Check
                className={cn(
                  'mr-2 h-4 w-4',
                  props.selected?.toString() === option.value
                    ? 'opacity-100'
                    : 'opacity-0'
                )}
              />
            </CommandItem>
          ))}
        </CommandGroup>
      </CommandList>
    </Command>
  )

  if (props.buttonLess) {
    return content
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        {props.buttonComponent ?? (
          <ComboboxButton
            disabled={props.disabled || props.loading}
            role="combobox"
            aria-expanded={open}
            className={cn(
              'text-md w-[300px] justify-between',
              props.buttonClassName
            )}
          >
            <div className="flex flex-row justify-between w-100 items-center">
              {props.selected ? (
                <div className="">
                  {
                    props.options.find(
                      (option) =>
                        option.value.toString() === props.selected?.toString()
                    )?.label
                  }
                </div>
              ) : (
                <div className="text-[#ADB5BD]">{props.placeholder}</div>
              )}
              {props.loading ? (
                <Spinner className={'ml-2 flex-shrink-0'} size={'sm'} />
              ) : (
                <div className="flex">
                  {props.selected ? (
                    <div
                      onClick={(e) => {
                        e.stopPropagation()
                        props?.onSelect?.(undefined)
                      }}
                      className="text-[#ccc] hover:text-[#999] p-2"
                    >
                      <svg
                        height="20"
                        width="20"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                        focusable="false"
                        style={{
                          display: 'inline-block',
                          fill: 'currentColor',
                          lineHeight: 1,
                          stroke: 'currentColor',
                          strokeWidth: 0,
                        }}
                      >
                        <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                      </svg>
                    </div>
                  ) : null}

                  <div className="w-px bg-[#ccc] self-stretch my-[9px]"></div>
                  <div className="text-[#ccc] hover:text-[#999] py-2 pl-2 pr-0">
                    <svg
                      height="20"
                      width="20"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                      focusable="false"
                      style={{
                        display: 'inline-block',
                        fill: 'currentColor',
                        lineHeight: 1,
                        stroke: 'currentColor',
                        strokeWidth: 0,
                      }}
                    >
                      <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                    </svg>
                  </div>
                </div>
              )}
            </div>
          </ComboboxButton>
        )}
      </PopoverTrigger>
      <PopoverContent className="w-[400px] p-0">{content}</PopoverContent>
    </Popover>
  )
}
