import React from 'react'
import { FbLink } from 'components/FbUI/FbLink'
import ErrorCard from './ErrorCard'

const NotFoundCard: React.FC = () => {
  const supportEmail = 'support@firstbite.io'

  const handleEmailClick = () => {
    window.open(`mailto:${supportEmail}`)
  }

  const emailLink = () => (
    <FbLink to="#" onClick={handleEmailClick}>
      here
    </FbLink>
  )

  return (
    <ErrorCard
      title="404"
      description="This page may have been moved, or is temporarily unavailable."
      legend={
        <>
          Let us know how you got here, and we&apos;ll look into this for you.
          Click {emailLink()} to send us an email.
        </>
      }
    />
  )
}

export default NotFoundCard
