import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { z, ZodObject } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { DropdownMenuTrigger } from '@radix-ui/react-dropdown-menu'
import { cn } from 'components/UI/cn'
import {
  DropdownMenu,
  DropdownMenuContent,
} from 'components/UI/BaseDropdown/dropdown-base'
import { FilterButton } from '../components/FilterButton'
import { FilterCloseButton } from '../components/FilterCloseButton'
import { NumericInput } from 'components/FormUtils/NumericInput/numeric-input'
import FbButton from 'components/FbUI/FbButton'
import { FilterStore } from '../FilterStore'

interface NamedRangeFilterProps {
  min?: number
  max?: number
  store: FilterStore
}

export function RangeFilterWithMinMax(
  props: NamedRangeFilterProps & BaseRangeFilterProps
) {
  const { min, max, ...rest } = props

  const zodObject = z.object({
    min: z
      .number()
      .min(min ?? 1)
      .max(max ?? 100)
      .optional()
      .nullable(),
    max: z
      .number()
      .min(min ?? 1)
      .max(max ?? 100)
      .optional()
      .nullable(),
  })

  return <RangeFilter {...rest} zodObject={zodObject} />
}

export function ChainSizeRangeFilter(props: NamedRangeFilterProps) {
  return (
    <RangeFilter
      title="Chain Size"
      filterKey="chain_count"
      store={props.store}
    />
  )
}

export function AvgRatingRangeFilter(props: NamedRangeFilterProps) {
  const zodObject = z.object({
    min: z.number().min(0).max(5).optional().nullable(),
    max: z.number().min(0).max(5).optional().nullable(),
  })

  return (
    <RangeFilter
      title="Average Rating"
      filterKey="avg_rating"
      decimalScale={2}
      store={props.store}
      zodObject={zodObject}
    />
  )
}

export function ReviewCountRangeFilter(props: NamedRangeFilterProps) {
  const zodObject = z.object({
    min: z.number().min(0).max(1000000000).optional().nullable(),
    max: z.number().min(0).max(1000000000).optional().nullable(),
  })

  return (
    <RangeFilter
      title="Review Count"
      filterKey="reviews_count"
      store={props.store}
      zodObject={zodObject}
    />
  )
}

export function InstagramFollowersRangeFilter(props: NamedRangeFilterProps) {
  const zodObject = z.object({
    min: z.number().min(0).max(1000000000).optional().nullable(),
    max: z.number().min(0).max(1000000000).optional().nullable(),
  })

  return (
    <RangeFilter
      title="Instagram Followers"
      filterKey="instagram_followers"
      store={props.store}
      zodObject={zodObject}
    />
  )
}

export function LTVTotalRangeFilter(props: NamedRangeFilterProps) {
  return (
    <RangeFilter
      title="Lifetime Total Value"
      filterKey="total_ltv"
      store={props.store}
    />
  )
}

export function BrandLTVRangeFilter(props: NamedRangeFilterProps) {
  return (
    <RangeFilter
      title="Lifetime Brand Value"
      filterKey="brand_ltv"
      store={props.store}
    />
  )
}

export function RevenueLTVRangeFilter(props: NamedRangeFilterProps) {
  return (
    <RangeFilter
      title="Lifetime Revenue Value"
      filterKey="revenue_ltv"
      store={props.store}
    />
  )
}

export function PoundsPerYearRangeFilter(props: NamedRangeFilterProps) {
  return (
    <RangeFilter
      title="1yr Volume"
      filterKey="pounds_per_year"
      store={props.store}
    />
  )
}

export function TaroPlusTaboRangeFilter(props: NamedRangeFilterProps) {
  return (
    <RangeFilter
      title="1yr Total Value"
      filterKey="one_year_total_value"
      store={props.store}
    />
  )
}

export function TaroRangeFilter(props: NamedRangeFilterProps) {
  return (
    <RangeFilter
      title="1yr Revenue Value"
      filterKey="taro"
      store={props.store}
    />
  )
}

export function TaboRangeFilter(props: NamedRangeFilterProps) {
  return (
    <RangeFilter title="1yr Brand Value" filterKey="tabo" store={props.store} />
  )
}

export function DealCountRangeFilter(props: NamedRangeFilterProps) {
  return (
    <RangeFilter
      title="Deal Count"
      filterKey="deal_count"
      store={props.store}
    />
  )
}

export function NoteCountRangeFilter(props: NamedRangeFilterProps) {
  return (
    <RangeFilter
      title="Note Count"
      filterKey="note_count"
      store={props.store}
    />
  )
}

export function ContactCountRangeFilter(props: NamedRangeFilterProps) {
  return (
    <RangeFilter
      title="Contact Count"
      filterKey="contact_count"
      store={props.store}
    />
  )
}

export function MedianHouseholdIncomeRangeFilter(props: NamedRangeFilterProps) {
  return (
    <RangeFilter
      title="Median Household Income"
      filterKey="median_hhi"
      store={props.store}
    />
  )
}

export function PopDensityRangeFilter(props: NamedRangeFilterProps) {
  return (
    <RangeFilter
      title="Population Density"
      filterKey="pop_density"
      store={props.store}
    />
  )
}

export function DaysSinceLastUpdateRangeFilter(props: NamedRangeFilterProps) {
  return (
    <RangeFilter
      title="Days Since Last Update"
      filterKey="days_since_last_modified"
      store={props.store}
    />
  )
}

export function HighHouseholdIncomeRangeFilter(props: NamedRangeFilterProps) {
  return (
    <RangeFilter
      title="High Household Income"
      filterKey="hh_gt100k"
      store={props.store}
      decimalScale={2}
    />
  )
}

export function UnemploymentRateRangeFilter(props: NamedRangeFilterProps) {
  return (
    <RangeFilter
      title="Unemployment Rate"
      filterKey="unemployment_rate"
      store={props.store}
      decimalScale={2}
    />
  )
}

export interface BaseRangeFilterProps {
  title: string
  filterKey: string

  zodObject?: ZodObject<any>
  decimalScale?: number
  store: FilterStore
  minAllowNegative?: boolean
  maxAllowNegative?: boolean
}

export function RangeFilter({
  title,
  filterKey,
  zodObject,
  decimalScale = 0,
  store,
  minAllowNegative = true,
  maxAllowNegative = true,
}: BaseRangeFilterProps) {
  const [open, setOpen] = useState(false)
  const {
    pendingRangeFilters,
    setPendingRangeFilters,
    clearPendingRangeFilter,
    registerFilterTitle,
  } = store()
  const current = pendingRangeFilters?.[filterKey!]

  const { control, watch, reset, handleSubmit } = useForm({
    defaultValues: {
      min: parseFloat(current?.min?.toString() ?? '') || null,
      max: parseFloat(current?.max?.toString() ?? '') || null,
    },
    resolver: zodObject ? zodResolver(zodObject) : undefined,
  })

  function saveFilters() {
    const hasMin = watch('min') != null
    const hasMax = watch('max') != null

    if (hasMin || hasMax) {
      setPendingRangeFilters(
        filterKey,
        watch('min') ?? undefined,
        watch('max') ?? undefined
      )
    }

    setOpen(false)
  }

  const clearFilters = () => {
    reset({
      min: null,
      max: null,
    })
    clearPendingRangeFilter(filterKey)
  }

  const _handleSubmit = handleSubmit(() => {
    saveFilters()
    setOpen(false)
  })

  const getLabel = () => {
    if (!isRangeSet) {
      return 'All'
    }

    if (current?.min == null && current?.max != null) {
      return `≤${current?.max}`
    }

    if (current?.max == null && current?.min != null) {
      return `≥${current?.min}`
    }

    return `${current?.min} - ${current?.max}`
  }

  const isRangeSet = current?.min != null || current?.max != null

  useEffect(() => {
    registerFilterTitle(filterKey, title)
  }, [])

  return (
    <DropdownMenu open={open} onOpenChange={(open) => setOpen(open)}>
      <div className="h-full w-full relative">
        <div className={'h-full w-full flex align-center'}>
          <DropdownMenuTrigger asChild>
            <FilterButton
              open={open}
              isActive={isRangeSet}
              title={title}
              activeValueLabel={getLabel()}
              onClick={() => setOpen(!open)}
            />
          </DropdownMenuTrigger>
          <FilterCloseButton
            isActive={isRangeSet}
            onClick={() => clearFilters()}
          />
        </div>
      </div>

      <DropdownMenuContent
        forceMount={true}
        side={'bottom'}
        className={cn('bg-white w-[260px] lg:w-[235px] p-2')}
        style={{
          zIndex: 9999,
        }}
        sideOffset={5}
      >
        <div className={'flex gap-2'}>
          <NumericInput
            label="min"
            control={control}
            fieldName="min"
            decimalScale={decimalScale}
            allowNegative={minAllowNegative}
          />
          <NumericInput
            label="max"
            control={control}
            fieldName="max"
            decimalScale={decimalScale}
            allowNegative={maxAllowNegative}
          />
        </div>

        <FbButton className="mt-2 w-full" onClick={_handleSubmit}>
          Apply
        </FbButton>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
