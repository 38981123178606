import { useDataTableContext } from 'components/DataTable'
import apiService from 'services/api'
import lodash from 'lodash'
import confirm from 'components/dialogConfirm'
import { queryClient } from 'services/queryClient'
import { toast } from 'react-toastify'

interface BulkDealsDeleterProps {
  data: any[]
  accessorKey: string
  tableQueryKey: string[]
  filterParams: any
}

export function useBulkDealsDeleter(props: BulkDealsDeleterProps) {
  const api = apiService()

  const {
    state: { rowSelection, isAllRowsSelected },
  } = useDataTableContext()

  async function handleSubmit() {
    const selectedRows = props.data?.filter((_, i) => {
      if (isAllRowsSelected) {
        return !rowSelection?.[i]
      }
      return !!rowSelection?.[i]
    })

    const selectedDealIDs = selectedRows?.map((r: any) =>
      lodash.get(r, props.accessorKey)
    ) as number[]

    const count = selectedDealIDs.length
    console.log({ selectedDealIDs })

    const confirmed = await confirm(
      `Are you sure you want to delete ${count > 1 ? `all ${count} deals` : 'this deal'}?`,
      'Delete deals',
      undefined,
      {
        confirmLabel: 'Delete',
      }
    )

    if (!confirmed) {
      return
    }

    const data = await api.bulkDeleteDeals(selectedDealIDs, isAllRowsSelected)

    if (data?.message) {
      toast.info(
        data?.message ??
          'We are processing your request, you will be notified when it is done'
      )
    } else {
      toast.success('Deals deleted successfully')
    }
    queryClient.refetchQueries({ queryKey: props.tableQueryKey })
  }

  return handleSubmit
}
