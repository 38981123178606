/* eslint-disable @typescript-eslint/no-explicit-any */

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useDataTableContext } from 'components/DataTable'
import lodash from 'lodash'
import { toast } from 'react-toastify'
import { bulkEditMultiParams } from 'services/apiTypes'
import { paramsContainAccountFilters } from 'utils/filterUtils'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  ColumnDef,
  RowSelectionState,
  SortingState,
} from '@tanstack/react-table'
import { Modal } from '../../UI/Modal/Modal'
import { ClientDataTable } from '../../ClientDataTable/ClientDataTable'
import { AiOutlineTag } from 'react-icons/ai'
import FbButton from '../../FbUI/FbButton'
import { BsCheckLg, BsPlusSquare, BsXLg } from 'react-icons/bs'
import { Form } from 'react-bootstrap'
import confirm from 'components/dialogConfirm'

export interface AssignModalProps<T extends { id: number }, K> {
  show: boolean
  handleClose: () => void
  tableQueryKey: string[]
  idAccessor: string
  tableObjAccessor: string
  data: K[]
  filterAndSortParams: Record<string, any>
  apiAction: (params: bulkEditMultiParams) => Promise<any>
  items: T[]
  columns?: ColumnDef<T, any>[]
  onConfirmAddNew?: (value: string) => Promise<T>
  title: string | React.JSX.Element
  subtitle?: string | React.JSX.Element
  pluralLabel?: string
  singularLabel?: string
  tableFooter?: (selectedItems: T[]) => React.JSX.Element
  refetchFn?: () => Promise<any>
  initialOrdering?: SortingState
}

const useAssignMutation = <T extends { id: number }>(
  tableQueryKey: string[],
  apiAction: (params: bulkEditMultiParams) => Promise<any>,
  filterAndSortParams: Record<string, any>,
  handleClose: () => void,
  tableObjAccessor: string
) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      items,
      excludeIds,
      selectedIds,
      action,
      isAllRowsSelected,
    }: {
      items: T[]
      excludeIds?: string[]
      selectedIds?: string[]
      action: 'add' | 'delete'
      affectedRowsIndexes: number[]
      isAllRowsSelected: boolean
    }) => {
      const body: Record<string, any> = {
        ids: selectedIds,
        exclude_ids: excludeIds,
      }

      if (isAllRowsSelected) {
        delete body.ids
      }

      return apiAction({
        action,
        items_ids: items.map((it) => it.id),
        params: filterAndSortParams,
        ...body,
      })
    },
    onSuccess: (data) => {
      if (data.status === 202) {
        toast.info(
          data.data?.message ??
            'We are processing your request, you will be notified when it is done'
        )
      } else {
        toast.success(data.data?.message ?? 'Updated successfully')
        if (tableQueryKey && paramsContainAccountFilters(filterAndSortParams)) {
          queryClient.invalidateQueries({ queryKey: tableQueryKey })
        }
      }
      handleClose?.()
    },
    onError: (err) => {
      toast.error(`Error updating: ${err}`)
    },
    onMutate: ({ items, affectedRowsIndexes, action }) => {
      const previousData = queryClient.getQueryData(tableQueryKey)
      queryClient.setQueryData(tableQueryKey, (old: any) => {
        const newData = lodash.cloneDeep(old)
        affectedRowsIndexes.forEach((idx) => {
          const row = newData.results?.[idx] ?? newData?.[idx]
          let rowItems = lodash.get(row, tableObjAccessor, [])
          if (action === 'add') {
            const newItems = items.filter(
              (t: T) => !rowItems.some((rt: T) => rt.id === t.id)
            )
            rowItems = [...rowItems, ...newItems]
          } else {
            rowItems = rowItems.filter(
              (t: T) => !items.some((it) => it.id === t.id)
            )
          }
          lodash.set(row, tableObjAccessor, rowItems)
        })
        return newData
      })
      return () => queryClient.setQueryData(tableQueryKey, previousData)
    },
  })
}

export function AssignModal<T extends { id: number }, K>({
  show,
  handleClose,
  tableQueryKey,
  idAccessor,
  tableObjAccessor,
  data,
  filterAndSortParams,
  apiAction,
  items,
  columns,
  onConfirmAddNew,
  title,
  subtitle,
  pluralLabel,
  singularLabel,
  tableFooter,
  refetchFn,
  initialOrdering,
}: AssignModalProps<T, K>) {
  const {
    state: { rowSelection, isAllRowsSelected, totalRowsInBackend },
  } = useDataTableContext()

  const [isAdding, setIsAdding] = useState(false)
  const [newValue, setNewValue] = useState('')
  const [internalItems, setInternalItems] = useState<T[]>(items || [])
  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({})

  const inputAddNewRef = useRef<HTMLInputElement>(null)

  const { mutateAsync, isPending } = useAssignMutation(
    tableQueryKey,
    apiAction,
    filterAndSortParams,
    handleClose,
    tableObjAccessor
  )

  const selectedItems = useMemo(() => {
    return Object.entries(selectedRows)
      .filter(([, v]) => Boolean(v))
      .map(([k]) => internalItems[parseInt(k)])
  }, [internalItems, selectedRows])

  useEffect(() => {
    setInternalItems(items || [])
  }, [items])

  const handleMutate = useCallback(
    async (items: T[], action: 'add' | 'delete') => {
      const excludeIds = data
        .filter((_, i) => !rowSelection[i])
        .map((r) => lodash.get(r, idAccessor))
      const selectedIds = data
        .filter((_, i) => rowSelection[i])
        .map((r) => lodash.get(r, idAccessor))
      const affectedRowsIndexes = Object.keys(rowSelection).map((key) =>
        parseInt(key)
      )

      if (
        isAllRowsSelected &&
        totalRowsInBackend &&
        totalRowsInBackend > 1000
      ) {
        const confirmed = await confirm(
          `You are about to ${action === 'add' ? 'add' : 'delete'} ${totalRowsInBackend} ${pluralLabel}. This is a large number and may take a while to complete. Are you sure you want to proceed?`,
          `Bulk ${action === 'add' ? 'Add' : 'Delete'} ${pluralLabel}`
        )
        if (!confirmed) return
      }

      await mutateAsync({
        items,
        excludeIds,
        selectedIds,
        action,
        affectedRowsIndexes,
        isAllRowsSelected,
      })
      await refetchFn?.()
    },
    [data, rowSelection, isAllRowsSelected, idAccessor, mutateAsync]
  )

  const handleAddNew = useCallback(() => {
    setIsAdding(true)
    setTimeout(() => inputAddNewRef.current?.focus(), 100)
  }, [])

  const handleCancelAddNew = useCallback(() => {
    setIsAdding(false)
    setNewValue('')
  }, [])

  const handleAddNewConfirm = useCallback(async () => {
    if (!onConfirmAddNew) return
    try {
      const newItem = await onConfirmAddNew(newValue)
      if (!newItem) return
      setInternalItems((prev) => [...prev, newItem])
      setIsAdding(false)
      setNewValue('')
      toast.success(`Successfully added ${singularLabel}`)
    } catch (e) {
      toast.error(`Error adding ${singularLabel}: ${e}`)
    }
  }, [onConfirmAddNew, newValue, singularLabel])

  return (
    <Modal
      size="lg"
      open={show}
      title={title}
      description={subtitle}
      loading={isPending}
      onOpenChange={(open) => !open && handleClose?.()}
      onAccept={() => handleMutate(selectedItems, 'add')}
      blockAccept={!selectedItems.length}
      blockAlt={!selectedItems.length}
      onAlt={() => handleMutate(selectedItems, 'delete')}
      altButtonProps={{ variant: 'danger-outline' }}
      acceptButtonText="Apply"
      altButtonText="Delete"
    >
      <div>
        <ClientDataTable
          initialOrdering={initialOrdering}
          height={500}
          smallRows
          enableRowSelection
          onRowSelectionChange={setSelectedRows}
          selectedRows={selectedRows}
          columns={columns ?? []}
          data={internalItems}
          emptyContent={
            <div className="flex flex-col items-center gap-2">
              <AiOutlineTag size={25} />
              <p>Create your first {pluralLabel} to get started</p>
            </div>
          }
          footerContent={
            <div className="flex-1">
              <div className="flex items-center gap-2.5 px-6 py-2.5 border-b border-gray-200">
                {!isAdding ? (
                  <div className="w-full flex justify-between items-center">
                    {onConfirmAddNew ? (
                      <FbButton
                        className="bg-transparent text-fb-green-800 hover:shadow-none px-0"
                        onClick={handleAddNew}
                        type="button"
                      >
                        <BsPlusSquare />
                        Create {pluralLabel}
                      </FbButton>
                    ) : null}
                    <div className="flex items-center gap-3 ml-auto">
                      {selectedItems.length > 0 && (
                        <div className="rounded-full bg-fb-green-900 bg-opacity-20 text-fb-green-900 px-3 py-1">
                          {selectedItems.length} Selected
                        </div>
                      )}
                      <p className="text-gray-500">
                        {internalItems.length}{' '}
                        {internalItems.length <= 1
                          ? singularLabel?.toLowerCase()
                          : pluralLabel?.toLowerCase()}
                      </p>
                    </div>
                  </div>
                ) : isAdding ? (
                  <>
                    <div className={'relative flex-1'}>
                      <Form.Control
                        type="text"
                        placeholder={`New ${singularLabel}`}
                        ref={inputAddNewRef}
                        value={newValue}
                        maxLength={50}
                        onChange={(e) => setNewValue(e.target.value)}
                      />
                      <span
                        className={
                          'absolute top-3 right-3 m-0 text-xs font-light text-muted pointer-events-none'
                        }
                      >
                        {newValue.length}/50
                      </span>
                    </div>
                    <FbButton
                      variant="secondary"
                      onClick={handleCancelAddNew}
                      type="button"
                    >
                      <BsXLg />
                    </FbButton>
                    <FbButton
                      variant="primary"
                      onClick={handleAddNewConfirm}
                      type="button"
                    >
                      <BsCheckLg />
                    </FbButton>
                  </>
                ) : null}
              </div>
            </div>
          }
        />
        {tableFooter?.(selectedItems)}
      </div>
    </Modal>
  )
}
