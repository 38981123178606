import { format } from 'date-fns'
import { Badge, Spinner } from 'react-bootstrap'
import { RebateOffer } from './tradespend_api'
import { ExternalLinkIcon, Copy } from 'lucide-react'
import { toast } from 'react-toastify'

export const VITE_TRADESPEND_APP_HOST = import.meta.env
  .VITE_TRADESPEND_APP_HOST as string

const formatDiscount = (tier: any) => {
  if (tier.discount_type === 'dollars_off') {
    return `$${tier.discount} off`
  } else {
    return `${tier.discount}% off`
  }
}

function StatCard({
  label,
  value,
  color = 'gray',
  subValue,
}: {
  label: string
  value: number | string
  color?: 'gray' | 'green' | 'yellow' | 'red'
  subValue?: string
}) {
  const colorClasses = {
    gray: 'text-gray-900',
    green: 'text-green-600',
    yellow: 'text-yellow-600',
    red: 'text-red-600',
  }

  return (
    <div className="bg-white rounded-lg border border-gray-200 p-3">
      <label className="text-xs text-gray-600 block truncate" title={label}>
        {label}
      </label>
      <div
        className={`text-xl font-semibold ${colorClasses[color]} mt-1 truncate`}
        title={String(value)}
      >
        {value}
      </div>
      {subValue && (
        <div className="text-xs text-gray-500 truncate" title={subValue}>
          {subValue}
        </div>
      )}
    </div>
  )
}

function ProductTermsCard({ product, unit }: { product: any; unit: string }) {
  return (
    <div className="bg-white rounded-lg border border-gray-200 p-3">
      <div className="flex justify-between items-start gap-2 mb-2">
        <div className="min-w-0 flex-1">
          <h4
            className="text-base font-semibold text-gray-900 truncate"
            title={product.product_name}
          >
            {product.product_name}
          </h4>
          {product.product_desc && (
            <p
              className="text-xs text-gray-600 mt-0.5 truncate"
              title={product.product_desc}
            >
              {product.product_desc}
            </p>
          )}
        </div>
        <Badge bg="info" className="text-xs shrink-0">
          {unit}
        </Badge>
      </div>

      {/* Variants Section */}
      <div>
        <h5 className="text-xs font-medium text-gray-700 mb-1">
          Product Variants
        </h5>
        <div className="space-y-1">
          {product.product_variants.map((variant: any) => (
            <div
              key={variant.variant_id}
              className="flex items-center justify-between bg-gray-50 rounded p-1.5"
            >
              <span className="text-xs text-gray-600 truncate">
                {variant.variant_name}
              </span>
              <span className="text-xs text-gray-500">
                {variant.variant_format}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export function RebateOfferDetailsTab({
  offer,
  isLoading,
}: {
  offer?: RebateOffer
  isLoading?: boolean
}) {
  if (isLoading) {
    return (
      <div className="flex items-center justify-center p-12">
        <Spinner />
      </div>
    )
  }

  if (!offer) return null

  const validClaimsTotal = offer.unpaid_valid_claims + offer.paid_claims
  const validClaimsPercent = Math.round(
    (validClaimsTotal / offer.total_claims) * 100
  )

  const offerUrl = `${import.meta.env.VITE_TRADESPEND_APP_HOST || 'http://localhost:5174'}/offer/${offer.code}`

  return (
    <div className="flex flex-col lg:flex-row gap-4 p-4 w-full max-w-full">
      {/* Main Content */}
      <div className="w-full lg:w-3/5 space-y-4 min-w-0">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <div>
            <h3 className="text-sm font-semibold mb-2">Offer Information</h3>
            <div className="bg-white rounded-lg border border-gray-200 p-3 space-y-3">
              <div>
                <label className="text-xs text-gray-600">Name</label>
                <div
                  className="text-sm font-medium truncate"
                  title={offer.name}
                >
                  {offer.name}
                </div>
              </div>
              <div>
                <label className="text-xs text-gray-600">Code</label>
                <div
                  className="text-sm font-mono bg-gray-50 p-1 rounded truncate"
                  title={offer.code}
                >
                  {offer.code}
                </div>
              </div>
              <div>
                <label className="text-xs text-gray-600">Offer Link</label>
                <div className="flex items-center gap-2 mt-1">
                  <div className="flex-1 min-w-0">
                    <a
                      href={offerUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-xs text-blue-600 hover:text-blue-800 truncate block"
                      title={offerUrl}
                    >
                      {offerUrl}
                    </a>
                  </div>
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(offerUrl)
                      toast.success('Link copied to clipboard')
                    }}
                    className="p-1 hover:bg-gray-100 rounded-md transition-colors"
                    title="Copy link"
                  >
                    <Copy className="h-4 w-4 text-gray-500" />
                  </button>
                </div>
              </div>
              <div>
                <label className="text-xs text-gray-600">State</label>
                <div>
                  <Badge
                    bg={
                      offer.state === 'PUBLISHED'
                        ? 'success'
                        : offer.state === 'PENDING'
                          ? 'warning'
                          : 'secondary'
                    }
                  >
                    {offer.state}
                  </Badge>
                </div>
              </div>
              <div>
                <label className="text-xs text-gray-600">Valid Period</label>
                <div className="text-sm truncate">
                  {format(new Date(offer.valid_from), 'MMM d, yyyy')} -{' '}
                  {format(new Date(offer.valid_to), 'MMM d, yyyy')}
                </div>
              </div>
            </div>
          </div>

          <div className="lg:col-span-2">
            <h3 className="text-sm font-semibold mb-2">Claims Summary</h3>
            <div className="grid grid-cols-2 lg:grid-cols-3 gap-2">
              <StatCard
                label="Total Claims"
                value={offer.total_claims}
                color="gray"
              />
              <StatCard
                label="Pending Validation"
                value={offer.pending_validation_claims}
                color="yellow"
                subValue={`${Math.round(
                  (offer.pending_validation_claims / offer.total_claims) * 100
                )}% of total`}
              />
              <StatCard
                label="Invalid Claims"
                value={offer.invalid_claims}
                color="red"
                subValue={`${Math.round(
                  (offer.invalid_claims / offer.total_claims) * 100
                )}% of total`}
              />
              <StatCard
                label="Valid Claims (Unpaid)"
                value={offer.unpaid_valid_claims}
                color="green"
                subValue={`${Math.round(
                  (offer.unpaid_valid_claims / offer.total_claims) * 100
                )}% of total`}
              />
              <StatCard
                label="Valid Claims (Paid)"
                value={offer.paid_claims}
                color="green"
                subValue={`${Math.round(
                  (offer.paid_claims / offer.total_claims) * 100
                )}% of total`}
              />
              <StatCard
                label="Total Valid Claims"
                value={validClaimsTotal}
                color="green"
                subValue={`${validClaimsPercent}% of total`}
              />
            </div>

            <div className="grid grid-cols-2 gap-2 mt-2">
              <StatCard
                label="Total Valid Units"
                value={
                  offer.total_validated_units?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }) ?? '0.00'
                }
                color="gray"
              />
              <StatCard
                label="Total Valid Payout"
                value={`$${
                  offer.total_validated_payout?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }) ?? '0.00'
                }`}
                color="green"
              />
            </div>
          </div>
        </div>

        {/* Terms Section */}
        <div>
          <div className="flex items-center justify-between mb-2">
            <h3 className="text-sm font-semibold">Product Terms</h3>
            <div className="flex items-center gap-2">
              <Badge bg="secondary" className="text-xs">
                {offer.terms.company_name}
              </Badge>
              <Badge bg="info" className="text-xs">
                {offer.terms.unit}
              </Badge>
            </div>
          </div>

          {/* Global Tiers Section */}
          <div className="mb-4">
            <h4 className="text-sm font-medium text-gray-700 mb-2">
              Global Discount Tiers
            </h4>
            <div className="bg-white rounded-lg border border-gray-200 p-3">
              <div className="space-y-1">
                {offer.terms.tiers.map((tier, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between bg-gray-50 rounded p-1.5"
                  >
                    <span className="text-xs text-gray-600 truncate">
                      {tier.min} - {tier.max || '∞'} {offer.terms.unit}
                    </span>
                    <span className="font-medium text-xs text-green-600 shrink-0 ml-2">
                      {formatDiscount(tier)}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Products Grid */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            {offer.terms.products.map((product, index) => (
              <ProductTermsCard
                key={index}
                product={product}
                unit={offer.terms.unit}
              />
            ))}
          </div>
        </div>
      </div>

      {/* Preview Section */}
      <div className="w-full lg:w-2/5 bg-white rounded-lg border border-gray-200 flex flex-col">
        <div className="p-2 border-b border-gray-200 flex items-center justify-between">
          <h3 className="text-sm font-semibold">Offer Preview</h3>
          <a
            href={offerUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center gap-1 text-xs text-blue-600 hover:text-blue-800"
          >
            Open in new tab
            <ExternalLinkIcon className="h-3 w-3" />
          </a>
        </div>
        <iframe
          src={offerUrl}
          className="w-full flex-1 min-h-[800px] border-0"
          title="Offer Preview"
        />
      </div>
    </div>
  )
}
