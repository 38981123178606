export const OWNERSHIP_TYPE_OPTIONS = [
  { label: 'Corp - For Profit', value: 'For Profit - Corporation' },
  {
    label: 'Partnership - For Profit',
    value: 'For Profit - Partnership',
  },
  {
    label: 'Individual - For Profit',
    value: 'For Profit - Individual',
  },
  {
    label: 'LLC - For Profit',
    value: 'For Profit - Limited Liability Company',
  },
  { label: 'Corp - Non Profit', value: 'Non Profit - Corporation' },
  {
    label: 'Church - Non Profit',
    value: 'Non Profit - Church Related',
  },
  { label: 'Other - Non Profit', value: 'Non Profit - Other' },
  { label: 'State Govt', value: 'Government - State' },
  { label: 'Federal Govt', value: 'Government - Federal' },
  { label: 'City Govt', value: 'Government - City' },
  { label: 'County Govt', value: 'Government - County' },
  {
    label: 'City/County Govt',
    value: 'Government - City/County',
  },
  {
    label: 'Hospital District',
    value: 'Government - Hospital District',
  },
]

export const PROVIDER_TYPE_OPTIONS = [
  { label: 'Medicare & Medicaid', value: 'Medicare and Medicaid' },
  { label: 'Medicare', value: 'Medicare' },
  { label: 'Medicaid', value: 'Medicaid' },
]

export const AFFILIATED_ENTITY_NAME_OPTIONS_STR = [
  'A&M Healthcare Investments',
  'Abby Gl, Llc',
  'Abcm Corporation',
  'Ablehearts',
  'Abraham Bak & Menachem Gastwirth',
  'Abraham Kraus',
  'Abraham Smilow',
  'Abri Health Care',
  'Absolut Care',
  'Accolade Healthcare',
  'Accura Healthcare',
  'Acts Retirement-Life Communities',
  'Adams County Memorial Hospital',
  'Advanced Health Care',
  'Advanced Health Care Management',
  'Advanced Healthcare Solutions',
  'Adventist Health',
  'Adviniacare',
  'Ahava Healthcare',
  'Ahmc Healthcare',
  'Ajc Healthcare',
  'Akiko Ike',
  'Alaris Health',
  'Aliya Healthcare',
  'Allaire Health Services',
  'Allegiant Healthcare',
  'Alliance Health & Human Services',
  'Allure Healthcare Services',
  'Alpha Snf Ma',
  'Altercare',
  'Ama Holdings',
  'Ambassador Health',
  'American Baptist Homes Of The Midwest',
  'American Health Communities',
  'American Health Foundation',
  'American Senior Communities',
  'Americare Senior Living',
  'Anew Healthcare',
  'Anthony & Bryan Adams',
  'Aom Healthcare',
  'Aperion Care',
  'Apple Rehab',
  'Arabella Healthcare Management',
  'Arbor Care Centers',
  'Arboreta Healthcare',
  'Arbors At Ohio',
  'Arcadia Care',
  'Archcare',
  'Aristacare',
  'Arrowhead Healthcare Partners',
  'Asbury Communities',
  'Ascension Living',
  'Ascentria Care Alliance',
  'Aspen Skilled Healthcare',
  'Aspire Health Group',
  'Aspire Senior Living',
  'Athena Healthcare Systems',
  'Atied Associates',
  'Atlas Healthcare',
  'Atrium Centers',
  'Atrium Health',
  'Autumn Lake Healthcare',
  'Avalon Health Care',
  'Avamere',
  'Avante Centers',
  'Aventura Health Group',
  'Avera Health',
  'Ayden Healthcare',
  'Azria Health',
  'Azure Healthcare',
  'Ball Healthcare Services',
  'Ballad Health',
  'Banecare Management',
  'Bayshire Senior Communities',
  'Beacon Health Management',
  'Bear Mountain Healthcare',
  'Bedford Care Centers',
  'Bedrock Care',
  'Bedrock Healthcare',
  'Beebe Family',
  'Benedictine Health System',
  'Benjamin Landa',
  'Best Care Services',
  'Bethesda Health',
  'Bgm Estate',
  'Bhcp Ok4 Hh-H Sponsor, Llc',
  'Bhi Senior Living',
  'Big Rock Snf Holdings',
  'Birchwood',
  'Blue Central Group',
  'Blue Ridge Healthcare',
  'Blue Spring Healthcare Partners',
  'Bonamour Health Group',
  'Boncrest Resource Group',
  'Booker Hospital District',
  'Bradford Montgomery',
  'Bria Health Services',
  'Briar Hill Management',
  'Brickyard Healthcare',
  'Bridges Health',
  'Brius Management',
  'Brookdale Senior Living',
  'Bvhc, Llc',
  'C. Ross Management',
  'Cadia Healthcare',
  'Caldera Care',
  'Cambridge Healthcare Services',
  'Campbell Street Services',
  'Cantex Continuing Care',
  'Canyon Creek Healthcare',
  'Capital Health Care Network',
  'Capstone Management',
  'Caraday Healthcare',
  'Cardon & Associates',
  'Care & Rehab',
  'Care Initiatives',
  'Care Network',
  'Carecore Health',
  'Careone',
  'Carerite Centers',
  'Carespring',
  'Caring Healthcare Group',
  'Caring Place Healthcare Group',
  'Carlyle Senior Care',
  'Carmelite Sisters For The Aged And Infirm',
  'Carmelite Sisters For The Aged And Infirmed',
  'Carrolton Health',
  'Cascades Healthcare',
  'Cascadia Healthcare',
  'Cassena Care',
  'Cassia',
  'Castle Healthcare',
  'Catholic Charities New Hampshire',
  'Cavalier Healthcare',
  'Cch Healthcare',
  'Celebrate Senior Living',
  'Centennial Colorado Holdco',
  'Center Management Group',
  'Centers For Aging And Rehabilitation Of Florida',
  'Centers Health Care',
  'Central Arkansas Nursing Centers',
  'Central Management Company',
  'Century Care Management',
  'Century Healthcare',
  'Certus Healthcare',
  'Chambers County Public Hospital District No. 1',
  'Champion Care',
  'Chancellor Healthcare',
  'Charis Trust Dtd 12/22/16',
  'Charleston Healthcare Group',
  'Chelsea Jewish Lifecare',
  'Cheyenne Sheridan Opco Holdings',
  'Chi Living Communities',
  'Choice Health Management',
  'Choice Healthcare',
  'Chosen Healthcare',
  'Christian Care Communities',
  'Christian Horizons',
  'Ciena Healthcare/Laurel Health Care',
  'Circle B Enterprises',
  'Citadel Care Centers',
  'Citadel Healthcare',
  'Citizens Memorial Health Care',
  'Civita Care Centers',
  'Clear Choice Healthcare',
  'Colev Gestetner',
  'Commcare Corporation',
  'Commonspirit Health',
  'Commonwealth Care Of Roanoke',
  'Communicare Health',
  'Community Care Centers',
  'Community Eldercare Services',
  'Compass Health, Inc.',
  'Complete Care',
  'Concordia Lutheran Ministries',
  'Consulate Health Care/Independence Living Centers/Nspire Healthcare/Raydiant Health Care',
  'Continuing Healthcare Solutions',
  'Continuing Life',
  'Continuum Healthcare',
  'Cornerstone Group Holdings',
  'Coryell County Memorial Hospital Authority',
  'Country Club Rehabilitation Campus',
  'Country Villa Health Services',
  'Covenant Care',
  'Covenant Health',
  'Covenant Living',
  'Creative Solutions In Healthcare',
  'Crest Healthcare Consulting',
  'Crossroads Care Center',
  'Crossroads Medical Management',
  'Crown Healthcare Group',
  'Crowne Health Care',
  'Cypress Healthcare Group',
  'Cypress Healthcare La',
  'Cypress Skilled Nursing',
  'D&N, Llc',
  'Dakavia',
  'David Gamzeh & Gabriel Sebbag',
  'David Marx',
  'David Weisz',
  'Delmar Gardens',
  'Diakonos Group',
  'Diocesan Health Facilities',
  'Diversicare Healthcare',
  'Divine Healthcare Management',
  'Dove Healthcare',
  'Dynasty Healthcare Group',
  'Eastern Healthcare Group',
  'Eden Healthcare',
  'Eden Senior Care',
  'Eduro Healthcare',
  'El Dorado Nursing And Rehabilitation',
  'Elder Outreach Nursing & Rehabilitation',
  'Elder Services',
  'Elderwood',
  'Elevate Care',
  'Elevation Healthcare',
  'Elmbrook Management Company',
  'Embassy Healthcare',
  'Emerald Healthcare',
  'Empire Care Centers',
  'Empres Operated By Evergreen',
  'Envive Healthcare',
  'Ephram Lahasky',
  'Epic Healthcare Management',
  'Episcopal Retirement Services',
  'Erickson Living',
  'Essential Healthcare',
  'Eva Care Group',
  'Evangelical Good Samaritan Society',
  'Evangelical Lutheran Good Samaritan Society',
  'Everyage Senior Living',
  'Excelcare',
  'Excelsior Care Group',
  'Extended Care Associates',
  'Extended Care Clinical',
  'Family Of Caring Healthcare',
  'Felician Services',
  'First Atlantic Healthcare',
  'Florida Institute For Long-Term Care',
  'Focused Post Acute Care Partners',
  'Foundations Health Solutions',
  'Foursquare Healthcare',
  'Franciscan Communities',
  'Freedom Management Company',
  'Front Porch',
  'Frontline Management',
  'Fundamental Healthcare',
  'Future Care/Lifebridge Health',
  'Garden Healthcare',
  'Generations Healthcare',
  'Genesis Healthcare',
  'Global Healthcare Reit',
  'Gold Fl Trust Ii',
  'Golden Snf Operations',
  'Grace Healthcare',
  'Green Tree Healthcare Management',
  'Guardian Elder Care',
  'Gulf Coast Ltc Partners',
  'Hamilton County Hospital District',
  'Harborview Health Systems',
  'Harmony',
  'Harrison Senior Living',
  'Haven Health',
  'Hawaii Health Systems Corporation',
  'Hcf Management',
  'Hcf Management Indiana',
  'Hcr Manorcare',
  'Health Care Management Group',
  'Health Concepts, Ltd.',
  'Health Scholarships, Inc.',
  'Health Services Management',
  'Health Systems Facilities, Inc.',
  'Healthcare Accounting Services, Llc',
  'Healthcare Of Iowa',
  'Healthpeak Properties, Inc.',
  'Hearthstone Senior Communities',
  'Helia Healthcare',
  'Heritage Hall',
  'Heritage Ministries',
  'Heritage Operations Group',
  'Hickory Creek Healthcare Foundation',
  'Highbridge Healthcare',
  'Hill Valley Healthcare',
  'Hillstone Healthcare',
  'Hmg Healthcare',
  'Homewood Retirement Centers',
  'Humangood',
  'Huntsville Hospital Health System',
  'Hurlbut Care',
  'Hyatt Family Facilities',
  'Icare Health Network',
  'Ide Management Group',
  'Ignite Medical Resorts',
  'Ihs Management Consultants',
  'Immanuel',
  'Imperial Healthcare Group',
  'Infinite Care',
  'Infinity Healthcare Management',
  'Ingleside Engaged Living',
  'Innovative Healthcare Management',
  'Inspired Healthcare Management',
  'Integritus Healthcare',
  'Integrity Healthcare Communities',
  'Jag Healthcare',
  'James & Judy Lincoln',
  'Janet & Brenda Mandelbaum',
  'Jonathan Bleier & Yaakov Sod',
  'Jph Consulting',
  'Juckette Family Homes',
  'Kadima Healthcare Group',
  'Kalesta Healthcare Group',
  'Kendal',
  'Kindred Healthcare',
  'Kingston Healthcare',
  'Kisco Senior Living',
  'Kissito Healthcare',
  'Kpc Promise Healthcare',
  'Kr Management',
  'Lantis Enterprises',
  'Legacy Health Services',
  'Legacy Healthcare',
  'Legacy Nursing & Rehabilitation',
  'Lexington Health Management',
  'Liberty Senior Living',
  'Life Care Centers Of America',
  'Life Care Services',
  'Lifebrite Hospital Group',
  'Lifepoint Hospitals',
  'Lifespace Communities',
  'Lifespark',
  'Links Healthcare Group',
  'Lionstone Care',
  'Little Sisters Of The Poor',
  'Live Oak Healthcare',
  'Lme Family Holdings',
  'Loft Healthcare',
  'Longwood Management Corporation',
  'Lorien Health Services',
  'Lutheran Senior Services',
  'Lutheran Services Carolinas',
  'Madison Creek Partners',
  'Magnolia Manor Senior Living',
  "Maine Veterans' Home",
  'Majestic Care',
  'Major Hospital',
  'Maple Springs Living',
  'Mariner Health Care',
  'Marquis Companies',
  'Marquis Health Services',
  'Marsh Pointe Management',
  'Maximus Healthcare Group',
  'Mayer Family',
  'Mb Healthcare',
  'Mcculloch County Hospital District',
  'Medical Facilities Of America',
  'Medicalodges, Inc.',
  'Medilodge',
  'Mercyhealth System',
  'Mgm Healthcare',
  'Midwest Health',
  'Millennium Health Systems',
  "Miller'S Merry Manor",
  'Mission Health Communities',
  'Mission Health Services',
  'Mission Point Healthcare Services',
  'Mississippi Care Center',
  'Ml Healthcare',
  'Momentum Skilled Services',
  'Monarch Healthcare Management',
  'Moshe Blonder',
  'Nahs',
  'National Health Care Associates',
  'National Healthcare Corporation',
  'National Lutheran Communities & Services',
  'Nationwide Healthcare Services',
  'Neurorestorative',
  'New York City Health + Hospitals',
  'Nexcare Health Systems',
  'Nexion Health',
  'Next Step Healthcare',
  'Nhs Management',
  'Noland Health',
  'North Country Associates',
  'North Mississippi Health Services',
  'North Shore Healthcare',
  'Nursing Care Management Of America',
  "O'Neill Healthcare",
  'Oakbend Medical Center',
  'Ocean Healthcare',
  'Ohana Pacific Management Co.',
  'Ohio Living Communities',
  'Oklahoma Nursing Homes, Ltd.',
  'Onyx Health',
  'Opis Senior Services Group',
  'Optalis Health & Rehabilitation',
  'Optima Care',
  'Optimum Healthcare Management',
  'Osbycorp',
  'Otterbein Seniorlife',
  'P&M Management',
  'Pacific Retirement Services',
  'Pacs Group',
  'Palladian Healthcare',
  'Palm Garden Health And Rehabilitation Center',
  'Paradigm Healthcare',
  'Paradigm Senior Management',
  'Paragon Healthnet',
  'Paramount Healthcare Consultants',
  'Pavilion Healthcare',
  'Peach Health Group',
  'Peak Resources, Inc.',
  'Pearl Healthcare',
  'Personal Healthcare Management',
  'Personal Healthcare, Llc',
  'Petersen Health Care',
  'Philipson Family',
  'Philosophy Care Centers',
  'Phoenix Healthcare',
  'Phoenix Senior Living',
  'Piedmont Regional Health',
  'Pioneer Healthcare Management',
  'Pivotal Health Care',
  'Plainview Healthcare Partners',
  'Plantation Management Company',
  'Pollak Holdings',
  'Preferred Care',
  'Premier Healthcare Of Illinois',
  'Presbyterian Communities Of South Carolina',
  'Presbyterian Homes & Services',
  'Presbyterian Manors Of Mid-America',
  'Presbyterian Senior Living',
  'Prestige Administrative Services',
  'Prestige Care',
  'Prestige Care Center',
  'Prestige Healthcare Administrative Services',
  'Prime Health Care Enterprises',
  'Prime Healthcare',
  'Prime Healthcare Management',
  'Principle Long Term Care',
  'Priority Healthcare Group',
  'Priority Management',
  'Progressive Health Care Centers',
  'Progressive Quality Care',
  'Promontory Healthcare',
  'Providence Health & Services',
  'Providence Health Group',
  'Providence Healthcare Management',
  'Providence Life Services',
  'Pruitthealth',
  'Pulaski Memorial Hospital',
  'Purehealth',
  'Quality Life Services',
  'Reach Ltc',
  'Real Property Health Facilities',
  'Recover-Care Healthcare',
  'Regalcare',
  'Regency Care',
  'Regency Integrated Health Services',
  'Regency Pacific Management',
  'Regent Management Services',
  'Rehab Select',
  'Rehabilitation Associates',
  'Reliant Care Management',
  'Reliant Management Group',
  'Renew Health Consulting Services',
  'Rennes Group',
  'Residential Alternatives Of Illinois',
  'Retirement Housing Foundation',
  'Riley Spence Senior Living',
  'Rivers Edge Operations',
  'Riverside Health System',
  'Rochester Regional Health',
  'Rocky Mountain Care',
  'Rollins-Nelson Healthcare Management',
  'Royal Health Group',
  'Ryders Health Management',
  'S-H Forty-Nine Opco Ventures',
  'Saber Healthcare Group',
  'Saint Therese Senior Communities',
  'Sandstone Healthcare Group',
  'Sanford Health Good Samaritan (Prospera)',
  'Sanstone Health & Rehabilitation',
  'Sante',
  'Sapphire Care Group',
  'Sapphire Health Services',
  'Sava Senior Care',
  'Seky Holding Co.',
  'Senior Lifestyle',
  'Senior Living Communities',
  'Senior Living Properties',
  'Serrano Group',
  'Sherman Family',
  'Shlomo Rechnitz',
  'Signature Healthcare',
  'Simcha Hyman & Naftali Zanziper',
  'Skld',
  'Smp Health',
  'Solaris Healthcare',
  'South Limestone Hospital District',
  'Southern Administrative Services',
  'Southwest Ltc',
  'Sovereign Healthcare Holdings',
  'Spectrum Health',
  'Sprenger Health Care Systems',
  'Springpoint Senior Living',
  'Ssm Health',
  'St. Francis Health Services',
  'State Of Louisiana Department Of Veterans Affairs',
  "State Of New York Comptroller'S Office",
  'Stein Ltc',
  'Stellar Health Group',
  'Stellar Senior Living',
  'Sterling Care',
  'Stern Consultants',
  'Stonebridge Senior Living',
  'Stonegate Senior Living',
  'Stonehenge Of Utah',
  'Summit Ltc',
  'Summitt Care Ii, Inc.',
  'Sunrise Senior Living',
  'Sunterra Springs',
  'Sweetwater Care',
  'Symphony Care Network',
  'Synergy Senior Care',
  'Tampa 5 Fl Holdco',
  'Texvet',
  'The Alden Network',
  'The Blossoms Nursing And Rehab Center',
  'The Carpenter Health Network',
  'The Ensign Group',
  'The Goodman Group',
  'The Grand Healthcare',
  'The Mcguire Group',
  'The Orchards',
  'The Orchards Michigan',
  'The Pavilion Group',
  'The Peplinski Group',
  'The Rosenberg Family',
  'The Springs Arkansas',
  'The Wellbridge Group',
  'Thomas Chambers & David Johnson',
  'Tlc Management',
  'Touchstone Communities',
  'Transitions Healthcare',
  'Traylor Porter Healthcare Management',
  'Trend Consultants',
  'Trilogy Health Services',
  'Trinity Health',
  'Trinity Healthcare',
  'Trinity Management, Inc.',
  'Trio Healthcare',
  'Trousdale Living Communities',
  'Trustcare Management',
  'Tutera Senior Living & Health Care',
  'Twin Rivers Health & Rehabilitation',
  'United Church Homes',
  'United Methodist Homes',
  'University Of Vermont Health Network',
  'Unlimited Development, Inc.',
  'Upmc Senior Communities',
  'Upstate Services Group',
  'Uvalde County Hospital Authority',
  'Vancrest Health Care Centers',
  'Vanguard Healthcare',
  'Vantage Care',
  'Ventura Services Florida',
  'Venza Care Management',
  'Veritas Management Group',
  'Vertical Health Services',
  'Vetter Senior Living',
  'Vi Living',
  'Vibra Healthcare',
  'Vierra Communities',
  'Villa Healthcare',
  'Virginia Health Services',
  'Vivage Senior Living',
  'Vivo Healthcare',
  'Volare Health',
  'Volunteers Of America Senior Living',
  'Vrc Management',
  'Watermark Communities',
  'We Care Centers',
  'Wellington Health Care Services',
  'Wellsential Health',
  'Wellspring Lutheran Services',
  'Welltower, Inc.',
  'Wesleylife',
  'West Harbor Healthcare',
  'West Wharton County Hospital District',
  'Westminster Communities Of Florida',
  'White Oak Management',
  'Whittier Health Network',
  'Windsor',
  'Windsor House, Inc.',
  'Wisconsin Illinois Senior Housing, Inc.',
  'Wlc Management Firm',
  'Wvu Medicine',
  'Yad Healthcare',
]
export const AFFILIATED_ENTITY_NAME_OPTIONS =
  AFFILIATED_ENTITY_NAME_OPTIONS_STR.map((d) => ({ value: d, label: d }))
