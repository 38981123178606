import Ellipsis from 'components/Ellipsis'
import { FbLinkPill } from 'components/FbUI/FbLinkPill'
import SuggestionModal from 'components/Modals/SuggestionModal/SuggestionModal'
import { Anchor, RouterLink } from 'components/UI/Anchor'
import { CompanyTypeSlug } from 'models/companies'
import React, { useMemo } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { BsStarFill } from 'react-icons/bs'
import {
  formatInteger,
  formatPhoneNumber,
  formatTwoDecimal,
} from 'utils/formatting'
import { Block } from '../UI/Block'
import * as S from './Cards.styles'

interface addressInfo {
  city?: string
  state?: string
  zipcode?: string
  fullAddress?: string
  country?: string
  extra?: string
}

interface ChainHeaderProps {
  companyTypeSlug: CompanyTypeSlug
  contactCompanyId?: number
  doorId?: number
  isLoading?: boolean
  category?: string
  subCategory?: string
  name?: string
  breadcrumbs?: {
    breadcrumb?: string
    title?: string
    url?: string
  }[]
  companyLogoUrl?: string
  address?: {
    addressInfo?: addressInfo
    url?: string
  }
  phone?: string
  extension?: string
  description?: string
  editDomain?: () => void
  domainField?: string | null | undefined
  userCreated?: boolean

  rightColumnHeader?: any
  leftFirstBiteCol?: {
    chain?: number
    category?: string
    cuisine?: string
    reviews?: number
    rating?: number
  }
  customBottomLeftItems?: {
    header: string
    text: string | React.ReactNode
  }[]
}

const ChainHeaderCard = (props: ChainHeaderProps) => {
  const getUrlDomain = (url: string, withHttp?: boolean) => {
    if (!url) return ''
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = 'http://' + url
    }
    const urlObj = new URL(url)
    return withHttp ? 'http://' + urlObj.hostname : urlObj.hostname
  }

  const addressString = useMemo(() => {
    function getAddressString(addressInfo: addressInfo) {
      const arr: string[] = []

      function addPart(string: string) {
        if (arr.length > 0) {
          arr.push(', ')
        }
        arr.push(string)
      }

      if (!addressInfo) return ''

      for (const part of Object.values(addressInfo)) {
        if (part) {
          addPart(part)
        }
      }

      return arr.join('')
    }

    const addressInfo = props.address?.addressInfo
    if (addressInfo?.city) {
      addressInfo.city = addressInfo.city.split('_')[0] // Remove possble _ in city name (that is used to separate city and state)
    }

    return getAddressString(props.address?.addressInfo || {})
  }, [props.address])

  if (props.isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '500px',
        }}
      >
        <Spinner animation="border" />
      </div>
    )
  }

  return (
    <Block className={'mb-3'}>
      <Row>
        {/* Left Column */}
        <Col>
          <S.TopLeftFirstBiteCol>
            <Row>
              <Col>
                <S.BreadcrumbGroup>
                  <S.BreadcrumbBadge pill>
                    {props.category ? props.category : '-'} -{' '}
                    {props.subCategory ? props.subCategory : '-'}
                  </S.BreadcrumbBadge>
                  {props.breadcrumbs?.map((breadcrumb, i) => {
                    return (
                      <S.BreadcrumbContainer key={i}>
                        <S.RChevronIcon />
                        <div>
                          {breadcrumb.title && (
                            <S.BreadcrumbTitle>
                              {breadcrumb.title}:{' '}
                            </S.BreadcrumbTitle>
                          )}
                          {breadcrumb?.url ? (
                            <RouterLink to={breadcrumb.url} target="_blank">
                              {breadcrumb.breadcrumb}
                            </RouterLink>
                          ) : (
                            <span>{breadcrumb.breadcrumb}</span>
                          )}
                        </div>
                      </S.BreadcrumbContainer>
                    )
                  })}
                </S.BreadcrumbGroup>
              </Col>
            </Row>

            <Row>
              <Col className="mt-3">
                <S.CompanyHeader>
                  {props.companyLogoUrl ? (
                    <S.Logo src={props.companyLogoUrl} />
                  ) : (
                    <S.EmptyCompanyLogo>&nbsp;</S.EmptyCompanyLogo>
                  )}
                  <div className="flex align-items-center gap-2.5 flex-wrap">
                    <S.CompanyName>{props.name}</S.CompanyName>
                    <div className="flex align-items-center gap-2.5">
                      {props.domainField && (
                        <FbLinkPill
                          url={getUrlDomain(props.domainField, true) ?? ''}
                        >
                          {getUrlDomain(props.domainField)}
                        </FbLinkPill>
                      )}
                      {props.editDomain && (
                        <S.EditDomainContainer onClick={props.editDomain} />
                      )}
                    </div>
                  </div>
                </S.CompanyHeader>
              </Col>
            </Row>

            {(props.address || props.phone) &&
              props.companyTypeSlug !== 'restaurants-bars' && (
                <Row>
                  <Col className="mt-2">
                    <S.AddressPhoneWrapper>
                      {props.address && addressString && (
                        <div className="flex items-center">
                          <S.AddressIcon />
                          {props.address?.url ? (
                            <Anchor
                              target={'_blank'}
                              href={props.address?.url || '#'}
                            >
                              {addressString}
                            </Anchor>
                          ) : (
                            addressString
                          )}
                        </div>
                      )}
                      {props.phone && (
                        <div className="flex items-center">
                          <S.PhoneIcon />
                          <Anchor
                            href={
                              'tel:' +
                              props.phone +
                              (props.extension ? `,${props.extension}` : '')
                            }
                          >
                            {formatPhoneNumber({
                              phoneNumber: props.phone,
                              extension: props.extension,
                            })}
                          </Anchor>
                        </div>
                      )}
                    </S.AddressPhoneWrapper>
                  </Col>
                </Row>
              )}

            {props.description && (
              <Row>
                <Col className="mt-2">
                  <Ellipsis description={props.description} />
                </Col>
              </Row>
            )}
          </S.TopLeftFirstBiteCol>

          {props.userCreated && (
            <S.BottomLeftFirstBiteCol>
              <Row>
                <S.UserCreatedContainer>
                  User-created company
                </S.UserCreatedContainer>
              </Row>
            </S.BottomLeftFirstBiteCol>
          )}

          {props.customBottomLeftItems ? (
            <S.BottomLeftFirstBiteCol>
              <Row>
                {props.customBottomLeftItems.map((item, i) => (
                  <Col key={i}>
                    <S.BottomContainerHeader>
                      {item.header}
                    </S.BottomContainerHeader>
                    <S.BottomContainerText>{item.text}</S.BottomContainerText>
                  </Col>
                ))}
              </Row>
            </S.BottomLeftFirstBiteCol>
          ) : ['restaurants-bars'].includes(props.companyTypeSlug) &&
            !props.userCreated ? (
            <S.BottomLeftFirstBiteCol>
              <Row>
                <Col>
                  <S.BottomContainerHeader>Chain Size</S.BottomContainerHeader>
                  <S.BottomContainerText>
                    {formatInteger(
                      props.leftFirstBiteCol?.chain
                        ? props.leftFirstBiteCol?.chain
                        : undefined
                    )}
                  </S.BottomContainerText>
                </Col>
                <Col>
                  <S.BottomContainerHeader>
                    Expense Cat.
                  </S.BottomContainerHeader>
                  <S.BottomContainerText style={{ color: '#00876C' }}>
                    {props.leftFirstBiteCol?.category
                      ? props.leftFirstBiteCol?.category
                      : ''}
                  </S.BottomContainerText>
                </Col>
                <Col>
                  <S.BottomContainerHeader>Cuisine</S.BottomContainerHeader>
                  <S.BottomContainerText>
                    {props.leftFirstBiteCol?.cuisine
                      ? props.leftFirstBiteCol?.cuisine
                      : ''}
                  </S.BottomContainerText>
                </Col>
                <Col>
                  <S.BottomContainerHeader>
                    Total Reviews
                  </S.BottomContainerHeader>
                  <S.BottomContainerText>
                    {props.leftFirstBiteCol?.reviews
                      ? formatInteger(props.leftFirstBiteCol?.reviews)
                      : ''}
                  </S.BottomContainerText>
                </Col>
                <Col>
                  <S.BottomContainerHeader>
                    Average Rating
                  </S.BottomContainerHeader>
                  <S.BottomContainerText>
                    {props.leftFirstBiteCol?.rating ? (
                      <S.RatingContainer>
                        <BsStarFill color="#fcbc05" size={18} />
                        <span style={{ marginLeft: 5 }}>
                          {formatTwoDecimal(props.leftFirstBiteCol?.rating)}
                        </span>
                      </S.RatingContainer>
                    ) : (
                      ''
                    )}
                  </S.BottomContainerText>
                </Col>
              </Row>
            </S.BottomLeftFirstBiteCol>
          ) : (
            <></>
          )}
        </Col>
        {/* Right Column */}
        <Col>
          <Row>
            <Col>
              <div className="d-flex">
                <S.CompanyDetails>Company Details</S.CompanyDetails>
                {[
                  'restaurants-bars',
                  'education-cu',
                  'education-k-12',
                  'healthcare-hospitals',
                  'healthcare-nursinghomes',
                ].includes(props.companyTypeSlug) && (
                  <div className="ml-auto">
                    <SuggestionModal
                      contactCompanyId={props.contactCompanyId as number}
                      companyTypeSlug={props.companyTypeSlug}
                      doorId={props.doorId}
                      defaultValues={{
                        suggested_name: props.name,
                        suggested_website: props.domainField ?? '',
                        ...(props.doorId && {
                          suggested_address:
                            props.address?.addressInfo?.fullAddress,
                          suggested_phone_number: props.phone,
                        }),
                      }}
                    />
                  </div>
                )}
              </div>
            </Col>
          </Row>

          <Row>
            <Col>{props.rightColumnHeader}</Col>
          </Row>
        </Col>
      </Row>
    </Block>
  )
}

export default ChainHeaderCard
