import apiService from 'services/api'
import ExportModal, { ExportModalProps } from './ExportModal'
import { downloadFile } from 'utils/csvDownloader'
import { NURSING_HOME_COLUMNS } from 'components/DataExpansionComponents/NursingHomes/nursingHomeColumnsInfo'
import { createExportColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { getCommonChainProxyExportColumns } from 'components/DataExpansionComponents/Common/commonExportColumns'

export default function NursingHomeExportModal(props: ExportModalProps) {
  const api = apiService()

  const exportNursingHomes = async ({
    filter,
    selectedColumns,
    exportType,
    ids,
    excludeIds,
    controller,
  }: {
    filter: Record<string, unknown>
    selectedColumns: string[]
    exportType: 'csv' | 'xlsx'
    controller?: AbortController
    ids?: number[] | undefined
    excludeIds?: number[] | undefined
  }) => {
    const res = await api.exportNursingHomes(
      filter,
      selectedColumns,
      exportType,
      ids,
      excludeIds,
      controller?.signal
    )
    downloadFile(
      res,
      `First Bite Export: Nursing Homes - ${new Date().toLocaleDateString()}.${exportType}`
    )
  }

  const nursingHomeColumnHelper = createExportColumnHelper({
    columns: NURSING_HOME_COLUMNS,
  })

  const columns = [
    {
      title: 'Opportunity Attributes',
      elements: [
        nursingHomeColumnHelper.retrieveColumn('provider_name'),
        nursingHomeColumnHelper.retrieveColumn('address'),
        nursingHomeColumnHelper.retrieveColumn('city'),
        nursingHomeColumnHelper.retrieveColumn('state'),
        nursingHomeColumnHelper.retrieveColumn('zip_code'),
        nursingHomeColumnHelper.retrieveColumn('domain'),
        nursingHomeColumnHelper.retrieveColumn('website_url'),
      ],
    },
    {
      title: 'Nursing Home Insights',
      elements: [
        nursingHomeColumnHelper.retrieveColumn('number_of_certified_beds'),
        nursingHomeColumnHelper.retrieveColumn(
          'average_number_of_residents_per_day'
        ),
        nursingHomeColumnHelper.retrieveColumn('overall_rating'),
        nursingHomeColumnHelper.retrieveColumn('health_inspection_rating'),
        nursingHomeColumnHelper.retrieveColumn('qm_rating'),
        nursingHomeColumnHelper.retrieveColumn('staffing_rating'),
        nursingHomeColumnHelper.retrieveColumn('projected_meals_per_day'),
        nursingHomeColumnHelper.retrieveColumn('affiliated_entity_name'),
        nursingHomeColumnHelper.retrieveColumn('ownership_type'),
        nursingHomeColumnHelper.retrieveColumn('provider_type'),
        nursingHomeColumnHelper.retrieveColumn(
          'continuing_care_retirement_community'
        ),
      ],
    },
    {
      title: 'Predicted Values and Deals',
      elements: getCommonChainProxyExportColumns(),
    },
  ]

  return (
    <ExportModal
      title="Select Columns for Nursing Homes Download"
      subTitle="Download up to 10,000 nursing homes at a time, with any of the following columns. Any filters already applied will remain active."
      submitText="Export Nursing Homes"
      columns={columns}
      exportAPIAction={exportNursingHomes}
      {...props}
    />
  )
}
