import { createColumnHelper } from '@tanstack/react-table'
import { getCommonContactCompanyColumns } from 'components/DataExpansionComponents/Common/CommonTable/CommonColumns'
import { Anchor } from 'components/UI/Anchor'
import { usePreferences } from 'context/preferences/PreferencesContext'
import { useMemo } from 'react'
import { featureFlagService } from 'utils/featureFlagService'
import {
  integerFormatterOrFallback,
  usdFormatterOrFallbackString,
} from 'utils/formatting'
import { ContactCompanyType } from '../../../../models/contact_companies'
import { HospitalChainProxy } from '../../../../models/hospital'
import { DataTableColumnSort } from '../../../DataTable/DataTableSorting/DataTableSortingStore'
import { FbLink } from '../../../FbUI/FbLink'
import { HOSPITAL_COLUMNS } from '../hospitalColumnsInfo'
import { createTableColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'

export const useHospitalsTableCols = ({
  sorting,
  inCampaigns,
}: {
  sorting?: DataTableColumnSort[]
  inCampaigns?: boolean
}) => {
  const { preferences } = usePreferences()
  const tooltips: Record<string, unknown> = preferences?.tooltips || {}
  const featureFlag = featureFlagService()
  const columnHelper = createColumnHelper<HospitalChainProxy>()
  const hospitalColumnHelper = createTableColumnHelper({
    columns: HOSPITAL_COLUMNS,
    tooltips,
  })
  const columns = useMemo(() => {
    let cols = [
      columnHelper.accessor('name', {
        ...hospitalColumnHelper.retrieveColumn('hospital_name', {
          meta: {
            isEditable: false,
            isFirstColumn: true,
          },
        }),
        header: () => null,
        size: 250,
        cell: (info) => {
          const name = info.getValue()
          let link = ''
          if (info.row.original?.hospital?.id) {
            link = `/hospital/${
              preferences?.company_type_prefix_map[ContactCompanyType.Hospital]
            }${info.row.original.hospital?.id}`
          } else {
            link = `/company/${
              preferences?.company_type_prefix_map[
                ContactCompanyType.UserGenerated
              ]
            }${info.row.original?.id}`
          }
          return (
            <FbLink to={link} target="_blank">
              {name}
            </FbLink>
          )
        },
      }),
      columnHelper.accessor('address', {
        ...hospitalColumnHelper.retrieveColumn('street_addr'),
        size: 250,
        cell: (info) => {
          if (info.row.original?.hospital?.street_addr) {
            return info.row.original.hospital.street_addr
          } else {
            return info.getValue() || '-'
          }
        },
      }),
      columnHelper.accessor('hospital.city', {
        ...hospitalColumnHelper.retrieveColumn('city'),
        size: 150,
        cell: (info) => {
          if (info.row.original?.hospital?.city) {
            return info.row.original.hospital.city
          } else {
            return info.row.original.city || '-'
          }
        },
      }),
      columnHelper.accessor('hospital.state', {
        ...hospitalColumnHelper.retrieveColumn('state'),
        size: 100,
        cell: (info) => {
          if (info.row.original?.hospital?.state) {
            return info.row.original.hospital.state
          } else {
            return info.row.original.state || '-'
          }
        },
      }),
      columnHelper.accessor('hospital.zip_code', {
        ...hospitalColumnHelper.retrieveColumn('zip_code'),
        size: 100,
      }),
      columnHelper.accessor('hospital.google_place_url', {
        ...hospitalColumnHelper.retrieveColumn('google_place_url'),
        size: 150,
        cell: (info) => {
          let value = info.getValue() || undefined
          if (!info.row.original?.hospital?.google_place_url) {
            value = info.row.original?.google_place_url
          }
          if (!value) {
            return '-'
          }
          return (
            <Anchor href={value} target="_blank">
              View
            </Anchor>
          )
        },
      }),
      columnHelper.accessor('hospital.meals_per_day', {
        ...hospitalColumnHelper.retrieveColumn('meals_per_day'),
        size: 150,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor('hospital.total_hospital_days', {
        ...hospitalColumnHelper.retrieveColumn('total_hospital_days'),
        size: 150,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor('hospital.total_hospital_beds', {
        ...hospitalColumnHelper.retrieveColumn('total_hospital_beds'),
        size: 150,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor('hospital.cafeteria', {
        ...hospitalColumnHelper.retrieveColumn('cafeteria'),
        size: 150,
        cell: (info) => usdFormatterOrFallbackString(info.getValue()),
      }),
      columnHelper.accessor('hospital.dietary', {
        ...hospitalColumnHelper.retrieveColumn('dietary'),
        size: 150,
        cell: (info) => usdFormatterOrFallbackString(info.getValue()),
      }),
      columnHelper.accessor('hospital.dietary_under_contract', {
        ...hospitalColumnHelper.retrieveColumn('dietary_under_contract'),
        size: 150,
        cell: (info) => usdFormatterOrFallbackString(info.getValue()),
      }),
      columnHelper.accessor('domain', {
        ...hospitalColumnHelper.retrieveColumn('domain'),
        size: 200,
        cell: (info) => {
          const domain = info.getValue()
          if (!domain) {
            return '-'
          }
          return (
            <Anchor href={'//' + domain} target="_blank">
              {domain}
            </Anchor>
          )
        },
      }),
      columnHelper.accessor('hospital.website_url', {
        ...hospitalColumnHelper.retrieveColumn('website_url'),
        size: 150,
        cell: (info) => {
          const value = info.getValue()
          if (!value) {
            return '-'
          }
          return (
            <Anchor href={value} target="_blank">
              Visit
            </Anchor>
          )
        },
      }),
      ...getCommonContactCompanyColumns(
        columnHelper,
        sorting,
        preferences,
        inCampaigns
      ),
    ]
    if (!featureFlag.enableCampaigns) {
      cols = cols.filter((col) => !col.id?.includes('campaigns'))
    }
    return cols
  }, [columnHelper, sorting, featureFlag, preferences, inCampaigns])
  return columns
}
