import { zodResolver } from '@hookform/resolvers/zod'
import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { FilterIdentifier } from '../../../models/saved_view'
import apiService from '../../../services/api'
import { Column } from '../../../stores/ColumnsStore/ColumnsStore'
import { useColumnStoreContext } from '../../../stores/ColumnsStore/useColumnsStoreContext'
import FbButton from '../../FbUI/FbButton'
import { Combobox } from '../../UI/combobox/combobox'
import { ColumnPreset } from '../../../models/column_preset'
import { FaChevronDown, FaSave } from 'react-icons/fa'
import { TextInput } from 'components/FormUtils/TextInput'

export function ColumnPresetSelector(props: {
  currentCols: Column[]
  setCurrentCols: (cols: Column[]) => void
}) {
  const api = apiService()
  const savedPresetsIdentifier = useColumnStoreContext(
    (state) => state.savedPresetsIdentifier
  )
  const selectedColumnsStore = useColumnStoreContext(
    (state) => state.selectedColumns
  )
  const defaultPreset = useColumnStoreContext(
    (state) => state.defaultSelectedColumns
  )
  const { data: _presets, refetch } = api.useGetColumnPresetOptions(
    savedPresetsIdentifier as FilterIdentifier
  )
  const presets = useMemo(() => {
    const def: ColumnPreset = {
      payload: defaultPreset ?? [],
      id: -1,
      name: 'Default',
      identifier: savedPresetsIdentifier as FilterIdentifier,
      user: {
        first_name: '',
        last_name: '',
      },
    }

    const out = structuredClone(_presets)

    out?.unshift({
      label: 'Default',
      value: -1,
      original: def,
    })

    return out
  }, [_presets, defaultPreset])

  const formSchema = useMemo(
    () =>
      z.object({
        new_preset_name: z
          .string()
          .min(1, 'Enter a name to save this preset')
          .refine(
            (value) => {
              return !presets?.some((it) => it.original.name === value)
            },
            { message: 'Create a unique name to save this preset' }
          ),
      }),
    [presets]
  )

  type FormSchema = z.infer<typeof formSchema>

  // Form
  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm<FormSchema>({
    defaultValues: {
      new_preset_name: '',
      selected_preset: undefined,
    } as FormSchema,
    resolver: zodResolver(formSchema),
    mode: 'onChange',
  })

  const [isLoading, setIsLoading] = useState(false)
  const submit = handleSubmit(async (values) => {
    setIsLoading(true)
    await api.createColumnPreset({
      name: values.new_preset_name,
      identifier: savedPresetsIdentifier as FilterIdentifier,
      payload: props.currentCols,
      shared: true,
    })
    await refetch()
    reset()
    setIsLoading(false)
  })

  // Get the current preset
  const [presetLoaded, setPresetLoaded] = useState<number>()
  useEffect(() => {
    const loadedPreset = presets?.find((it) => {
      return _.isEqual(it.original.payload, props.currentCols)
    })

    setPresetLoaded(loadedPreset?.original.id)
  }, [props.currentCols, presets])

  // Compare state in store vs local state.
  const hasChanged = useMemo(
    () =>
      !_.isEqual(
        props.currentCols,
        selectedColumnsStore?.filter((column) => column.isEditable)
      ),
    [selectedColumnsStore, props.currentCols]
  )

  const isNewPreset = hasChanged && !presetLoaded

  if (!savedPresetsIdentifier) {
    return null
  }

  return (
    <div className={'pb-4'}>
      <div className={'w-full flex flex-row gap-5 items-center pt-2'}>
        <div>
          Choose a data table view from the Column Preset menu. You can can
          customize the view further by manually selecting and ordering columns.
        </div>
        {isNewPreset ? (
          <div className={'relative w-full flex flex-row items-end'}>
            <div
              className={
                'absolute -top-2 left-3 text-black z-10 text-xs px-1 bg-green-300 rounded'
              }
            >
              New Preset
            </div>
            <TextInput
              noMargin
              control={control}
              name={'new_preset_name'}
              className={'h-14'}
              maxLength={25}
            />
            <div
              className={
                'flex flex-row justify-end absolute top-0 right-0 h-14 items-center z-50 bg-white border border-l-0 rounded'
              }
            >
              <FbButton
                onClick={submit}
                type={'button'}
                loading={isLoading}
                className={'border-none bg-transparent hover:shadow-none'}
                disabled={!isValid}
              >
                <FaSave color={'black'} />
              </FbButton>
              <Combobox
                buttonComponent={
                  <FbButton
                    type={'button'}
                    className={'border-none bg-transparent hover:shadow-none'}
                  >
                    <FaChevronDown color={'black'} />
                  </FbButton>
                }
                buttonClassName={'w-full max-w-full'}
                options={presets ?? []}
                searchPlaceholder="Search..."
                placeholder="Select a preset"
                loading={isLoading}
                selected={presetLoaded}
                onSelect={(id) => {
                  const selection = presets?.find((it) => it.value == id)
                  if (selection) {
                    props.setCurrentCols(selection.original.payload)
                    setPresetLoaded(id as number)
                  }
                }}
              />
            </div>
          </div>
        ) : null}

        {!isNewPreset ? (
          <div className={'w-full flex flex-row items-end'}>
            <Combobox
              buttonClassName={'w-full max-w-full h-14'}
              options={presets ?? []}
              searchPlaceholder="Search..."
              placeholder="Select a preset"
              loading={isLoading}
              selected={presetLoaded}
              onSelect={(id) => {
                const selection = presets?.find((it) => it.value == id)
                if (selection) {
                  props.setCurrentCols(selection.original.payload)
                  setPresetLoaded(id as number)
                } else {
                  setPresetLoaded(undefined)
                }
              }}
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}
