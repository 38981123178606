import { createColumnHelper } from '@tanstack/react-table'
import { format } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { RebateClaim, RebateClaimStatus } from './tradespend_api'
import { formatUsd } from 'utils/formatting'
import { createTableColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { REBATE_CLAIMS_COLUMNS } from './rebateClaimsColumnInfo'
import { usePreferences } from 'context/preferences/PreferencesContext'
import { RebateStatusPill } from './RebateStatusPill'

export function useRebateClaimsColumns() {
  const navigate = useNavigate()
  const { preferences } = usePreferences()
  const tooltips: Record<string, unknown> = preferences?.tooltips || {}
  const columnHelper = createColumnHelper<RebateClaim>()
  const claimColumnHelper = createTableColumnHelper({
    columns: REBATE_CLAIMS_COLUMNS,
    tooltips,
  })

  return [
    columnHelper.accessor('company_name', {
      ...claimColumnHelper.retrieveColumn('company_name'),
      size: 200,
    }),
    columnHelper.accessor('status', {
      ...claimColumnHelper.retrieveColumn('status'),
      size: 150,
      cell: (info) => (
        <RebateStatusPill status={info.getValue() as RebateClaimStatus} />
      ),
    }),
    columnHelper.accessor('payout_amount', {
      ...claimColumnHelper.retrieveColumn('payout_amount'),
      size: 120,
      cell: (info) => formatUsd(info.getValue() || 0),
    }),
    columnHelper.accessor('offer_name', {
      ...claimColumnHelper.retrieveColumn('offer_name'),
      size: 200,
      cell: (info) => (
        <span
          className="text-green-800 hover:text-green-900 cursor-pointer"
          onClick={() =>
            navigate(`/rebates/offer/${info.row.original.offer_id}/details`)
          }
        >
          {info.getValue()}
        </span>
      ),
    }),
    columnHelper.accessor('enrollment_date', {
      ...claimColumnHelper.retrieveColumn('enrollment_date'),
      size: 150,
      cell: (info) => format(new Date(info.getValue()), 'MMM d, yyyy'),
    }),
    columnHelper.accessor('created', {
      ...claimColumnHelper.retrieveColumn('created'),
      size: 150,
      cell: (info) => format(new Date(info.getValue()), 'MMM d, yyyy'),
    }),
  ]
}
