import React from 'react'
import * as S from './styles'

const ErrorCard = ({
  title,
  description,
  legend,
}: {
  title: string | React.ReactNode
  description: string | React.ReactNode
  legend: string | React.ReactNode
}) => {
  const currentTimestamp = new Date().toLocaleString()

  return (
    <S.Card>
      <S.Container>
        <S.Header>
          <S.Title>{title}</S.Title>
          <S.Hr />
        </S.Header>
        <S.Content>
          <S.Description>{description}</S.Description>
          <S.Legend>{legend}</S.Legend>
          <div className="text-right mt-16 text-xs text-[#475467]">
            {currentTimestamp}
          </div>
        </S.Content>
      </S.Container>
    </S.Card>
  )
}

export default ErrorCard
