import { createColumnHelper } from '@tanstack/react-table'
import { getCommonContactCompanyColumns } from 'components/DataExpansionComponents/Common/CommonTable/CommonColumns'
import { Anchor } from 'components/UI/Anchor'
import { usePreferences } from 'context/preferences/PreferencesContext'
import { ContactCompanyType } from 'models/contact_companies'
import { K12ChainProxy } from 'models/k12'
import { useMemo } from 'react'
import { featureFlagService } from 'utils/featureFlagService'
import {
  integerFormatterOrFallback,
  percentFormatterOrFallback,
} from 'utils/formatting'
import { DataTableColumnSort } from '../../../DataTable/DataTableSorting/DataTableSortingStore'
import { FbLink } from '../../../FbUI/FbLink'
import { K12_COLUMNS } from '../K12ColumnsInfo'
import { createTableColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'

export const useK12TableColumns = ({
  sorting,
  inCampaigns,
}: {
  sorting?: DataTableColumnSort[]
  inCampaigns?: boolean
}) => {
  const { preferences } = usePreferences()
  const tooltips: Record<string, unknown> = preferences?.tooltips || {}
  const columnHelper = createColumnHelper<K12ChainProxy>()
  const featureFlag = featureFlagService()
  const k12ColumnHelper = createTableColumnHelper({
    columns: K12_COLUMNS,
    tooltips,
  })

  const columns = useMemo(() => {
    let cols = [
      columnHelper.accessor('k12district.lea_name', {
        ...k12ColumnHelper.retrieveColumn('lea_name', {
          meta: {
            isEditable: false,
            isFirstColumn: true,
          },
        }),
        header: () => null,
        size: 250,
        cell: (info) => {
          if (info.row.original?.k12district) {
            const name = info.getValue()
            return (
              <FbLink
                to={`/k12/${preferences?.company_type_prefix_map[ContactCompanyType.K12District]}${info.row.original?.k12district?.id}`}
                target="_blank"
              >
                {name}
              </FbLink>
            )
          } else {
            return (
              <FbLink
                to={`/company/${preferences?.company_type_prefix_map[ContactCompanyType.UserGenerated]}${info.row.original?.id}`}
                target="_blank"
              >
                {info.row.original.name}
              </FbLink>
            )
          }
        },
      }),
      columnHelper.accessor('k12district.address', {
        ...k12ColumnHelper.retrieveColumn('address'),
        size: 200,
      }),
      columnHelper.accessor('k12district.zipcode', {
        ...k12ColumnHelper.retrieveColumn('zipcode'),
        size: 100,
      }),
      columnHelper.accessor('k12district.lcity', {
        ...k12ColumnHelper.retrieveColumn('lcity'),
        size: 150,
      }),
      columnHelper.accessor('k12district.lstate', {
        ...k12ColumnHelper.retrieveColumn('lstate'),
        size: 100,
      }),
      columnHelper.accessor('k12district.county', {
        ...k12ColumnHelper.retrieveColumn('county'),
        size: 200,
      }),
      columnHelper.accessor('k12district.locale', {
        ...k12ColumnHelper.retrieveColumn('locale'),
        size: 200,
      }),
      columnHelper.accessor('k12district.operational_schools', {
        ...k12ColumnHelper.retrieveColumn('operational_schools'),
        size: 150,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor('k12district.gslo', {
        ...k12ColumnHelper.retrieveColumn('gslo'),
        size: 100,
      }),
      columnHelper.accessor('k12district.gshi', {
        ...k12ColumnHelper.retrieveColumn('gshi'),
        size: 100,
      }),
      columnHelper.accessor('k12district.student_count', {
        ...k12ColumnHelper.retrieveColumn('student_count'),
        size: 150,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor('k12district.teachers_count', {
        ...k12ColumnHelper.retrieveColumn('teachers_count'),
        size: 150,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor('k12district.phone', {
        ...k12ColumnHelper.retrieveColumn('phone'),
        size: 150,
      }),
      ...getCommonContactCompanyColumns(
        columnHelper,
        sorting,
        preferences,
        inCampaigns
      ),
      columnHelper.accessor('k12district.revenue', {
        ...k12ColumnHelper.retrieveColumn('revenue'),
        size: 200,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor('k12district.reduced_lunch', {
        ...k12ColumnHelper.retrieveColumn('reduced_lunch'),
        size: 250,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor('k12district.free_lunch', {
        ...k12ColumnHelper.retrieveColumn('free_lunch'),
        size: 250,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor('k12district.estimated_meals_per_day', {
        ...k12ColumnHelper.retrieveColumn('estimated_meals_per_day'),
        size: 200,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor('k12district.food_service_expenditures', {
        ...k12ColumnHelper.retrieveColumn('food_service_expenditures'),
        size: 200,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor('k12district.total_supplemented_lunch', {
        ...k12ColumnHelper.retrieveColumn('total_supplemented_lunch'),
        size: 250,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor('k12district.total_supplemented_lunch_perc', {
        ...k12ColumnHelper.retrieveColumn('total_supplemented_lunch_perc'),
        size: 250,
        cell: (info) => {
          return percentFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor('k12district.domain', {
        ...k12ColumnHelper.retrieveColumn('domain'),
        size: 200,
        cell: (info) => {
          const domain = info.getValue()
          if (!domain) {
            return '-'
          }
          return (
            <Anchor href={'//' + domain} target="_blank">
              {domain}
            </Anchor>
          )
        },
      }),
      columnHelper.accessor('k12district.google_place_url', {
        ...k12ColumnHelper.retrieveColumn('google_place_url'),
        size: 200,
        cell: (info) => {
          const value = info.getValue()
          if (!value) {
            return '-'
          }
          return (
            <Anchor href={value} target="_blank">
              View
            </Anchor>
          )
        },
      }),
      columnHelper.accessor('k12district.uncategorized_lunch', {
        ...k12ColumnHelper.retrieveColumn('uncategorized_lunch'),
        size: 250,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor('k12district.free_lunch_perc', {
        ...k12ColumnHelper.retrieveColumn('free_lunch_perc'),
        size: 250,
        cell: (info) => {
          return percentFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor('k12district.reduced_lunch_perc', {
        ...k12ColumnHelper.retrieveColumn('reduced_lunch_perc'),
        size: 250,
        cell: (info) => {
          return percentFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor('k12district.uncategorized_lunch_perc', {
        ...k12ColumnHelper.retrieveColumn('uncategorized_lunch_perc'),
        size: 250,
        cell: (info) => {
          return percentFormatterOrFallback(info.getValue()) || '-'
        },
      }),
    ]

    if (!featureFlag.enableCampaigns) {
      cols = cols.filter((col) => !col.id?.includes('campaigns'))
    }
    return cols
  }, [columnHelper, sorting, preferences, inCampaigns, featureFlag])

  return columns
}
