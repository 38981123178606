import { createColumnHelper } from '@tanstack/react-table'
import { format } from 'date-fns'
import { Badge } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { RebateOffer, RebateOfferType } from './tradespend_api'
import { formatInteger, formatUsd } from 'utils/formatting'
import { createTableColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { REBATE_OFFERS_COLUMNS } from './rebateOffersColumnInfo'
import { usePreferences } from 'context/preferences/PreferencesContext'
import { RebateOfferTypePill } from './RebateOfferTypePill'

export function useRebateOffersColumns() {
  const navigate = useNavigate()
  const { preferences } = usePreferences()
  const tooltips: Record<string, unknown> = preferences?.tooltips || {}
  const columnHelper = createColumnHelper<RebateOffer>()
  const offerColumnHelper = createTableColumnHelper({
    columns: REBATE_OFFERS_COLUMNS,
    tooltips,
  })

  return [
    columnHelper.accessor('name', {
      ...offerColumnHelper.retrieveColumn('name'),
      size: 200,
      cell: (info) => (
        <span
          className="text-green-800 hover:text-green-900 cursor-pointer"
          onClick={() =>
            navigate(`/rebates/offer/${info.row.original.id}/details`)
          }
        >
          {info.getValue()}
        </span>
      ),
    }),
    columnHelper.accessor('state', {
      ...offerColumnHelper.retrieveColumn('state'),
      size: 120,
      cell: (info) => (
        <Badge
          bg={
            info.getValue() === 'PUBLISHED'
              ? 'success'
              : info.getValue() === 'PENDING'
                ? 'warning'
                : 'secondary'
          }
        >
          {info.getValue()}
        </Badge>
      ),
    }),
    columnHelper.accessor('scope', {
      ...offerColumnHelper.retrieveColumn('scope'),
      size: 120,
      cell: (info) => <Badge bg="info">{info.getValue()}</Badge>,
    }),
    columnHelper.accessor('offer_type', {
      ...offerColumnHelper.retrieveColumn('offer_type'),
      size: 150,
      cell: (info) => (
        <RebateOfferTypePill type={info.getValue() as RebateOfferType} />
      ),
    }),
    columnHelper.accessor('total_enrollments', {
      ...offerColumnHelper.retrieveColumn('total_enrollments'),
      size: 120,
      cell: (info) => formatInteger(info.getValue() || 0),
    }),
    columnHelper.accessor('total_claims', {
      ...offerColumnHelper.retrieveColumn('total_claims'),
      size: 120,
      cell: (info) => formatInteger(info.getValue() || 0),
    }),
    columnHelper.accessor('pending_validation_claims', {
      ...offerColumnHelper.retrieveColumn('pending_validation_claims'),
      size: 120,
      cell: (info) => formatInteger(info.getValue() || 0),
    }),
    columnHelper.accessor('invalid_claims', {
      ...offerColumnHelper.retrieveColumn('invalid_claims'),
      size: 120,
      cell: (info) => formatInteger(info.getValue() || 0),
    }),
    columnHelper.accessor('paid_claims', {
      ...offerColumnHelper.retrieveColumn('paid_claims'),
      size: 120,
      cell: (info) => formatInteger(info.getValue() || 0),
    }),
    columnHelper.accessor('unpaid_valid_claims', {
      ...offerColumnHelper.retrieveColumn('unpaid_valid_claims'),
      size: 120,
      cell: (info) => formatInteger(info.getValue() || 0),
    }),
    columnHelper.accessor('total_validated_units', {
      ...offerColumnHelper.retrieveColumn('total_validated_units'),
      size: 120,
      cell: (info) => formatInteger(info.getValue() || 0),
    }),
    columnHelper.accessor('total_validated_payout', {
      ...offerColumnHelper.retrieveColumn('total_validated_payout'),
      size: 150,
      cell: (info) => formatUsd(info.getValue() || 0),
    }),
    columnHelper.accessor('valid_from', {
      ...offerColumnHelper.retrieveColumn('valid_from'),
      size: 150,
      cell: (info) => format(new Date(info.getValue()), 'MMM d, yyyy'),
    }),
    columnHelper.accessor('valid_to', {
      ...offerColumnHelper.retrieveColumn('valid_to'),
      size: 150,
      cell: (info) => format(new Date(info.getValue()), 'MMM d, yyyy'),
    }),
    columnHelper.accessor('product_names', {
      ...offerColumnHelper.retrieveColumn('product_names'),
      size: 200,
    }),
  ]
}
