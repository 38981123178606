import apiService from 'services/api'
import { Tag } from 'models/tags'
import BaseSelectModal, {
  BaseSelectModalProps,
} from './BaseSelectModal/BaseSelectModal'
import { bulkEditSingleParams } from 'services/apiTypes'

export default function AccountOwnerSelectModal({
  tableObjAccessor = 'account_owner',
  ...props
}: {
  tableObjAccessor?: string
} & Omit<
  BaseSelectModalProps<Tag>,
  | 'apiAction'
  | 'title'
  | 'subtitle'
  | 'label'
  | 'placeholder'
  | 'onConfirmAddNew'
  | 'items'
  | 'tableObjAccessor'
>) {
  const api = apiService()
  const { data: accountOwners } = api.useGetUserOptions({
    onlyActive: true,
    withNoneOption: true,
  })

  const apiAction = async (params: bulkEditSingleParams) => {
    const { item_id: account_owner, ...rest } = params
    return api.bulkEditDeal({
      deal: { account_owner_id: account_owner },
      ...rest,
    })
  }

  if (!accountOwners) return null

  return (
    <BaseSelectModal
      {...props}
      title="Assign Account Owner"
      subtitle={
        <span>
          Add or replace the account owner for <b>all</b> selected rows.
        </span>
      }
      label="Name"
      placeholder="Select Account Owner"
      items={accountOwners}
      tableObjAccessor={tableObjAccessor!}
      apiAction={apiAction}
    />
  )
}
