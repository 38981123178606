import { Deal } from '../../../models/deals'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { TextInput } from '../../FormUtils/TextInput'
import React, { useEffect } from 'react'
import Select from '../../FormUtils/Select'
import apiService from '../../../services/api'
import { Modal } from 'components/UI/Modal/Modal'
import { CreatableTagSelector } from 'components/CreatableTagSelector'
import { CreatableDistributorSelector } from 'components/CreatableDistributorSelector'
import { toast } from 'react-toastify'
import { NoteManager } from 'components/Notes/NoteManager'
import { featureFlagService } from 'utils/featureFlagService'

interface EditDealModalProps {
  show: boolean
  onClose: () => void
  deal?: Deal
  onDealEdited?: () => void
}

export function EditDealModal(props: EditDealModalProps) {
  const featureFlag = featureFlagService()
  const noChainSet = false
  const editDealSchema = z.object({
    monthly_volume_override: z.union([
      noChainSet ? z.number().min(0) : z.number().min(0).optional().nullable(),
      z.literal(''),
    ]),
    monthly_revenue_override: z.union([
      noChainSet ? z.number().min(0) : z.number().min(0).optional().nullable(),
      z.literal(''),
    ]),
    close_date_override: z.string().optional().nullable(),
    account_owner: z.number().optional().nullable(),
    sales_stage: z.number().optional().nullable(),
    notes: z.string().optional().nullable(),
    attachments: z.array(z.any()).optional().nullable(),
    tags: z.array(z.number()).optional().nullable(),
    distributors: z.array(z.number()).optional().nullable(),
    origin_campaign_id: z.number().optional().nullable(),
  })
  const api = apiService()
  const { data: stageOptions } = api.useGetSalesStageOptions()
  const { data: userOptions } = api.useGetUserOptions({ onlyActive: true })
  const { data: tagsOptions } = api.useGetTagsOptions(false, 'products')
  const { data: distributorOptions } = api.useGetCompanyDistributorsOptions()
  const handleClose = () => {
    props.onClose()
  }

  const { data: campaignOptions } = api.useGetCampaignsOptions()
  const [loading, setLoading] = React.useState(false)

  const form = useForm({
    resolver: zodResolver(editDealSchema),
  })

  useEffect(() => {
    if (!props.deal) return
    form.reset({
      monthly_volume_override: props.deal?.monthly_volume_override,
      monthly_revenue_override: props.deal?.monthly_revenue_override,
      close_date_override: props.deal?.close_date_override,
      account_owner: props.deal?.account_owner,
      sales_stage: props.deal?.sales_stage,
      notes: '', // Notes always reset to empty
      attachments: [], // Attachments always reset to empty
      tags: props.deal?.contact_company?.taglist?.map((tag) => tag.id),
      distributors: props.deal?.contact_company?.distributors?.map(
        (distributor) => distributor.id
      ),
      origin_campaign_id: props.deal?.origin_campaign?.id,
    })
  }, [props.deal, props.show])

  const onSubmit = form.handleSubmit(async (values) => {
    if (!props.deal) return

    const body = {
      monthly_volume_override: values.monthly_volume_override || null,
      monthly_revenue_override: values.monthly_revenue_override || null,
      close_date_override: values.close_date_override,
      account_owner: values.account_owner,
      sales_stage: values.sales_stage,
      notes: values.notes,
      attachments: values.attachments,
      origin_campaign_id: values.origin_campaign_id,
      tags: values.tags.length > 0 ? values.tags : 'REMOVE',
      distributors:
        values.distributors.length > 0 ? values.distributors : 'REMOVE',
    }

    setLoading(true)
    await api.editDeal(props.deal.id, body)
    toast.success('Deal updated successfully')
    setLoading(false)
    props.onDealEdited?.()
    handleClose()
  })

  return (
    <Modal
      loading={loading}
      onAccept={onSubmit}
      onCancel={handleClose}
      title="Edit Deal"
      open={props.show}
      onOpenChange={(open) => !open && props.onClose()}
      acceptButtonText="Save"
    >
      <div className="flex flex-col gap-2">
        <TextInput
          noMargin
          control={form.control}
          label={'Monthly Volume (override)'}
          name={`monthly_volume_override`}
          type="number"
          placeholder="### lbs."
        />
        <TextInput
          noMargin
          control={form.control}
          label={'Monthly Revenue (override)'}
          name={`monthly_revenue_override`}
          type="number"
          placeholder="$"
        />
        <TextInput
          noMargin
          onClear={() => form.setValue('close_date_override', '')}
          control={form.control}
          name={`close_date_override`}
          label={'Close Date (override)'}
          type="date"
        />
        {featureFlag.enableCampaigns ? (
          <Select
            label={'Origin Campaign'}
            control={form.control}
            name={`origin_campaign_id`}
            options={campaignOptions}
            placeholder="Select a campaign"
          />
        ) : null}
        <Select
          label={'Sales Stage'}
          control={form.control}
          name={`sales_stage`}
          options={stageOptions}
          placeholder="Select a stage"
        />
        <Select
          label={'Account Owner'}
          control={form.control}
          name={`account_owner`}
          options={userOptions}
          placeholder="Select an owner"
        />
        <NoteManager
          control={form.control}
          attachmentsName="attachments"
          noteName="notes"
        />
        <CreatableTagSelector
          name="tags"
          control={form.control}
          label="Tags"
          placeholder="Tags"
          options={tagsOptions}
          setError={form.setError}
          tagParent="products"
        />
        <CreatableDistributorSelector
          name="distributors"
          control={form.control}
          label="Distributors"
          placeholder="Distributor(s)"
          options={distributorOptions}
          setError={form.setError}
        />
      </div>
    </Modal>
  )
}
