import * as React from 'react'
import { cn } from '../cn'

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

const ComboboxButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, ...props }, ref) => {
    return (
      <button
        className={
          cn(
          'h-10 px-2.5 py-2 inline-flex items-center justify-center whitespace-nowrap rounded-md ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50  border border-slate-200 bg-white cursor-default',
          className
          )
        }
        ref={ref}
        {...props}
      />
    )
  }
)
ComboboxButton.displayName = 'ComboboxButton'

export { ComboboxButton }
