import { clientInstance } from '../utils/http-client'
import { PaginatedResponse } from '../models/paginated-response'
import qs from 'query-string'
import { baseAPICall } from './baseAPICall'
import { EmailIntegration, EmailMessage } from 'models/emailIntegration'

export async function getEmailIntegrationList(
  params = {},
  signal?: AbortSignal
) {
  return baseAPICall<PaginatedResponse<EmailIntegration>>(() =>
    clientInstance.get(`/email_integration/`, {
      params: {
        ...params,
      },
      signal,
      paramsSerializer: (p) => {
        return qs.stringify(p)
      },
    })
  )
}

export async function getEmailIntegrationAuthUrl(
  email: string,
  isShared: boolean
): Promise<{ auth_url: string }> {
  return baseAPICall(() =>
    clientInstance.get(`/email_integration/get_auth_url/`, {
      params: {
        email_to_connect: email,
        is_shared: isShared,
      },
      paramsSerializer: (p) => {
        return qs.stringify(p)
      },
    })
  )
}

export async function updateEmailIntegration(
  emailIntegrationId: number,
  data: Partial<{ is_shared: boolean }>
) {
  return baseAPICall(() =>
    clientInstance.patch(`/email_integration/${emailIntegrationId}/`, data)
  )
}

export async function syncEmailIntegration(emailIntegrationId: number) {
  return baseAPICall(() =>
    clientInstance.post(`/email_integration/${emailIntegrationId}/sync/`)
  )
}

export async function deleteEmailIntegration(emailIntegrationId: number) {
  return baseAPICall(() =>
    clientInstance.delete(`/email_integration/${emailIntegrationId}/`)
  )
}

export async function getEmailMessageList(params = {}, signal?: AbortSignal) {
  return baseAPICall<PaginatedResponse<EmailMessage>>(() =>
    clientInstance.get(`/email_integration/email_message/`, {
      params: {
        ...params,
      },
      signal,
      paramsSerializer: (p) => {
        return qs.stringify(p)
      },
    })
  )
}
