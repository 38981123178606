import { useQuery } from '@tanstack/react-query'
import CreatableSelect from 'components/FormUtils/CreatableSelect'
import { ContactCompany } from 'models/companies'
import { useEffect, useMemo, useState } from 'react'
import { Control, FieldValues, Path, useController } from 'react-hook-form'
import apiService from 'services/api'
import { useDebounce } from 'use-debounce'
import { featureFlagService } from 'utils/featureFlagService'
import { ContactCreateRequest } from '../../models/contacts'

type CompanyOption = {
  label: string | React.JSX.Element
  value: ContactCreateRequest['contact_company'] | ContactCompany
}

export function CompanyAutocomplete<T extends FieldValues>({
  control,
  name,
  initialCompany,
  placeholder,
  initialSearch,
  menuPosition,
  label = 'Company',
  onFetchDone,
  disabled,
  disableCreate = false,
  readOnly,
  autoSelectFirstOption = false,
  className,
  height = '50px',
  chainsOnly = false,
}: {
  control: Control<T, any>
  name: Path<T>
  initialCompany?: ContactCompany
  placeholder?: string
  initialSearch?: string
  menuPosition?: 'fixed' | 'absolute'
  label?: string | null
  onFetchDone?: () => void
  disabled?: boolean
  disableCreate?: boolean
  readOnly?: boolean
  autoSelectFirstOption?: boolean
  className?: string
  height?: string
  chainsOnly?: boolean
}) {
  const [searchCompany, setSearchCompany] = useState(initialSearch || '')
  const [search] = useDebounce(searchCompany, 500)

  const api = apiService()
  const {
    enableK12Table,
    enableCnUTable,
    enableHospitals,
    enableNursingHomes,
  } = featureFlagService()

  const {
    field: { onChange },
  } = useController({ name: name, control })

  const { data, isFetching, isFetched } = useQuery({
    queryKey: ['companies_list_search', search],
    queryFn: async ({ signal }) => {
      return await api.searchCompaniesList({ search: search }, signal)
    },
    enabled: search !== '' && !disabled,
  })

  useEffect(() => {
    if (onFetchDone && isFetched) onFetchDone()
  }, [isFetched])

  const companiesOptions = useMemo((): CompanyOption[] => {
    const getInitialCompanyLabel = (name: string, domain?: string) => {
      if (domain) {
        return (
          <>
            <span>
              <b>{name}</b>
              {domain ? ` - ${domain}` : ''}
            </span>
          </>
        )
      }
      return <b>{name}</b>
    }

    // note: initial contact company does not have sub category name, just id
    // note: the control will not display the initial value in the ui component
    //       unless we provide the full initialCompany (ContactCompany) object
    if (initialCompany) {
      return [
        {
          label: getInitialCompanyLabel(
            initialCompany.name!,
            initialCompany.domain
          ),
          value: {
            ...initialCompany,
          } as ContactCompany,
        },
      ]
    }

    const out =
      data
        ?.filter((c) => {
          if (chainsOnly && c.sub_category_name !== 'Restaurants & Bars') {
            return false
          }

          if (!enableK12Table && c.sub_category_name === 'Education - K-12') {
            return false
          }

          if (!enableCnUTable && c.sub_category_name === 'Education - C&U') {
            return false
          }

          if (
            !enableHospitals &&
            c.sub_category_name === 'Healthcare - Hospitals'
          ) {
            return false
          }
          if (
            !enableNursingHomes &&
            c.sub_category_name === 'Healthcare - Nursing Homes'
          ) {
            return false
          }

          return true
        })
        .map((c) => {
          return {
            label: (
              <div className={'flex flex-col'}>
                <span>
                  <b>{c.name}</b>
                  {c.domain ? ` - ${c.domain}` : ''}
                </span>
                <small>{c.sub_category_name}</small>
              </div>
            ),
            value: {
              id: c.contact_company_id,
              domain: c.domain,
              name: c.name,
              category: c.category,
              sub_category: c.sub_category,
            },
          }
        }) || []

    return out
  }, [data, initialCompany])

  useEffect(() => {
    if (autoSelectFirstOption && companiesOptions.length > 0) {
      const firstOption = companiesOptions[0]
      onChange(firstOption.value)
    }
  }, [companiesOptions, autoSelectFirstOption])

  return (
    <CreatableSelect
      className={className}
      style={{ flex: 1, flexShrink: 0, width: '100%' }}
      height={height}
      name={name}
      control={control}
      type="text"
      label={label}
      onSearchChange={setSearchCompany}
      placeholder={placeholder}
      isLoading={isFetching}
      options={companiesOptions}
      disabled={disabled}
      disableCreate={disableCreate}
      initialSearch={searchCompany}
      menuPosition={menuPosition}
      // Clear search when auto selecting first option
      clearSearchOnSelect={autoSelectFirstOption}
      readOnly={readOnly}
      // Disable filtering as we do it from the API
      disableFiltering
      onCreateOption={(option) =>
        Promise.resolve({
          label: option,
          value: {
            id: -1,
            name: option,
            domain: undefined,
          },
        })
      }
    />
  )
}
