import { Dialog, DialogContent } from 'components/UI/Modal/ModalPrimitive'
import { useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { ProductDetailsStep } from './ProductDetailsStep'
import { ProductModalProvider } from './ProductModalContext'
import { SalesPotentialStep } from './SalesPotentialStep'
import { ProductVariantsStep } from './ProductVariantsStep'

export function ProductModal({
  show,
  handleClose,
  selectedProductID,
  defaultValues,
  productForm,
  espForm,
  onSubmit,
}: {
  show: boolean
  handleClose: () => void
  selectedProductID?: number
  defaultValues: any
  productForm: UseFormReturn<any>
  espForm: UseFormReturn<any>
  onSubmit: () => Promise<void>
}) {
  const [step, setStep] = useState(0)

  // Reset step when modal closes
  useEffect(() => {
    if (!show) {
      setStep(0)
    }
  }, [show])

  // Create a wrapper for handleClose that ensures step is reset
  const handleModalClose = () => {
    setStep(0)
    handleClose()
  }

  return (
    <Dialog open={show} onOpenChange={(open) => !open && handleModalClose()}>
      <DialogContent id={'fb-modal-content'} size="lg" className="px-3">
        <ProductModalProvider
          handleClose={handleModalClose}
          selectedProductID={selectedProductID}
          defaultValues={defaultValues}
          productForm={productForm}
          espForm={espForm}
          onSubmit={onSubmit}
        >
          {step === 0 && <ProductDetailsStep onNext={() => setStep(1)} />}
          {step === 1 && (
            <SalesPotentialStep
              onNext={() => setStep(2)}
              onBack={() => setStep(0)}
            />
          )}
          {step === 2 && <ProductVariantsStep onBack={() => setStep(1)} />}
        </ProductModalProvider>
      </DialogContent>
    </Dialog>
  )
}
