export const Cuisine50Options = [
  { value: 'African', label: 'African' },
  { value: 'American', label: 'American' },
  { value: 'Asian', label: 'Asian' },
  { value: 'Bagel', label: 'Bagel' },
  { value: 'Baked Dessert', label: 'Baked Dessert' },
  { value: 'Bakery', label: 'Bakery' },
  { value: 'Bar', label: 'Bar' },
  { value: 'Barbecue', label: 'Barbecue' },
  { value: 'Bar-Restaurant', label: 'Bar-Restaurant' },
  { value: 'Breakfast / Brunch', label: 'Breakfast / Brunch' },
  { value: 'Buffet', label: 'Buffet' },
  { value: 'Burger', label: 'Burger' },
  { value: 'Cafe', label: 'Cafe' },
  { value: 'Caribbean', label: 'Caribbean' },
  { value: 'Catering', label: 'Catering' },
  { value: 'Chicken', label: 'Chicken' },
  { value: 'Chinese', label: 'Chinese' },
  { value: 'Coffee/Tea', label: 'Coffee/Tea' },
  { value: 'Deli', label: 'Deli' },
  { value: 'Dessert', label: 'Dessert' },
  { value: 'Diner', label: 'Diner' },
  { value: 'Donut', label: 'Donut' },
  { value: 'European', label: 'European' },
  { value: 'Fast Food', label: 'Fast Food' },
  { value: 'French', label: 'French' },
  { value: 'Hawaiian / Poke', label: 'Hawaiian / Poke' },
  { value: 'Health', label: 'Health' },
  { value: 'Ice cream / Yogurt', label: 'Ice cream / Yogurt' },
  { value: 'Indian / South Asian', label: 'Indian / South Asian' },
  { value: 'Italian', label: 'Italian' },
  { value: 'Japanese', label: 'Japanese' },
  { value: 'Juice', label: 'Juice' },
  { value: 'Korean', label: 'Korean' },
  { value: 'Latin', label: 'Latin' },
  { value: 'Mediterranean', label: 'Mediterranean' },
  { value: 'Mexican', label: 'Mexican' },
  { value: 'Middle Eastern', label: 'Middle Eastern' },
  { value: 'Non-Alcoholic Drink', label: 'Non-Alcoholic Drink' },
  { value: 'Pizza', label: 'Pizza' },
  { value: 'Sandwich', label: 'Sandwich' },
  { value: 'Seafood', label: 'Seafood' },
  { value: 'Snack', label: 'Snack' },
  { value: 'Southern / Soul', label: 'Southern / Soul' },
  { value: 'Steakhouse/Meat', label: 'Steakhouse/Meat' },
  { value: 'Sushi', label: 'Sushi' },
  { value: 'Taco / Burrito', label: 'Taco / Burrito' },
  { value: 'Tapas / Small Plates', label: 'Tapas / Small Plates' },
  { value: 'Thai', label: 'Thai' },
  { value: 'Vegan / Vegetarian', label: 'Vegan / Vegetarian' },
  { value: 'Vietnamese', label: 'Vietnamese' },
]

export type Cuisine50 = (typeof Cuisine50Options)[number]['value']
