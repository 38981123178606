import React from 'react'
import { ZipcodeFilter } from 'components/Filters/fields/ZipcodeFilter'
import apiService from 'services/api'
import { US_CITIES } from 'utils/us_cities'
import { US_STATES } from 'utils/us_states'
import { filterStoreRepo } from '../../../Filters/FilterStore'
import FilterModal, {
  FilterGroup,
} from '../../../Filters/components/FilterModal'
import { RangeFilter } from '../../../Filters/fields/RangeFilter'
import { SelectFilter } from '../../../Filters/fields/SelectFilter'
import { FilterIdentifier } from 'models/saved_view'
import { createFilterColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { NURSING_HOME_COLUMNS } from '../nursingHomeColumnsInfo'
import { CommonFiltersetAccountGroup } from 'components/DataExpansionComponents/Common/CommonTable/CommonFiltersetAccountGroup'
import {
  OWNERSHIP_TYPE_OPTIONS,
  PROVIDER_TYPE_OPTIONS,
  AFFILIATED_ENTITY_NAME_OPTIONS,
} from 'models/nursinghome_options'

export function NursingHomesFilterset({
  filterIdentifier,
  hideCampaignsFilter,
}: {
  filterIdentifier: FilterIdentifier
  hideCampaignsFilter?: boolean
}) {
  const api = apiService()
  const { data: campaignOptions } = api.useGetCampaignsOptions(
    'healthcare-nursinghomes'
  )

  const nursingHomesFilterStore = filterStoreRepo.getStore(filterIdentifier)

  const nursingHomeFilterHelper = createFilterColumnHelper({
    columns: NURSING_HOME_COLUMNS,
  })

  return (
    <FilterModal identifier={filterIdentifier} store={nursingHomesFilterStore}>
      <CommonFiltersetAccountGroup
        filterStore={nursingHomesFilterStore}
        campaignOptions={campaignOptions}
        hideCampaignsFilter={hideCampaignsFilter}
      />
      <FilterGroup title="Nursing Home Insights">
        <RangeFilter
          {...nursingHomeFilterHelper.retrieveProps('number_of_certified_beds')}
          store={nursingHomesFilterStore}
        />
        <RangeFilter
          {...nursingHomeFilterHelper.retrieveProps(
            'average_number_of_residents_per_day'
          )}
          store={nursingHomesFilterStore}
        />
        <RangeFilter
          {...nursingHomeFilterHelper.retrieveProps('overall_rating')}
          store={nursingHomesFilterStore}
        />
        <RangeFilter
          {...nursingHomeFilterHelper.retrieveProps('health_inspection_rating')}
          store={nursingHomesFilterStore}
        />
        <RangeFilter
          {...nursingHomeFilterHelper.retrieveProps('qm_rating')}
          store={nursingHomesFilterStore}
        />
        <RangeFilter
          {...nursingHomeFilterHelper.retrieveProps('staffing_rating')}
          store={nursingHomesFilterStore}
        />
        <SelectFilter
          {...nursingHomeFilterHelper.retrieveProps('affiliated_entity_name')}
          filterStore={nursingHomesFilterStore}
          options={AFFILIATED_ENTITY_NAME_OPTIONS}
        />

        <SelectFilter
          {...nursingHomeFilterHelper.retrieveProps('ownership_type')}
          options={OWNERSHIP_TYPE_OPTIONS}
          filterStore={nursingHomesFilterStore}
        />
        <SelectFilter
          {...nursingHomeFilterHelper.retrieveProps('provider_type')}
          options={PROVIDER_TYPE_OPTIONS}
          filterStore={nursingHomesFilterStore}
        />
        <SelectFilter
          {...nursingHomeFilterHelper.retrieveProps(
            'continuing_care_retirement_community'
          )}
          options={[
            { label: 'Yes', value: 'Y' },
            { label: 'No', value: 'N' },
          ]}
          filterStore={nursingHomesFilterStore}
        />
      </FilterGroup>
      <FilterGroup title="Dining Services">
        <RangeFilter
          {...nursingHomeFilterHelper.retrieveProps('projected_meals_per_day')}
          store={nursingHomesFilterStore}
        />
      </FilterGroup>
      <FilterGroup title="Location">
        <SelectFilter
          {...nursingHomeFilterHelper.retrieveProps('city')}
          options={US_CITIES}
          filterStore={nursingHomesFilterStore}
        />
        <SelectFilter
          {...nursingHomeFilterHelper.retrieveProps('state')}
          options={US_STATES}
          filterStore={nursingHomesFilterStore}
        />
        <ZipcodeFilter filterStore={nursingHomesFilterStore} />
      </FilterGroup>
    </FilterModal>
  )
}
