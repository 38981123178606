import { DataTypeColumn } from './commonColumnHelpers'

export const COMMON_COLUMNS = {
  campaigns: {
    header: 'Campaigns',
    group: 'Campaigns and Deals',
  },
  sales_stages: {
    header: 'Sales Stages',
    group: 'Campaigns and Deals',
    tooltipName: 'opps_sales_stages',
  },
  account_owners: {
    header: 'Account Owners',
    group: 'Campaigns and Deals',
    tooltipName: 'opps_account_owners',
  },
  taglist: {
    header: 'Tags',
    group: 'Campaigns and Deals',
    tooltipName: 'opps_tags',
  },
  contact_count: {
    header: 'Contacts',
    group: 'Campaigns and Deals',
    tooltipName: 'opps_contact_count',
  },
  note_count: {
    header: 'Notes',
    group: 'Campaigns and Deals',
    tooltipName: 'opps_note_count',
  },
  deal_count: {
    header: 'Deals',
    group: 'Campaigns and Deals',
    tooltipName: 'opps_deals_count',
  },
  campaign_attributed_deal_count: {
    header: 'Attributed Deals',
    group: 'Campaigns and Deals',
    tooltipName: 'opps_campaign_attributed_deal_count',
  },
  distributors: {
    header: 'Known Distributors',
    group: 'Campaigns and Deals',
    tooltipName: 'opps_distributors',
  },
} satisfies Partial<Record<string, DataTypeColumn>>
