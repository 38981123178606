import { usePreferences } from 'context/preferences/PreferencesContext'
import { ContactCompanyType } from 'models/contact_companies'
import React from 'react'
import { useParams } from 'react-router-dom'
import apiService from 'services/api'
import { getIdWithPrefix } from 'utils/pathUtils'
import CommonPage from 'components/DataExpansionComponents/Common/CommonPage'
import { formatInteger } from 'utils/formatting'

export default function NursingHomePage() {
  const urlParams = useParams()
  const { preferences } = usePreferences()
  const nursingHomeID = urlParams?.nursinghome_id
    ? getIdWithPrefix(
        urlParams?.nursinghome_id,
        preferences?.company_type_prefix_map[ContactCompanyType.NursingHome]
      )
    : undefined
  const api = apiService()
  const { data, isLoading, isError, error } =
    api.useGetNursingHome(nursingHomeID)

  return (
    <CommonPage
      uniqueCompanyTypeId={nursingHomeID}
      useGetCompany={{
        companyData: data,
        isLoading,
        isError,
        error,
      }}
      companyTypeSlug="healthcare-nursinghomes"
      breadcrumbs={[
        {
          title: 'Nursing Home',
          breadcrumb: data?.nursinghome?.provider_name,
        },
      ]}
      getFieldsFromData={(data) => ({
        contactCompanyId: data?.id ?? 0,
        companyName: data?.nursinghome?.provider_name ?? '',
        websiteAddress: data?.nursinghome?.website_url ?? '',
        address: {
          addressUrl: data?.nursinghome?.google_place_url ?? undefined,
          addressInfo: {
            fullAddress: data?.nursinghome?.address ?? '',
            city: data?.nursinghome?.city ?? '',
            state: data?.nursinghome?.state ?? '',
            zipcode: data?.nursinghome?.zip_code ?? '',
          },
          fullAddressInfo: data?.nursinghome?.address_info ?? undefined,
        },
      })}
      customBottomLeftItems={[
        {
          header: 'Telephone Number',
          text: data?.nursinghome?.telephone_number || 'N/A',
        },
        {
          header: 'Certified Beds',
          text:
            data?.nursinghome?.number_of_certified_beds != null
              ? formatInteger(data.nursinghome.number_of_certified_beds)
              : 'N/A',
        },
        {
          header: 'Avg Residents/Day',
          text:
            data?.nursinghome?.average_number_of_residents_per_day != null
              ? data.nursinghome.average_number_of_residents_per_day.toString()
              : 'N/A',
        },
        {
          header: 'Overall Rating',
          text:
            data?.nursinghome?.overall_rating != null
              ? data.nursinghome.overall_rating.toString()
              : 'N/A',
        },
        {
          header: 'QM Rating',
          text:
            data?.nursinghome?.qm_rating != null
              ? data.nursinghome.qm_rating.toString()
              : 'N/A',
        },
        {
          header:
            '\u2002\u2002\u2002\u2002\u2002\u2002\u2002\u2002\u2002\u2002\u2002\u2002\u2002\u2002\u2002\u2002\u2002\u2002', // hacky way to get two rows using invisible spaces
          text: '',
        },
        {
          header: 'Ownership Type',
          text: data?.nursinghome?.ownership_type || 'N/A',
        },
        {
          header: 'Provider Type',
          text: data?.nursinghome?.provider_type || 'N/A',
        },
        {
          header: 'Affiliated Entity Name',
          text: data?.nursinghome?.affiliated_entity_name || 'N/A',
        },
      ]}
    />
  )
}
