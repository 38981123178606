import { Badge } from 'react-bootstrap'
import { RebateOfferType } from './tradespend_api'

const OFFER_TYPE_LABELS: Record<RebateOfferType, string> = {
  [RebateOfferType.FIRST_OFFER]: 'First Offer',
  [RebateOfferType.TIME_OFFER]: 'Time Offer',
}

interface RebateOfferTypePillProps {
  type: RebateOfferType
}

export function RebateOfferTypePill({ type }: RebateOfferTypePillProps) {
  const label = OFFER_TYPE_LABELS[type]
  const bg = type === RebateOfferType.FIRST_OFFER ? 'primary' : 'info'

  return <Badge bg={bg}>{label}</Badge>
}
