import { Deal } from 'models/deals'
import { DataTypeColumn } from '../Common/commonColumnHelpers'

type CustomFields =
  | 'company'
  | 'category'
  | 'sub_category'
  | 'distributors'
  | 'note_count'
  | 'contact_count'
  | 'days_since_last_modified'
  | 'taglist'

export const DEAL_COLUMNS = {
  company: {
    header: 'Company',
    group: 'Company Attributes',
  },
  product: {
    header: 'Product',
    group: 'Campaigns and Deals',
  },
  company_size: {
    header: 'Size',
    group: 'Company Attributes',
    tooltipName: 'deals_chain_size',
  },
  category: {
    header: 'Category',
    group: 'Company Attributes',
  },
  sub_category: {
    header: 'Type',
    group: 'Company Attributes',
  },
  sales_stage: {
    header: 'Sales Stage',
    group: 'Campaigns and Deals',
    tooltipName: 'deals_sales_stage',
  },
  last_datetime_stage_changed: {
    header: 'Days In Stage',
    group: 'Campaigns and Deals',
    tooltipName: 'deals_last_datetime_stage_changed',
  },
  expected_days_to_close: {
    header: 'Expected Close Date',
    group: 'Campaigns and Deals',
    tooltipName: 'deals_expected_days_to_close',
  },
  close_date_override: {
    header: 'Close Date (override)',
    group: 'Campaigns and Deals',
    tooltipName: 'deals_close_date_override',
  },
  account_owner: {
    header: 'Account Owner',
    group: 'Campaigns and Deals',
    tooltipName: 'deals_account_owner',
  },
  origin_campaign: {
    header: 'Origin Campaign',
    group: 'Campaigns and Deals',
  },
  predicted_annual_total: {
    header: '1yr Total Value',
    group: 'Forecast',
    tooltipName: 'deals_predicted_total',
  },
  predicted_annual_brand_opp: {
    header: '1yr Brand Value',
    group: 'Forecast',
    tooltipName: 'predicted_annual_brand_opp',
  },
  annual_revenue_opportunity: {
    header: 'Annual Revenue Opportunity',
    group: 'Forecast',
    tooltipName: 'deals_annual_revenue_opportunity',
  },
  predicted_annual_revenue: {
    header: '1yr Revenue Value',
    group: 'Forecast',
    tooltipName: 'deals_predicted_annual_revenue',
  },
  predicted_monthly_revenue: {
    header: 'Monthly Revenue',
    group: 'Forecast',
    tooltipName: 'deals_predicted_monthly_revenue',
  },
  monthly_revenue_override: {
    header: 'Monthly Revenue (override)',
    group: 'Forecast',
    tooltipName: 'deals_monthly_revenue_override',
  },
  predicted_annual_volume: {
    header: '1yr Volume',
    group: 'Forecast',
    tooltipName: 'deals_predicted_annual_volume',
  },
  predicted_monthly_volume: {
    header: 'Monthly Volume',
    group: 'Forecast',
    tooltipName: 'deals_predicted_monthly_volume',
  },
  monthly_volume_override: {
    header: 'Monthly Volume (override)',
    group: 'Forecast',
    tooltipName: 'deals_monthly_volume_override',
  },
  taglist: {
    header: 'Tags',
    group: 'Company Attributes',
    tooltipName: 'deals_contact_company_tags',
  },
  contact_tags: {
    header: 'Contact Labels',
    group: 'Company Attributes',
    tooltipName: 'deals_contact_tags',
  },
  distributors: {
    header: 'Known Distributors',
    group: 'Company Attributes',
    tooltipName: 'deals_distributors',
  },
  note_count: {
    header: 'Note Count',
    group: 'Company Attributes',
    tooltipName: 'deals_note_count',
  },
  contact_count: {
    header: 'Contact Count',
    group: 'Company Attributes',
    tooltipName: 'deals_contact_count',
  },
  days_since_last_modified: {
    header: 'Days Since Last Update',
    group: 'Company Attributes',
    tooltipName: 'dealspipeline_days_since_last_modified',
  },
  estimated_meals_per_day: {
    header: 'Estimated Meals Per Day',
    group: 'Company Attributes',
    tooltipName: 'deals_estimated_meals_per_day',
  },
  operational_schools: {
    header: 'School Count',
    group: 'Company Attributes',
    tooltipName: 'deals_operational_schools',
  },
  days_since_sent: {
    header: 'Days Since Sent',
    group: 'Company Attributes',
    tooltipName: 'deals_days_since_sent',
  },
  days_since_reply: {
    header: 'Days Since Reply',
    group: 'Company Attributes',
    tooltipName: 'deals_days_since_reply',
  },
  modified: {
    header: 'Last Modified',
    group: 'Campaigns and Deals',
    tooltipName: 'deals_modified',
  },
  created: {
    header: 'Date Created',
    group: 'Campaigns and Deals',
    tooltipName: 'deals_created',
  },
} satisfies Partial<Record<keyof Deal | CustomFields, DataTypeColumn>>
