import { PageWithTitle } from 'utils/PageWithTitle'
import { AuthenticatedNav, UnauthenticatedNav } from 'components/Layout/Navbar'
import { useAuth } from 'context/authentication/useAuth'
import styled from 'styled-components/macro'
import Footer from 'components/Layout/Footer/Footer'
import OopsCard from 'components/NotFoundCard/OopsCard'

export default function Oops() {
  const { isAuthenticated } = useAuth()

  const NavBar = isAuthenticated ? AuthenticatedNav : UnauthenticatedNav

  return (
    <PageWithTitle title="Oops">
      <LayoutRoot>
        <NavBar />
        <Content>
          <OopsCard />
        </Content>
        <Footer />
      </LayoutRoot>
    </PageWithTitle>
  )
}

const LayoutRoot = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  width: 100%;
`

const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`
