import FbButton from 'components/FbUI/FbButton'
import * as S from './styles'
import apiService from 'services/api'

export function Billing() {
  const api = apiService()
  async function openStripePortal() {
    const url = await api.getStripePortalRedirect(window.location.href)
    window.location.href = url
  }

  return (
    <div>
      <S.SettingsTableHeader>
        <S.TableDescriptionText>
          Manage your billing information and subscription details here.
        </S.TableDescriptionText>
        <FbButton onClick={() => openStripePortal()}>Manage Billing</FbButton>
      </S.SettingsTableHeader>
    </div>
  )
}
