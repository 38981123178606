import FilterModal, {
  FilterGroup,
} from 'components/Filters/components/FilterModal'
import { DateRangeFilter } from 'components/Filters/fields/DateRangeFilter'
import { SelectFilter } from 'components/Filters/fields/SelectFilter'
import { FilterIdentifier } from 'models/saved_view'
import { filterStoreRepo } from 'components/Filters/FilterStore'
import { createFilterColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { REBATE_CLAIMS_COLUMNS } from './rebateClaimsColumnInfo'
import { RangeFilter } from 'components/Filters/fields/RangeFilter'
import apiService from 'services/api'

export function RebateClaimsFilterset(props: {
  filterIdentifier: FilterIdentifier
}) {
  const api = apiService()
  const claimsFilterStore = filterStoreRepo.getStore(props.filterIdentifier)
  const { data: productsOptions } = api.useGetProductsOptions()
  const { data: offersData } = api.useGetRebateOfferOptions()

  const claimsFilterHelper = createFilterColumnHelper({
    columns: REBATE_CLAIMS_COLUMNS,
  })

  // Transform offers data into options format
  const offersOptions =
    offersData?.map((offer: { id: number; name: string }) => ({
      value: offer.id.toString(),
      label: offer.name,
    })) || []

  return (
    <FilterModal store={claimsFilterStore} identifier={props.filterIdentifier}>
      <FilterGroup title="Claim Details">
        <SelectFilter
          {...claimsFilterHelper.retrieveProps('offer')}
          options={offersOptions}
          filterStore={claimsFilterStore}
        />
        <SelectFilter
          {...claimsFilterHelper.retrieveProps('status')}
          options={[
            { label: 'Claim Submitted', value: 'CLAIM_SUBMITTED' },
            { label: 'Claim Validated', value: 'CLAIM_VALIDATED' },
            { label: 'Pending Payment', value: 'PENDING_MANUFACTURER_PAYMENT' },
            {
              label: 'Operator Payment Submitted',
              value: 'OPERATOR_PAYMENT_SUBMITTED',
            },
            { label: 'Paid', value: 'PAID' },
            { label: 'Rejected', value: 'CLAIM_REJECTED' },
          ]}
          filterStore={claimsFilterStore}
        />
        <SelectFilter
          {...claimsFilterHelper.retrieveProps('products')}
          filterStore={claimsFilterStore}
          options={productsOptions as any}
        />
        <RangeFilter
          {...claimsFilterHelper.retrieveProps('payout_amount')}
          store={claimsFilterStore}
        />
      </FilterGroup>

      <FilterGroup title="Dates">
        <DateRangeFilter
          {...claimsFilterHelper.retrieveProps('created')}
          filterStore={claimsFilterStore}
        />
      </FilterGroup>
    </FilterModal>
  )
}
