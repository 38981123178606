import { NursingHome } from 'models/nursinghome'
import { DataTypeColumn } from '../Common/commonColumnHelpers'

export const NURSING_HOME_COLUMNS = {
  provider_name: {
    header: 'Nursing Home Name',
    group: 'Opportunity Attributes',
  },
  address: {
    header: 'Address',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_full_address',
  },
  city: {
    header: 'City',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_city',
  },
  state: {
    header: 'State',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_state',
  },
  zip_code: {
    header: 'ZIP Code',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_zipcode',
  },
  google_place_url: {
    header: 'Place URL',
    group: 'Opportunity Attributes',
    tooltipName: 'google_place_url',
  },
  projected_meals_per_day: {
    header: 'Est. Meals Per Day',
    group: 'Dining Services',
    tooltipName: 'estimated_meals_per_day',
  },
  number_of_certified_beds: {
    header: 'Certified Beds',
    group: 'Nursing Home Insights',
    tooltipName: 'number_of_certified_beds',
  },
  average_number_of_residents_per_day: {
    header: 'Avg Residents/Day',
    group: 'Nursing Home Insights',
    tooltipName: 'average_residents_per_day',
  },
  overall_rating: {
    header: 'Overall Rating',
    group: 'Nursing Home Insights',
    tooltipName: 'overall_rating',
  },
  health_inspection_rating: {
    header: 'Health Inspection Rating',
    group: 'Nursing Home Insights',
    tooltipName: 'health_inspection_rating',
  },
  qm_rating: {
    header: 'Quality Measure Rating',
    group: 'Nursing Home Insights',
    tooltipName: 'qm_rating',
  },
  staffing_rating: {
    header: 'Staffing Rating',
    group: 'Nursing Home Insights',
    tooltipName: 'staffing_rating',
  },
  domain: {
    header: 'Domain',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_domain',
  },
  website_url: {
    header: 'Website',
    group: 'Opportunity Attributes',
    tooltipName: 'website_url',
  },
  affiliated_entity_name: {
    header: 'Affiliated Entity',
    group: 'Nursing Home Insights',
    tooltipName: 'affiliated_entity_name',
  },
  ownership_type: {
    header: 'Ownership Type',
    tooltipName: 'ownership_type',
    group: 'Nursing Home Insights',
  },
  provider_type: {
    header: 'Provider Type',
    tooltipName: 'provider_type',
    group: 'Nursing Home Insights',
  },
  continuing_care_retirement_community: {
    header: 'Retirement Community',
    tooltipName: 'retirement_community',
    group: 'Nursing Home Insights',
  },
} satisfies Partial<Record<keyof NursingHome, DataTypeColumn>>
