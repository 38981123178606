import { activityV2FilterStore } from 'components/Filters/FilterStore'
import FilterModal, {
  FilterGroup,
} from 'components/Filters/components/FilterModal'
import { DateRangeFilter } from 'components/Filters/fields/DateRangeFilter'
import { SelectFilter } from 'components/Filters/fields/SelectFilter'
import apiService from 'services/api'

import { FilterIdentifier } from 'models/saved_view'
import { createFilterColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { ACTIVITY_V2_COLUMNS } from 'components/DataExpansionComponents/Activity/activityV2ColumnInfo'

const IDENTIFIER: FilterIdentifier = 'ACTIVITY_TABLE_V2'

export default function ActivityV2Filterset() {
  const api = apiService()
  const { data: userOptions } = api.useGetUserOptions({ onlyActive: true })

  const activityFilterHelper = createFilterColumnHelper({
    columns: ACTIVITY_V2_COLUMNS,
  })

  return (
    <FilterModal identifier={IDENTIFIER} store={activityV2FilterStore}>
      <FilterGroup title="Activity">
        <SelectFilter
          {...activityFilterHelper.retrieveProps('user')}
          filterStore={activityV2FilterStore}
          options={userOptions as any}
        />
        <DateRangeFilter
          {...activityFilterHelper.retrieveProps('created_at')}
          filterStore={activityV2FilterStore}
        />
        <SelectFilter
          {...activityFilterHelper.retrieveProps('event_type')}
          filterStore={activityV2FilterStore}
          options={[
            { value: 'contactcompany', label: 'Company' },
            { value: 'contact', label: 'Contact' },
            { value: 'deal', label: 'Deal' },
          ]}
        />
      </FilterGroup>
    </FilterModal>
  )
}
