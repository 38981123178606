import axios from 'axios'
import getCookie from './getCookie'

export const API_HOST = import.meta.env.VITE_API_HOST as string
const API_PATH_V1 = (import.meta.env.VITE_API_PATH_V1 ||
  import.meta.env.VITE_API_PATH ||
  'api/v1') as string
const HOST_URL_V1 = `${API_HOST}${API_PATH_V1}`
const TOKEN_LOCALSTORAGE_KEY = import.meta.env
  .VITE_TOKEN_LOCALSTORAGE_KEY as string

export function getCsrfCookie() {
  return getCookie('csrftoken')
}

export function getToken() {
  return localStorage.getItem(TOKEN_LOCALSTORAGE_KEY)
}

export function setToken(token: string, reload = true) {
  localStorage.setItem(TOKEN_LOCALSTORAGE_KEY, token)
  if (reload) window.location.reload()
}

export function removeToken(reload = true) {
  localStorage.removeItem(TOKEN_LOCALSTORAGE_KEY)
  if (reload) window.location.reload()
}

export function getClient({
  reloadOnAuthError = true,
  baseURL = HOST_URL_V1,
}: {
  reloadOnAuthError?: boolean
  baseURL?: string
} = {}) {
  const newInstance = axios.create()

  newInstance.defaults.baseURL = baseURL

  newInstance.interceptors.request.use((config) => {
    const csrftoken = getCsrfCookie()
    const token = getToken()

    if (config.headers !== undefined) {
      if (token) {
        config.headers.Authorization = `token ${token}`
      }
      if (csrftoken) {
        config.headers['X-CSRFToken'] = csrftoken
      }
      config.headers['Referer-Path'] = window.location.pathname
    }

    return config
  })

  newInstance.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.response?.status === 401 || error.response?.status === 403) {
        removeToken(reloadOnAuthError)
      }
      return Promise.reject(error)
    }
  )

  return newInstance
}

export const clientInstance = getClient({ baseURL: HOST_URL_V1 })
