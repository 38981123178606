import React from 'react'
import { FbLink } from 'components/FbUI/FbLink'
import ErrorCard from './ErrorCard'

const OopsCard: React.FC = () => {
  const supportEmail = 'support@firstbite.io'

  const handleEmailClick = () => {
    window.open(`mailto:${supportEmail}`)
  }

  const emailLink = () => (
    <FbLink to="#" onClick={handleEmailClick}>
      let us know
    </FbLink>
  )

  return (
    <ErrorCard
      title="Oops!"
      description="Something unexpected happened."
      legend={
        <>
          Try refreshing the page, or {emailLink()} and we&apos;ll look into
          this for you.
        </>
      }
    />
  )
}

export default OopsCard
