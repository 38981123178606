import to from 'await-to-js'
import { TextInput } from 'components/FormUtils/TextInput'
import {
  addDays,
  endOfQuarter,
  endOfYear,
  startOfQuarter,
  startOfYear,
  isBefore,
  endOfToday,
} from 'date-fns'
import {
  ShoppingBag,
  Clock,
  Calendar,
  CalendarRange,
  CalendarDays,
} from 'lucide-react'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useForm, useFieldArray } from 'react-hook-form'
import { z } from 'zod'
import FbButton from '../../components/FbUI/FbButton'
import { DateRangePicker } from '../../components/FormUtils/DateRangePicker'
import { RadioButtonSelect } from '../../components/FormUtils/RadioButtonSelect'
import { Modal } from '../../components/UI/Modal/Modal'
import apiService from '../../services/api'
import Select from 'components/FormUtils/Select'
import { toast } from 'react-toastify'
import { queryClient } from '../../services/queryClient'
import { zodResolver } from '@hookform/resolvers/zod'
import { useNavigate } from 'react-router-dom'
import { Checkbox } from '../../components/FormUtils/Checkbox'
import { REBATE_OFFERS_TABLE_KEY } from './RebateOffersTable'

interface PeriodOption {
  label: string
  value: string
  dates: {
    from: Date
    to: Date
  }
  isDisabled: boolean
}

interface PeriodOptions {
  [key: string]: {
    quarters: PeriodOption[]
    halves: PeriodOption[]
  }
}

interface CreateRebateOfferModalProps {
  campaignId?: number
  onSuccess?: () => void
}

const createRebateOfferFormSchema = z.object({
  name: z.string().min(1, 'Name is required'),
  type: z.string().min(1),
  basis: z.string().min(1),
  min: z.number().min(0.01, 'Minimum spend/units must be greater than 0'),
  max: z.number().min(0, 'Maximum must be greater than or equal to 0'),
  amount_off: z.number().min(0.01, 'Amount off must be greater than 0'),
  discount_type: z.string().min(1),
  period_type: z.string(),
  period_value: z.string(),
  period_year: z.string(),
  range: z.object({
    from: z.date(),
    to: z.date(),
  }),
  products: z
    .array(
      z.object({
        product_id: z.number().min(1, 'A product must be selected').nullable(),
        selectedVariants: z
          .record(z.string(), z.boolean())
          .refine(
            (variants) => Object.values(variants).some((selected) => selected),
            'At least one variant must be selected'
          ),
      })
    )
    .min(1, 'At least one product is required'),
})

type CreateRebateOfferForm = z.infer<typeof createRebateOfferFormSchema>

const currentYear = new Date().getFullYear()
const today = endOfToday()

const generatePeriodOptions = (year: number) => {
  const quarters: PeriodOption[] = [
    {
      label: `Q1 ${year}`,
      value: `1-${year}`,
      dates: {
        from: startOfQuarter(new Date(year, 0)),
        to: endOfQuarter(new Date(year, 0)),
      },
      isDisabled: false,
    },
    {
      label: `Q2 ${year}`,
      value: `2-${year}`,
      dates: {
        from: startOfQuarter(new Date(year, 3)),
        to: endOfQuarter(new Date(year, 3)),
      },
      isDisabled: false,
    },
    {
      label: `Q3 ${year}`,
      value: `3-${year}`,
      dates: {
        from: startOfQuarter(new Date(year, 6)),
        to: endOfQuarter(new Date(year, 6)),
      },
      isDisabled: false,
    },
    {
      label: `Q4 ${year}`,
      value: `4-${year}`,
      dates: {
        from: startOfQuarter(new Date(year, 9)),
        to: endOfQuarter(new Date(year, 9)),
      },
      isDisabled: false,
    },
  ].map((quarter) => ({
    ...quarter,
    isDisabled: isBefore(quarter.dates.to, today),
  }))

  const halves: PeriodOption[] = [
    {
      label: `H1 ${year}`,
      value: `1-${year}`,
      dates: {
        from: startOfYear(new Date(year, 0)),
        to: endOfQuarter(new Date(year, 5)),
      },
      isDisabled: false,
    },
    {
      label: `H2 ${year}`,
      value: `2-${year}`,
      dates: {
        from: startOfQuarter(new Date(year, 6)),
        to: endOfYear(new Date(year, 0)),
      },
      isDisabled: false,
    },
  ].map((half) => ({
    ...half,
    isDisabled: isBefore(half.dates.to, today),
  }))

  return { quarters, halves }
}

export function CreateRebateOfferModal({
  campaignId,
  onSuccess,
}: CreateRebateOfferModalProps) {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const api = apiService()
  const { data: products, isLoading: isLoadingProducts } = api.useProducts()

  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
    reset,
    watch,
    setValue,
  } = useForm<CreateRebateOfferForm>({
    resolver: zodResolver(createRebateOfferFormSchema),
    mode: 'onChange',
    defaultValues: {
      name: '',
      type: 'first-time-offer',
      basis: 'volume',
      period_type: 'custom',
      period_value: '',
      period_year: currentYear.toString(),
      range: {
        from: new Date(),
        to: addDays(new Date(), 20),
      },
      min: 0,
      max: 0,
      amount_off: 0,
      discount_type: 'percentage_off',
      products: [
        {
          product_id: null,
          selectedVariants: {},
        },
      ],
    },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'products',
  })

  const periodType = watch('period_type')
  const periodValue = watch('period_value')
  const periodYear = watch('period_year')

  const periodOptions = useMemo<PeriodOptions>(
    () => ({
      [currentYear.toString()]: generatePeriodOptions(currentYear),
      [(currentYear + 1).toString()]: generatePeriodOptions(currentYear + 1),
    }),
    []
  )

  useEffect(() => {
    if (periodType !== 'custom' && periodValue && periodYear) {
      const options =
        periodType === 'quarter'
          ? periodOptions[periodYear]?.quarters
          : periodOptions[periodYear]?.halves
      const selectedPeriod = options?.find((opt) => opt.value === periodValue)
      if (selectedPeriod && !selectedPeriod.isDisabled) {
        setValue('range', {
          from: selectedPeriod.dates.from,
          to: selectedPeriod.dates.to,
        })
      }
    }
  }, [periodType, periodValue, periodYear, setValue, periodOptions])

  useEffect(() => {
    if (periodType !== 'custom' && periodYear) {
      const options =
        periodType === 'quarter'
          ? periodOptions[periodYear]?.quarters
          : periodOptions[periodYear]?.halves

      if (!options) return

      const currentSelection = options.find((opt) => opt.value === periodValue)
      if (!currentSelection || currentSelection.isDisabled) {
        const firstAvailable = options.find((opt) => !opt.isDisabled)
        if (firstAvailable) {
          setValue('period_value', firstAvailable.value)
          setValue('range', {
            from: firstAvailable.dates.from,
            to: firstAvailable.dates.to,
          })
        }
      }
    }
  }, [periodYear, periodType, periodValue, periodOptions, setValue])

  useEffect(() => {
    // When basis changes, update discount_type
    const basis = watch('basis')
    setValue(
      'discount_type',
      basis === 'volume' ? 'dollars_off' : 'percentage_off'
    )
  }, [watch('basis'), setValue])

  // Replace the variant initialization effect
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      // Check if the change is a product selection
      if (name && name.endsWith('.product_id')) {
        const index = parseInt(name.split('.')[1])
        const productId = value.products?.[index]?.product_id

        if (productId && products) {
          const selectedProduct = products.find((p) => p.id === productId)

          if (selectedProduct?.variants?.length) {
            // Get current variants state
            const currentVariants =
              value.products?.[index]?.selectedVariants || {}

            // Only set if variants aren't already set
            if (!Object.keys(currentVariants).length) {
              const initialVariants = selectedProduct.variants.reduce<
                Record<string, boolean>
              >((acc, v) => {
                if (v.id) {
                  acc[v.id.toString()] = true
                }
                return acc
              }, {})

              // Set variants but only validate this specific field
              setValue(`products.${index}.selectedVariants`, initialVariants, {
                shouldValidate: true,
                shouldDirty: false,
                shouldTouch: false,
              })
            }
          }
        }
      }
    })

    return () => subscription.unsubscribe()
  }, [products, setValue, watch])

  const onSubmit = handleSubmit(async (values) => {
    const productsWithVariants = values.products
      .map((product) => {
        const selectedProduct = products?.find(
          (p) => p.id === product.product_id
        )
        if (!selectedProduct) return null

        const selectedVariantIds = Object.entries(product.selectedVariants)
          .filter(([, isSelected]) => isSelected)
          .map(([id]) => parseInt(id))

        const activeVariants = selectedProduct.variants?.filter(
          (v) => v.id && selectedVariantIds.includes(v.id)
        )

        if (!activeVariants?.length) return null

        return {
          product_id: selectedProduct.id,
          product_name: selectedProduct.name,
          product_desc: '',
          product_variants: activeVariants.map((v) => ({
            variant_id: v.id,
            variant_name: v.name,
            variant_format: v.format_pack_size,
            variant_gtin: v.gtin,
            variant_sku: v.sku,
            variant_desc: v.description,
          })),
        }
      })
      .filter(Boolean)

    if (!productsWithVariants.length) {
      toast.error('At least one product with variants must be selected')
      return
    }

    setIsSubmitting(true)
    const [err, response] = await to(
      api.createRebateOffer({
        name: values.name,
        valid_from: values.range.from,
        valid_to: values.range.to,
        campaign_id: campaignId,
        terms: JSON.stringify({
          type: values.type,
          basis: values.basis,
          min: values.min,
          max: values.max,
          amount_off: values.amount_off,
          discount_type: values.discount_type,
          period_type: values.period_type,
          period_value: values.period_value,
          period_year: values.period_year,
          products: productsWithVariants,
        }),
      })
    )
    setIsSubmitting(false)

    if (err) {
      console.error(err)
      toast.error('Failed to create rebate offer')
      return
    }

    const offerId = (response as any)?.id
    if (!offerId) {
      toast.error('Failed to get offer ID')
      return
    }

    toast.success('Rebate offer created successfully')
    queryClient.invalidateQueries({
      queryKey: [REBATE_OFFERS_TABLE_KEY],
      exact: false,
    })
    reset()
    setOpen(false)
    onSuccess?.()
    navigate(`/rebates/offer/${offerId}/details`)
  })

  // Update preview text to show all selected products
  const selectedProducts = watch('products')
    .map((p) => products?.find((prod) => prod.id === p.product_id)?.name)
    .filter((name): name is string => Boolean(name))
    .join(', ')

  const basis = watch('basis')
  const min = watch('min')
  const max = watch('max')
  const amountOff = watch('amount_off')

  const previewText =
    basis === 'volume'
      ? `Get at least ${min} cases${max ? ` and up to ${max} cases` : ''} of ${selectedProducts || 'selected products'} and get $${amountOff} back per case`
      : `For every $${min} spent${max ? ` (up to $${max})` : ''} on ${selectedProducts || 'selected products'} get ${amountOff}% back on the order`

  return (
    <Fragment>
      <FbButton onClick={() => setOpen(true)}>Create Rebate Offer</FbButton>
      <Modal
        size="lg"
        open={open}
        onOpenChange={(open) => {
          if (!open) {
            reset()
            setOpen(open)
          }
        }}
        loading={isSubmitting || isLoadingProducts}
        blockAccept={!isValid}
        title="Create Rebate Offer"
        onAccept={onSubmit}
        acceptButtonText="Create Offer"
      >
        <TextInput
          control={control}
          name="name"
          label="Name of the offer"
          helperText={errors.name?.message}
        />

        <div className="space-y-4 mt-4">
          {fields.map((field, index) => (
            <div
              key={field.id}
              className="border border-gray-200 rounded-lg p-4"
            >
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-sm font-small">Product {index + 1}</h3>
                {index > 0 && (
                  <button
                    type="button"
                    onClick={() => remove(index)}
                    className="text-sm text-red-600 hover:text-red-800"
                  >
                    Remove Product
                  </button>
                )}
              </div>

              <Select
                control={control}
                name={`products.${index}.product_id`}
                label="Select Product"
                options={products?.map((p) => ({
                  label: p.name,
                  value: p.id,
                }))}
                isMulti={false}
                isLoading={isLoadingProducts}
                valueWhenCleared={null}
              />

              {(() => {
                const productId = watch(`products.${index}.product_id`)
                const selectedProduct = products?.find(
                  (p) => p.id === productId
                )

                if (!selectedProduct) return null

                return (
                  <div className="mt-4">
                    {selectedProduct.variants?.length > 0 ? (
                      <>
                        <h3 className="text-md font-medium mb-2">
                          Select Product Variants
                        </h3>
                        <div className="space-y-2">
                          {selectedProduct.variants.map(
                            (variant) =>
                              variant.id && (
                                <div key={variant.id}>
                                  <Checkbox
                                    control={control}
                                    name={`products.${index}.selectedVariants.${variant.id}`}
                                    disabled={
                                      watch(
                                        `products.${index}.selectedVariants.${variant.id}`
                                      ) &&
                                      Object.values(
                                        watch(
                                          `products.${index}.selectedVariants`
                                        ) ?? {}
                                      ).filter(Boolean).length <= 1
                                    }
                                  >
                                    {variant.name}
                                    {variant.format_pack_size && (
                                      <span className="ml-2 text-gray-500">
                                        ({variant.format_pack_size})
                                      </span>
                                    )}
                                  </Checkbox>
                                </div>
                              )
                          )}
                        </div>
                      </>
                    ) : (
                      <div className="p-4 bg-yellow-50 rounded-md">
                        <p className="text-yellow-800">
                          This product has no variants. Please add variants in
                          the product settings before creating a rebate offer.
                        </p>
                        <FbButton
                          onClick={() => navigate('/settings/products')}
                          className="mt-2"
                        >
                          Go to Product Settings
                        </FbButton>
                      </div>
                    )}
                  </div>
                )
              })()}
            </div>
          ))}

          <div className="flex justify-center">
            <FbButton
              type="button"
              onClick={() => append({ product_id: null, selectedVariants: {} })}
              variant="secondary"
            >
              Add Another Product
            </FbButton>
          </div>
        </div>

        <RadioButtonSelect
          control={control}
          name="type"
          label="Rebate Type"
          options={[
            {
              label: 'First Time Offer',
              key: 'first-time-offer',
              icon: ShoppingBag,
            },
            {
              label: 'Time Based Offer',
              key: 'time-offer',
              icon: Clock,
            },
          ]}
        />

        <div className="space-y-4">
          {/* Period Selection Row */}
          <div>
            <RadioButtonSelect
              control={control}
              name="period_type"
              label="Period Type"
              options={[
                {
                  label: 'Quarter',
                  key: 'quarter',
                  icon: Calendar,
                },
                {
                  label: 'Half',
                  key: 'half',
                  icon: CalendarRange,
                },
                {
                  label: 'Custom Range',
                  key: 'custom',
                  icon: CalendarDays,
                },
              ]}
            />
          </div>

          {periodType !== 'custom' && (
            <div className="flex gap-4">
              <Select
                control={control}
                name="period_year"
                label="Select Year"
                options={[
                  {
                    label: currentYear.toString(),
                    value: currentYear.toString(),
                  },
                  {
                    label: (currentYear + 1).toString(),
                    value: (currentYear + 1).toString(),
                  },
                ]}
              />
              <Select
                control={control}
                name="period_value"
                label={`Select ${periodType === 'quarter' ? 'Quarter' : 'Half'}`}
                options={
                  periodType === 'quarter'
                    ? periodOptions[periodYear]?.quarters
                    : periodOptions[periodYear]?.halves
                }
              />
            </div>
          )}

          {periodType === 'custom' && (
            <DateRangePicker label="Period" control={control} name="range" />
          )}

          {/* Minimum and Maximum Row */}
          <div className="flex gap-4">
            <div className="flex-1">
              <TextInput
                type="number"
                control={control}
                name="min"
                label={basis === 'volume' ? 'Minimum Cases' : 'Minimum Spend'}
                prefix={basis === 'volume' ? undefined : '$'}
                helperText={errors.min?.message}
              />
            </div>
            <div className="flex-1">
              <TextInput
                type="number"
                control={control}
                name="max"
                label={basis === 'volume' ? 'Maximum Cases' : 'Maximum Spend'}
                prefix={basis === 'volume' ? undefined : '$'}
                helperText={errors.max?.message}
              />
            </div>
          </div>

          {/* Amount Off Row */}
          <div className="flex-1">
            <TextInput
              type="number"
              control={control}
              name="amount_off"
              label={
                basis === 'volume'
                  ? 'Amount Off per Case'
                  : 'Percentage Off Order'
              }
              prefix={basis === 'volume' ? '$' : undefined}
              suffix={basis === 'volume' ? undefined : '%'}
              helperText={errors.amount_off?.message}
            />
          </div>
        </div>

        <div className="mt-4 p-4 bg-gray-50 rounded-md">
          <h3 className="text-lg font-medium mb-2">Offer Preview</h3>
          <p>{previewText}</p>
        </div>
      </Modal>
    </Fragment>
  )
}
