import { ProductFormValues } from 'pages/app/settings/ProductTable'
import { createContext, ReactNode, useContext } from 'react'
import { UseFormReturn } from 'react-hook-form'

interface ProductModalContextState {
  selectedProductID?: number
  defaultValues: any
}

interface ProductModalContextMethods {
  handleClose: () => void
  productForm: UseFormReturn<ProductFormValues>
  espForm: UseFormReturn<any>
  onSubmit: () => Promise<void>
}

const ProductModalContext = createContext<{
  state: ProductModalContextState
  methods: ProductModalContextMethods
} | null>(null)

export function ProductModalProvider({
  children,
  handleClose,
  selectedProductID,
  defaultValues,
  productForm,
  espForm,
  onSubmit,
}: {
  children: ReactNode
  handleClose: () => void
  selectedProductID?: number
  defaultValues: any
  productForm: UseFormReturn<ProductFormValues>
  espForm: UseFormReturn<any>
  onSubmit: () => Promise<void>
}) {
  return (
    <ProductModalContext.Provider
      value={{
        state: {
          selectedProductID,
          defaultValues,
        },
        methods: {
          handleClose,
          onSubmit,
          productForm,
          espForm,
        },
      }}
    >
      {children}
    </ProductModalContext.Provider>
  )
}

export function useProductModalContext() {
  const context = useContext(ProductModalContext)
  if (!context) {
    throw new Error(
      'useProductModalContext must be used within ProductModalProvider'
    )
  }
  return context
}
