import { DataTypeColumn } from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { RebateClaim } from './tradespend_api'

type CustomFields = 'offer'

export const REBATE_CLAIMS_COLUMNS: Record<
  keyof RebateClaim | CustomFields,
  DataTypeColumn
> = {
  id: {
    header: 'ID',
    tooltipName: 'Claim ID',
    group: 'Claim Details',
  },
  created: {
    header: 'Created',
    tooltipName: 'Created Date',
    group: 'Claim Details',
  },
  company_name: {
    header: 'Company',
    tooltipName: 'Company Name',
    group: 'Claim Details',
  },
  company_domain: {
    header: 'Domain',
    tooltipName: 'Company Domain',
    group: 'Claim Details',
  },
  firstbite_id: {
    header: 'FirstBite ID',
    tooltipName: 'FirstBite ID',
    group: 'Claim Details',
  },
  enrollment_date: {
    header: 'Enrollment Date',
    tooltipName: 'Enrollment Date',
    group: 'Claim Details',
  },
  status: {
    header: 'Status',
    tooltipName: 'Claim Status',
    group: 'Claim Details',
  },
  payout_amount: {
    header: 'Payout Amount',
    tooltipName: 'Payout Amount',
    group: 'Claim Details',
  },
  offer: {
    header: 'Offer',
    tooltipName: 'Rebate Offer',
    group: 'Offer Details',
  },
  offer_id: {
    header: 'Offer ID',
    tooltipName: 'Offer ID',
    group: 'Offer Details',
  },
  offer_name: {
    header: 'Offer Name',
    tooltipName: 'Offer Name',
    group: 'Offer Details',
  },
  products: {
    header: 'Products',
    tooltipName: 'Products',
    group: 'Offer Details',
  },
  rebate_enrollment_id: {
    header: 'Enrollment ID',
    tooltipName: 'Enrollment ID',
    group: 'Claim Details',
  },
  manufacturer_invoice_id: {
    header: 'Invoice ID',
    tooltipName: 'Manufacturer Invoice ID',
    group: 'Claim Details',
  },
  notes: {
    header: 'Notes',
    tooltipName: 'Notes',
    group: 'Claim Details',
  },
}
