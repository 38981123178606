import { bulkEditSingleParams } from 'services/apiTypes'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useDataTableContext } from 'components/DataTable'
import lodash from 'lodash'
import { toast } from 'react-toastify'
import SelectModal, { Item } from './SelectModal'
import confirm from 'components/dialogConfirm'

export type BaseSelectModalProps<T extends { id: string | number }> = {
  items: Item<T>[]
  show: boolean
  handleClose: () => void
  title: string
  subtitle: string | React.JSX.Element
  label: string
  placeholder: string
  tableQueryKey: string[]
  idAccessor: string
  tableObjAccessor: string

  data: any[]

  filterAndSortParams?: Record<string, any>

  apiAction: (params: bulkEditSingleParams) => Promise<any>
}

export default function BaseSelectModal<T extends { id: string | number }>({
  items,
  show,
  handleClose,
  title,
  subtitle,
  label,
  placeholder,
  tableQueryKey,
  idAccessor,
  tableObjAccessor,
  data,
  filterAndSortParams,
  apiAction,
}: BaseSelectModalProps<T>) {
  const queryClient = useQueryClient()

  const {
    state: { rowSelection, isAllRowsSelected, totalRowsInBackend },
  } = useDataTableContext()

  const unselectedRows = data.filter((_, i) => !rowSelection[i])
  const selectedRows = data.filter((_, i) => rowSelection[i])

  const { isPending, mutate } = useMutation({
    mutationFn: async ({
      item,
      excludeIds,
      selectedIds,
    }: {
      item?: T
      excludeIds?: string[]
      selectedIds?: string[]
      affectedRowsIndexes: number[]
    }) => {
      const body: any = {}

      body['ids'] = selectedIds
      body['exclude_ids'] = excludeIds

      // If all rows are selected, we don't need to send the chain_ids or contact_company_ids
      if (isAllRowsSelected) {
        delete body['ids']
      }

      if (
        isAllRowsSelected &&
        totalRowsInBackend &&
        totalRowsInBackend > 1000
      ) {
        const confirmed = await confirm(
          `You are about to update ${totalRowsInBackend} items. This is a large number and may take a while to complete. Are you sure you want to proceed?`,
          'Bulk Update'
        )
        if (!confirmed) return
      }

      return apiAction({
        item_id: item?.id ?? null,
        params: filterAndSortParams,
        ...body,
      })
    },
    onSuccess: (data) => {
      if (data.status === 202) {
        toast.info(
          data.data?.message ??
            'We are processing your request, you will be notified when it is done'
        )
      } else {
        toast.success('Updated successfully')
      }
      handleClose()
    },

    onError: (err, options, context: any) => {
      context()
      toast.error(`Error updating: ${err}`)
    },
    onMutate: ({ item, affectedRowsIndexes }) => {
      const previousData = queryClient.getQueryData(tableQueryKey)

      queryClient.setQueryData(tableQueryKey, (old: any) => {
        const newData = lodash.cloneDeep(old)

        affectedRowsIndexes.forEach((idx) => {
          const row = newData.results?.[idx] || newData?.[idx]
          let rowPointer = row
          tableObjAccessor.split('.').map((key, i, arr) => {
            if (i === arr.length - 1) {
              rowPointer[key] = item?.id ? item.id : item
            } else {
              rowPointer = rowPointer[key]
            }
          })
        })

        return newData
      })

      return () => queryClient.setQueryData(tableQueryKey, previousData)
    },
  })

  const handleMutate = async (item?: T) => {
    const excludeIds = unselectedRows?.map((r) => {
      return idAccessor.split('.').reduce((acc, key) => acc[key], r)
    })
    const selectedIds = selectedRows?.map((r) => {
      return idAccessor.split('.').reduce((acc, key) => acc[key], r)
    })

    const affectedRowsIndexes = Object.keys(rowSelection).map((key) =>
      parseInt(key)
    )

    return mutate({
      item,
      excludeIds,
      selectedIds,
      affectedRowsIndexes,
    })
  }

  return (
    <SelectModal
      items={items}
      show={show}
      title={title}
      subtitle={subtitle}
      label={label}
      placeholder={placeholder}
      handleClose={handleClose}
      onApply={handleMutate}
      isLoading={isPending}
    />
  )
}
