import apiService from '../../services/api'
import { useMemo, useRef, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Dropdown as FilterDropdown } from '../FormUtils/Dropdown'
import { Spinner } from 'react-bootstrap'
import { Bar } from 'react-chartjs-2'
import { formatInteger, formatUsd } from '../../utils/formatting'
import Toggle from 'react-toggle'
import styled from 'styled-components/macro'
import getStageColor from '../../utils/getStageColor'
import { PipelineResults } from 'models/SalesPipeline'

export function SalesPipelineChart() {
  const api = apiService()
  const [isWeighted, setIsWeighted] = useState(false)

  const { data: stageData = [] } = api.useGetSalesStages()

  const stages = stageData
    ?.sort((a, b) => {
      if (
        a.stage === 'Closed Won' ||
        a.stage === 'Closed Lost' ||
        a.stage === 'Churn'
      ) {
        return 1
      } else {
        return b?.avg_days_to_close - a?.avg_days_to_close
      }
    })

    ?.map((stage: any) => {
      return {
        id: stage.id,
        label: `${stage.stage} (${stage.avg_days_to_close} days to close)`,
        win_rate: stage.win_rate,
        avg_days_to_close: stage.avg_days_to_close,
        stageName: stage.stage,
        color: getStageColor(stage.win_rate)[0],
      }
    })

  const { data: salesStagePipelineData } = useQuery({
    queryKey: ['sales_pipeline'],
    queryFn: () =>
      api.getSalesPipeline({
        by: 'sales_stage',
        weighted: isWeighted,
      }),
  })

  const dataAllProducts = useMemo(() => {
    if (salesStagePipelineData == null) {
      return []
    }

    const data = structuredClone(salesStagePipelineData)

    Object.entries(data).forEach(([key, values]) => {
      const grouped = Object.values(values).reduce(
        (acc: any, curr: any) => {
          for (const k in curr) {
            acc[k] += curr[k]
          }
          return acc
        },
        {
          total_ltv: 0,
          revenue_ltv: 0,
          curr_year_revenue_opp: 0,
          next_year_revenue_opp: 0,
          one_year_revenue_opp: 0,
          three_year_revenue_opp: 0,
          five_year_revenue_opp: 0,
          restaurant_count: 0,
          chain_count: 0,
          pounds_per_year: 0,
        }
      )

      data[key as any] = grouped
    })

    return data as unknown as Record<number, PipelineResults>
  }, [salesStagePipelineData])

  const salesStagePipelineDataRef = useRef(dataAllProducts)
  salesStagePipelineDataRef.current = dataAllProducts

  const viewByOptions = [
    {
      label: 'Lifetime Total Value',
      value: 'total_ltv',
      formatter: formatUsd,
      // tooltip: defaultTooltip,
    },
    {
      label: `${new Date().getFullYear()} Revenue Value`,
      value: 'curr_year_revenue_opp',
      formatter: formatUsd,
      // tooltip: defaultTooltip,
    },
    {
      label: `${new Date().getFullYear() + 1} Revenue Value`,
      value: 'next_year_revenue_opp',
      formatter: formatUsd,
      // tooltip: defaultTooltip,
    },
    {
      label: '1yr Revenue Value',
      value: 'one_year_revenue_opp',
      formatter: formatUsd,
      // tooltip: defaultTooltip,
    },
    {
      label: '3yr Revenue Value',
      value: 'three_year_revenue_opp',
      formatter: formatUsd,
      // tooltip: defaultTooltip,
    },
    {
      label: '5yr Revenue Value',
      value: 'five_year_revenue_opp',
      formatter: formatUsd,
      // tooltip: defaultTooltip,
    },
    {
      label: '1 yr Volume',
      value: 'pounds_per_year',
      formatter: formatInteger,
      // tooltip: avgPoundsPerYerTooltip,
    },
    {
      label: 'Door Count',
      value: 'restaurant_count',
      formatter: formatInteger,
      // tooltip: ltvChainDoorTooltip,
    },
    {
      label: 'Chain Count',
      value: 'chain_count',
      formatter: formatInteger,
      // tooltip: ltvChainDoorTooltip,
    },
  ] as const

  const [viewBy, setViewBy] = useState<(typeof viewByOptions)[number]['value']>(
    'one_year_revenue_opp'
  )

  const selectedViewByOption = useMemo(() => {
    return viewByOptions.find((o) => o.value === viewBy)
  }, [viewBy])

  const theData = stages
    .map((stage) => {
      let weight = 1
      if (isWeighted && !viewBy.includes('count')) {
        weight = stage.win_rate / 100
      }

      let val = null
      if (dataAllProducts?.[stage.id]) {
        val = dataAllProducts?.[stage.id]?.[viewBy] * weight
      }

      return {
        // It's called chain_count for historical reasons.
        deal_count: dataAllProducts?.[stage.id]?.['chain_count'],
        label: stage.label,
        color: stage.color,
        value: val,
      }
    })
    .filter((x) => !!x.value)

  const datasets = [
    {
      id: 1,
      label: '',
      data: theData.map((d) => d.value),
      backgroundColor: theData?.map((o) => o.color),
    },
  ]

  const labels = theData.map((d) => d.label)

  return (
    <div
      style={{
        height: 450,
      }}
    >
      <h2 className="uppercase font-light text-2xl text-gray-700">
        Sales Pipeline
      </h2>

      <hr className="border-gray-400 mb-6 mt-2" />

      <div className="flex w-full justify-end">
        <FilterDropdown
          title="View By"
          options={viewByOptions as any}
          selected={selectedViewByOption}
          onSelect={(option) => {
            if (option && 'value' in option) {
              setViewBy(option.value as any)
            }
          }}
          enableSearch={false}
          enableClear={false}
          customDisplayFunction={(title, label) => (
            <span>
              {title}: <b>{label}</b>
            </span>
          )}
        />
      </div>

      {salesStagePipelineData == null ? (
        <div style={{ height: 300 }}>
          <Spinner />
        </div>
      ) : (
        <Bar
          style={{ height: 300, maxHeight: 300 }}
          data={{
            datasets,
            labels,
          }}
          options={{
            layout: {
              padding: {
                right: 80,
              },
            },
            plugins: {
              datalabels: {
                anchor: 'end',
                align: 'right',
                formatter: selectedViewByOption?.formatter || formatUsd,
                font: {
                  weight: 'bold',
                },
              },
              tooltip: {
                callbacks: {
                  label: (d) => {
                    return `Deal Count: ${theData?.[d.dataIndex]?.deal_count}`
                  },
                },
              },
              legend: {
                display: false,
              },
            },
            indexAxis: 'y',
            scales: {
              y: {
                grid: {
                  display: false,
                },
              },
              x: {
                grid: {
                  display: false,
                },
                ticks: {
                  display: false,
                },
              },
            },
          }}
        />
      )}

      <div style={{ display: 'flex' }}>
        <ToggleRow>
          <span style={{ verticalAlign: 'top', marginLeft: 2 }}>Standard</span>

          <Toggle
            disabled={viewBy.includes('count')}
            defaultChecked={isWeighted}
            onChange={(e) => setIsWeighted(e.target.checked)}
          />
          <span style={{ verticalAlign: 'top', marginLeft: 2 }}>Weighted</span>
        </ToggleRow>
      </div>
    </div>
  )
}

const ToggleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px 0;
  gap: 8px;
  width: 100%;
`
