import { useDataTableContext } from '../../components/DataTable'
import apiService from '../../services/api'
import lodash from 'lodash'
import confirm from '../../components/dialogConfirm'
import { CompanyTypeSlug } from 'models/companies'

interface CampaignUnassignerProps {
  accessorKey: string
  tableQueryKey: string[]
  requestParams: Record<string, any>
  campaignId: number
  data: any[]
  setPagination: (params: { pageIndex: number; pageSize: number }) => void
  totalCount: number
  pageSize?: number
}

export function useCampaignUnassigner(
  companyType: CompanyTypeSlug,
  props: CampaignUnassignerProps
) {
  const api = apiService()
  const pageSize = props.pageSize || 100

  const mutation = api.useUnassignToCampaignMutation(
    companyType,
    props.tableQueryKey,
    props.accessorKey
  )

  const {
    state: { rowSelection, isAllRowsSelected, totalRowsInBackend },
  } = useDataTableContext()

  async function handleSubmit() {
    const selectedRows = props.data?.filter((_, i) => {
      return Boolean(rowSelection?.[i])
    })

    const selectedChainProxyIds = selectedRows?.map((r: any) =>
      lodash.get(r, props.accessorKey)
    ) as number[]

    const numRowsBeingRemoved = isAllRowsSelected
      ? totalRowsInBackend - (props.data.length - selectedChainProxyIds.length)
      : selectedChainProxyIds.length

    const confirmed = await confirm(
      `Confirm unassigning ${isAllRowsSelected ? totalRowsInBackend : selectedChainProxyIds.length} companies from campaign?`,
      'Unassign companies from Campaign'
    )

    if (!confirmed) {
      return
    }

    await mutation.mutateAsync({
      campaign_id: props.campaignId,
      include_ids: isAllRowsSelected ? [] : selectedChainProxyIds,
      exclude_ids: [],
      filter_params: props.requestParams,
    })

    // After mutation completes, calculate pagination updates
    const newTotal = Math.max(0, totalRowsInBackend - numRowsBeingRemoved)
    const lastPossiblePage = Math.max(0, Math.ceil(newTotal / pageSize) - 1)

    // Get current page index
    const currentPageIndex = props.requestParams.page
      ? props.requestParams.page - 1
      : 0

    // Calculate if current page will be empty
    const itemsRemainingOnCurrentPage =
      props.data.length - selectedChainProxyIds.length
    const shouldChangePage =
      itemsRemainingOnCurrentPage === 0 || currentPageIndex > lastPossiblePage

    if (shouldChangePage) {
      // If we're removing all items from current page, go to previous page unless we're on first page
      const targetPage =
        itemsRemainingOnCurrentPage === 0 && currentPageIndex > 0
          ? currentPageIndex - 1
          : lastPossiblePage

      // Update pagination state after mutation is complete
      props.setPagination({
        pageIndex: targetPage,
        pageSize: pageSize,
      })
    }
  }

  return {
    unassign: handleSubmit,
    isUnassigning: mutation.isPending,
  }
}
