import { useMutation, useQuery } from '@tanstack/react-query'

import qs from 'query-string'

import { Campaign } from '../../models/campaign'
import { CompanyTypeSlug } from '../../models/companies'
import { baseAPICall } from '../../services/baseAPICall'
import { queryClient } from '../../services/queryClient'
import { clientInstance } from '../../utils/http-client'

const CAMPAIGNS_LIST_KEY = 'campaigns-list'

/* -------------
List Campaigns
-------------- */
async function getCampaigns(
  signal?: AbortSignal,
  queryParams?: Record<string, any>
) {
  return baseAPICall<Campaign[]>(() =>
    clientInstance.get('contacts/campaigns/', {
      signal,
      params: queryParams,
      paramsSerializer: (p) => {
        return qs.stringify(p)
      },
    })
  )
}

export const useGetCampaigns = (
  signal?: AbortSignal,
  disabled?: boolean,
  queryParams?: Record<string, any>
) =>
  useQuery({
    queryKey: [CAMPAIGNS_LIST_KEY, JSON.stringify(queryParams)],
    queryFn: () => getCampaigns(signal, queryParams),
    refetchOnMount: true,
    staleTime: 1000 * 60 * 5,
    enabled: !disabled,
  })

/* -------------
Get Campaign Options
-------------- */
interface CampaignOption {
  label: string
  value: number | string
  icon?: React.JSX.Element
  companyTypeSlug?: CompanyTypeSlug
}

export const useGetCampaignsOptions = (
  type?: CompanyTypeSlug,
  signal?: AbortSignal,
  withNoneOption = false,
  disabled = false
) =>
  useQuery({
    queryKey: [CAMPAIGNS_LIST_KEY, 'options', type, withNoneOption],
    queryFn: async () => {
      const res = await getCampaigns(signal)
      const campaignOptions: CampaignOption[] = (
        type ? res.filter((it) => it.type.slug === type) : res
      ).map((c) => ({
        label: c.name,
        value: c.id,
        companyTypeSlug: c.type.slug,
        icon: (
          <div
            className={`h-3 w-3 rounded-full mr-2 flex-shrink-0`}
            style={{
              backgroundColor: c.color,
            }}
          />
        ),
      }))

      if (withNoneOption) {
        campaignOptions.unshift({
          label: 'No Campaign',
          value: 'REMOVE',
        })
      }

      return campaignOptions
    },
    refetchOnMount: false,
    enabled: !disabled,
  })

/* -------------
Create Campaign
-------------- */
type CreateCampaignParams = {
  type_slug: CompanyTypeSlug
  product_id?: number
  name: string
  color: string
  description: string
}

async function createCampaign(values: CreateCampaignParams) {
  return baseAPICall<Campaign>(
    () => clientInstance.post('contacts/campaigns/create/', values),
    { successMessage: 'Campaign created successfully' }
  )
}

export const useCreateCampaign = () => {
  return useMutation({
    mutationFn: createCampaign,
    onMutate: async () => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({
        queryKey: [CAMPAIGNS_LIST_KEY],
        exact: false,
      })
    },
    onSuccess: async () => {
      // Always refetch after error or success to ensure consistency
      await queryClient.invalidateQueries({ queryKey: [CAMPAIGNS_LIST_KEY] })
    },
  })
}

/* -------------
Edit Campaign
-------------- */
type EditCampaignParams = Partial<CreateCampaignParams>

async function editCampaign(id: number, values: EditCampaignParams) {
  return baseAPICall<Campaign>(
    () => clientInstance.put(`contacts/campaigns/${id}/edit/`, values),
    { successMessage: 'Campaign updated successfully' }
  )
}

export const useEditCampaign = () => {
  return useMutation({
    mutationFn: ({
      id,
      product_id,
      ...values
    }: EditCampaignParams & {
      id: number
      product?: { id: number; name: string }
      product_id?: number
    }) => editCampaign(id, { ...values, product_id }),

    onMutate: async (newCampaign) => {
      // Get the main campaign list query
      const mainQueryKey = [CAMPAIGNS_LIST_KEY]
      const mainQuery = queryClient.getQueryData(mainQueryKey)

      // Cancel any outgoing refetches to avoid optimistic update being overwritten
      await queryClient.cancelQueries({
        queryKey: mainQueryKey,
        exact: false,
      })

      // Update the campaign in all related queries
      const updateCampaign = (campaign: Campaign) => ({
        ...campaign,
        name: newCampaign.name ?? campaign.name,
        description: newCampaign.description ?? campaign.description,
        color: newCampaign.color ?? campaign.color,
        product: newCampaign.product,
      })

      // Apply updates to all matching queries
      queryClient.setQueriesData(
        { queryKey: mainQueryKey, exact: false },
        (old: Campaign[] | undefined) => {
          if (!old) return old
          return old.map((campaign) =>
            campaign.id === newCampaign.id ? updateCampaign(campaign) : campaign
          )
        }
      )

      // Return the main query data for rollback
      return {
        prev: mainQuery,
        queryKey: mainQueryKey,
      }
    },

    onError: (_, __, context) => {
      // Restore the previous data if there was an error
      if (context?.prev && context.queryKey) {
        queryClient.setQueryData(context.queryKey, context.prev)
      }
    },
  })
}

/* -------------
Delete Campaign
-------------- */
async function deleteCampaign(id: number) {
  return baseAPICall(
    () => clientInstance.delete(`contacts/campaigns/${id}/delete/`),
    {
      successMessage: 'Campaign deleted successfully',
    }
  )
}

export const useDeleteCampaign = () => {
  return useMutation({
    mutationFn: deleteCampaign,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CAMPAIGNS_LIST_KEY] })
    },
  })
}

/* -------------
Campaign Companies
(for contact request modal)
-------------- */
async function getCampaignCompanies(campaignId: number, signal?: AbortSignal) {
  return baseAPICall<CampaignCompaniesResponse>(() =>
    clientInstance.get(`contacts/campaigns/${campaignId}/companies/`, {
      signal,
    })
  )
}

export const useGetCampaignCompanies = (
  campaignId: number,
  signal?: AbortSignal
) =>
  useQuery({
    queryKey: ['campaign', campaignId, 'companies'],
    queryFn: () => getCampaignCompanies(campaignId, signal),
  })

export interface CampaignCompaniesResponse {
  results: Array<{
    id: number
    name: string
  }>
  total_count: number
}
