import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { DataTable, DataTableProvider } from '../../components/DataTable'
import { DataTableContainer } from '../../components/DataTable/UI'
import { IColumnVisibility } from '../../components/DataTable/types'
import { ColumnsStoreProvider } from '../../stores/ColumnsStore/ColumnsStoreProvider'
import { RebateOffer } from './tradespend_api'
import { usePaginationURLParams } from '../../utils/usePaginationURLParams'
import {
  filterStoreRepo,
  useFilterParams,
} from '../../components/Filters/FilterStore'
import { TableSearch } from '../../components/Filters/TableSearch'
import { FilterChips } from '../../components/Filters/components/FilterChips/FilterChips'
import { ColumnSelectorRecipient } from '../../components/Modals/ColumnModal/ColumnModal'
import { RebateOffersFilterset } from './RebateOffersFilterset'
import * as S from '../../components/Tables/CommonTable.styles'
import { FilterIdentifier } from '../../models/saved_view'
import { dataTableSortingStoreRepo } from '../../components/DataTable/DataTableSorting/DataTableSortingStore'
import { getTableQueryKey } from 'utils/getTableQueryKey'
import apiService from 'services/api'
import { useRebateOffersColumns } from './useRebateOffersColumns'

const PAGE_SIZE = 20
const IDENTIFIER: FilterIdentifier = 'REBATES_TABLE'
export const REBATE_OFFERS_TABLE_KEY = 'rebates-table'
type SortableFields = (keyof RebateOffer | string)[]

const sortableFields: SortableFields = [
  'name',
  'state',
  'offer_type',
  'total_enrollments',
  'total_claims',
  'pending_validation_claims',
  'invalid_claims',
  'paid_claims',
  'unpaid_valid_claims',
  'total_validated_units',
  'total_validated_payout',
  'valid_from',
  'valid_to',
]

function RebateOffersTableComponent(props: {
  campaignId?: number
  tableKey: string
}) {
  const api = apiService()
  const navigate = useNavigate()
  const [pagination, setPagination] = usePaginationURLParams(PAGE_SIZE)
  const rebateOffersFilterStore = filterStoreRepo.getStore(IDENTIFIER)
  const { sorting } = dataTableSortingStoreRepo.getStore(props.tableKey)()

  const filtersAsParams = useFilterParams(rebateOffersFilterStore)

  const filterParams = useMemo(() => {
    const params: Record<string, unknown> = { ...filtersAsParams }

    if (props.campaignId) {
      params['campaign_id'] = props.campaignId
    }

    return params
  }, [filtersAsParams, props.campaignId])

  const sortParams = useMemo(() => {
    const params: Record<string, unknown> = {}

    if (sorting?.length) {
      params['sort'] = sorting[0].desc ? '-' + sorting[0]?.id : sorting[0]?.id
    }

    return params
  }, [sorting])

  const filterAndSortParams = useMemo(
    () => ({
      ...filterParams,
      ...sortParams,
    }),
    [filterParams, sortParams]
  )

  const TABLE_QUERY_KEY = getTableQueryKey({
    tableKey: props.tableKey,
    filterParams: filterAndSortParams,
    page: pagination.pageIndex + 1,
  })

  const { data: rebateData, isFetching } = useQuery({
    queryKey: TABLE_QUERY_KEY,
    queryFn: ({ signal }) =>
      api.getRebateOffers(
        {
          ...filterAndSortParams,
          page: pagination.pageIndex + 1,
          limit: PAGE_SIZE,
        },
        signal
      ),
  })

  const columns = useRebateOffersColumns()

  return (
    <DataTableContainer>
      <DataTable
        tableHeader={
          <S.SearchContainer>
            <div className="flex w-full gap-2 items-center">
              <TableSearch
                className={'flex-1'}
                filterStore={rebateOffersFilterStore}
                searchPlaceholder="Search by name"
              />
              <RebateOffersFilterset filterIdentifier={IDENTIFIER} />
              <ColumnSelectorRecipient tableKey={props.tableKey} />
            </div>
            <div className="mt-2">
              <FilterChips
                identifier={IDENTIFIER}
                store={rebateOffersFilterStore}
                showActive
                clearAllButton
              />
            </div>
          </S.SearchContainer>
        }
        loading={isFetching}
        stickyLastColumn={true}
        data={rebateData?.results ?? []}
        columns={columns}
        tableKey={props.tableKey}
        onRowClick={(row) =>
          navigate(`/rebates/offer/${row.original.id}/details`)
        }
        isPaginationEnabled={true}
        sortableFields={sortableFields}
        paginationOptions={{
          pageCount: Math.ceil((rebateData?.count || 0) / PAGE_SIZE),
          pagination,
          setPagination,
          isPaginationLoading: isFetching,
        }}
      />
    </DataTableContainer>
  )
}

export function RebateOffersTable(
  props: { campaignId?: number } & {
    defaultColumnVisibility?: IColumnVisibility<any>
  }
) {
  const { defaultColumnVisibility, ...rest } = props

  return (
    <ColumnsStoreProvider
      tableKey={REBATE_OFFERS_TABLE_KEY}
      defaultColumnVisibility={defaultColumnVisibility}
      preferredGroupOrder={['Offer Details', 'Claims', 'Dates']}
    >
      <DataTableProvider tableKey={REBATE_OFFERS_TABLE_KEY}>
        <RebateOffersTableComponent
          tableKey={REBATE_OFFERS_TABLE_KEY}
          {...rest}
        />
      </DataTableProvider>
    </ColumnsStoreProvider>
  )
}
