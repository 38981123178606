import apiService from 'services/api'
import { formatInteger } from 'utils/formatting'
import { Modal } from '../../UI/Modal/Modal'
import { useDataTableContext } from 'components/DataTable'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { TextInput } from 'components/FormUtils/TextInput'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import lodash from 'lodash'
import confirm from 'components/dialogConfirm'

const overridesSchema = z.object({
  monthly_volume_override: z.union([
    z.number().min(0).optional(),
    z.literal(''),
  ]),
  monthly_revenue_override: z.union([
    z.number().min(0).optional(),
    z.literal(''),
  ]),
  close_date_override: z.string().optional(),
})

type DealsOverride = z.infer<typeof overridesSchema>

export default function AddDealsOverrideModal({
  data,
  filterAndSortParams,
  handleClose,
  idAccessor,
  show,
  tableQueryKey,
}: {
  show: boolean
  handleClose: () => void
  tableQueryKey: string[]
  idAccessor: string

  data: any[]

  filterAndSortParams?: Record<string, any>
}) {
  const queryClient = useQueryClient()
  const api = apiService()

  const {
    state: {
      totalSelectedRows,
      rowSelection,
      isAllRowsSelected,
      totalRowsInBackend,
    },
  } = useDataTableContext()

  const unselectedRows = data.filter((_, i) => !rowSelection[i])
  const selectedRows = data.filter((_, i) => rowSelection[i])

  const { isPending, mutate } = useMutation({
    mutationFn: ({
      overrides,
      excludeIds,
      selectedIds,
    }: {
      overrides?: DealsOverride
      excludeIds?: string[]
      selectedIds?: string[]
      affectedRowsIndexes: number[]
    }) => {
      const body: any = {}

      body['ids'] = selectedIds
      body['exclude_ids'] = excludeIds

      // If all rows are selected, we don't need to send the chain_ids or contact_company_ids
      if (isAllRowsSelected) {
        delete body['ids']
      }

      if (overrides) {
        const onlyFilledOverrides = Object.entries(overrides).reduce(
          (acc: Record<string, unknown>, [key, value]) => {
            if (value) {
              acc[key] = value
            }
            return acc
          },
          {}
        )

        if (
          isAllRowsSelected &&
          totalRowsInBackend &&
          totalRowsInBackend > 1000
        ) {
          confirm(
            `You are about to update ${totalRowsInBackend} deals. This is a large number and may take a while to complete. Are you sure you want to proceed?`,
            'Bulk Update'
          ).then((confirmed) => {
            if (!confirmed) return
            return api.bulkEditDeal({
              deal: { ...onlyFilledOverrides },
              params: filterAndSortParams,
              ...body,
            })
          })
        } else {
          return api.bulkEditDeal({
            deal: { ...onlyFilledOverrides },
            params: filterAndSortParams,
            ...body,
          })
        }
      }

      return Promise.reject('No overrides provided')
    },
    onSuccess: (data) => {
      if (data.status === 202) {
        toast.info(
          data.data?.message ??
            'We are processing your request, you will be notified when it is done'
        )
      } else {
        toast.success('Deals updated successfully')
      }
      handleClose()
    },

    onError: (err, options, context: any) => {
      context()
      toast.error(`Error updating: ${err}`)
    },
    onMutate: ({ overrides, affectedRowsIndexes }) => {
      const previousData = queryClient.getQueryData(tableQueryKey)

      queryClient.setQueryData(tableQueryKey, (old: any) => {
        const newData = lodash.cloneDeep(old)
        affectedRowsIndexes.forEach((idx) => {
          const row = newData.results?.[idx] || newData?.[idx]
          if (row) {
            if (overrides?.monthly_volume_override) {
              row['monthly_volume_override'] = overrides.monthly_volume_override
            }
            if (overrides?.monthly_revenue_override) {
              row['monthly_revenue_override'] =
                overrides.monthly_revenue_override
            }
            if (overrides?.close_date_override) {
              row['close_date_override'] = overrides.close_date_override
            }
          }
        })
        return newData
      })
      return () => queryClient.setQueryData(tableQueryKey, previousData)
    },
  })

  const handleMutate = async (overrides: DealsOverride) => {
    const excludeIds = unselectedRows?.map((r) => {
      return idAccessor.split('.').reduce((acc, key) => acc[key], r)
    })
    const selectedIds = selectedRows?.map((r) => {
      return idAccessor.split('.').reduce((acc, key) => acc[key], r)
    })

    const affectedRowsIndexes = Object.keys(rowSelection).map((key) =>
      parseInt(key)
    )

    return mutate({
      overrides,
      excludeIds,
      selectedIds,
      affectedRowsIndexes,
    })
  }

  const { handleSubmit, control, setValue } = useForm({
    mode: 'onChange',
    resolver: zodResolver(overridesSchema),
  })

  const onSubmit = handleSubmit((data) => {
    handleMutate(data)
  })

  return (
    <Modal
      title="Add Overrides"
      description={
        <span>
          By applying these overrides, the volume and revenue forecasts for all{' '}
          <b>{formatInteger(totalSelectedRows)}</b> deals will be replaced.
          These are applied on the chain level.
        </span>
      }
      onOpenChange={(open) => !open && handleClose?.()}
      open={show}
      onAccept={onSubmit}
      acceptButtonText="Apply"
      onCancel={handleClose}
      cancelButtonText="Cancel"
      loading={isPending}
    >
      <TextInput
        noMargin
        control={control}
        label="Monthly Volume (override)"
        name="monthly_volume_override"
        type="number"
        placeholder="### lbs."
      />
      <TextInput
        noMargin
        control={control}
        label="Monthly Revenue (override)"
        name="monthly_revenue_override"
        type="number"
        placeholder="$"
      />
      <TextInput
        noMargin
        onClear={() => setValue('close_date_override', '')}
        control={control}
        name="close_date_override"
        label="Close Date (override)"
        type="date"
      />
    </Modal>
  )
}
