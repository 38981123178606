import { Bar, Line } from 'react-chartjs-2'
import { InfoCard } from 'components/UI/InfoCard'
import { formatInteger, formatUsd } from 'utils/formatting'
import {
  format,
  startOfQuarter,
  endOfQuarter,
  endOfYear,
  startOfMonth,
  endOfMonth,
} from 'date-fns'
import { useGetRebateDashboardStats } from './tradespend_api'
import { Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { RadioButtonSelect } from 'components/FormUtils/RadioButtonSelect'
import {
  BarChart2,
  LineChart,
  ChartPie,
  DollarSign,
  Users,
  TrendingUp,
  Percent,
} from 'lucide-react'
import { DateRangeSelect } from './DateRangeSelect'
import { RebateClaimsTable } from './RebateClaimsTable'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
)

// Chart color palette
const CHART_COLORS = [
  '#10B981', // emerald/green (primary)
  '#4F46E5', // indigo
  '#F59E0B', // amber
  '#EF4444', // red
  '#8B5CF6', // purple
  '#EC4899', // pink
  '#06B6D4', // cyan
  '#F97316', // orange
  '#14B8A6', // teal
  '#6366F1', // violet
]

interface FormValues {
  showValue: 'claims' | 'amount'
  dateRange: {
    type: 'quarter' | 'half' | 'custom'
    quarter?: string
    half?: string
    year?: string
    dateRange?: {
      from: Date
      to: Date
    }
  }
  showAllClaims: 'all' | 'select'
  chartType: 'stacked' | 'line'
}

interface MonthlyTrendData {
  month: string
  aggregate?: { count: number; value: number }
  [key: string]: string | { count: number; value: number } | undefined
}

export function RebateDashboard() {
  // Get current quarter (1-4)
  const now = new Date()
  const currentQuarter = Math.floor(now.getMonth() / 3) + 1

  const { control, watch, setValue } = useForm<FormValues>({
    defaultValues: {
      showValue: 'claims',
      dateRange: {
        type: 'quarter',
        quarter: currentQuarter.toString(),
        year: now.getFullYear().toString(),
      },
      showAllClaims: 'all',
      chartType: 'stacked',
    },
  })

  const showValue = watch('showValue')
  const dateRange = watch('dateRange')
  const showAllClaims = watch('showAllClaims')
  const chartType = watch('chartType')

  // Get date range based on selection
  const getDateRange = () => {
    const currentYear = parseInt(
      dateRange.year || new Date().getFullYear().toString()
    )

    if (dateRange.type === 'quarter' && dateRange.quarter) {
      const quarter = parseInt(dateRange.quarter)
      const start = startOfQuarter(new Date(currentYear, (quarter - 1) * 3, 1))
      const end = endOfQuarter(start)
      return { start, end }
    }

    if (dateRange.type === 'half' && dateRange.half) {
      const half = parseInt(dateRange.half)
      const start = new Date(currentYear, (half - 1) * 6, 1)
      const end =
        half === 1
          ? new Date(currentYear, 5, 30)
          : endOfYear(new Date(currentYear, 11, 31))
      return { start, end }
    }

    if (
      dateRange.type === 'custom' &&
      dateRange.dateRange?.from &&
      dateRange.dateRange?.to
    ) {
      return {
        start: dateRange.dateRange.from,
        end: dateRange.dateRange.to,
      }
    }

    // Default to current quarter if no valid selection
    const start = startOfQuarter(new Date())
    const end = endOfQuarter(start)
    return { start, end }
  }

  const { start, end } = getDateRange()
  const { data: dashboardStats, isLoading } = useGetRebateDashboardStats(
    format(start, 'yyyy-MM-dd'),
    format(end, 'yyyy-MM-dd')
  )

  if (isLoading || !dashboardStats) {
    return (
      <div className="flex items-center justify-center h-full">
        <Spinner animation="border" />
      </div>
    )
  }

  // Filter data for selected date range
  const filteredData = dashboardStats.monthly_trends.data.filter(
    (d: MonthlyTrendData) => {
      // Parse date in UTC to avoid timezone issues
      const [year, month] = d.month.split('-')
      const date = new Date(parseInt(year), parseInt(month) - 1, 1)
      return date >= startOfMonth(start) && date <= endOfMonth(end)
    }
  )

  // Compute derived values
  const isShowingValue = showValue === 'amount'
  const isShowingAllClaims = showAllClaims === 'all'

  // Filter offers for chart
  const activeOffers = isShowingAllClaims
    ? dashboardStats.monthly_trends.offers
    : dashboardStats.monthly_trends.offers

  const ChartComponent = chartType === 'stacked' ? Bar : Line

  return (
    <div className="w-full px-4 space-y-6 min-h-full">
      <div className="mx-auto">
        {/* Global Controls */}
        <div className="flex justify-between items-center mb-6">
          <DateRangeSelect
            control={control}
            name="dateRange"
            setValue={setValue}
          />
          <div className="relative group">
            <RadioButtonSelect
              control={control}
              name="showValue"
              options={[
                {
                  label: 'Claims',
                  key: 'claims',
                  icon: ChartPie,
                },
                {
                  label: 'Amount',
                  key: 'amount',
                  icon: DollarSign,
                },
              ]}
            />
            <div className="absolute hidden group-hover:block bg-gray-800 text-white text-sm rounded px-2 py-1 right-0 mt-2 whitespace-nowrap z-10">
              {isShowingValue
                ? 'Show data by $ amount'
                : 'Show data by # of claims'}
            </div>
          </div>
        </div>
        {/* Key Metrics */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 w-full mb-4">
          <InfoCard
            title="Foodservice Chains"
            value={formatInteger(dashboardStats.foodservice_chains)}
            tooltip="Total number of unique foodservice chains that have submitted claims"
            icon={Users}
          />
          <InfoCard
            title="Claims Received"
            value={formatInteger(dashboardStats.claims_received)}
            tooltip="Total number of rebate claims received across all offers"
            icon={TrendingUp}
          />
          <InfoCard
            title="Rebate Value Processed"
            value={formatUsd(dashboardStats.rebate_value_processed)}
            tooltip="Total monetary value of all processed rebate claims"
            icon={DollarSign}
          />
          <InfoCard
            title="Redemption Rate"
            value={`${dashboardStats.redemption_rate}%`}
            tooltip="Percentage of enrollments with at least one validated/paid claim"
            icon={Percent}
          />
        </div>

        {/* Claims by Offer */}
        <div className="w-full bg-white p-6 rounded-lg shadow mb-8">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-lg font-semibold">Claims by Rebate Offer</h3>
          </div>
          <Bar
            data={{
              labels: dashboardStats.claims_by_offer.map((o) => o.offer_name),
              datasets: [
                {
                  data: dashboardStats.claims_by_offer.map((o) =>
                    isShowingValue ? o.value : o.count
                  ),
                  backgroundColor: 'rgba(16, 185, 129, 0.2)', // emerald with opacity
                  borderColor: '#10B981', // emerald
                  borderWidth: 1,
                  borderRadius: 4,
                },
              ],
            }}
            options={{
              indexAxis: 'y',
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  callbacks: {
                    label: (context) => {
                      const offer =
                        dashboardStats.claims_by_offer[context.dataIndex]
                      if (isShowingValue) {
                        return `Value: ${formatUsd(context.parsed.x)}`
                      }
                      return `${context.parsed.x} claims (${formatUsd(
                        offer.value
                      )})`
                    },
                  },
                },
              },
              scales: {
                x: {
                  beginAtZero: true,
                  ticks: {
                    precision: 0,
                    callback: (value) =>
                      isShowingValue
                        ? formatUsd(value as number)
                        : formatInteger(value as number),
                  },
                },
              },
            }}
          />
        </div>

        {/* Monthly Claims Visualization */}
        <div className="w-full bg-white rounded-lg shadow p-6 mb-8">
          {/* Header and Controls */}
          <div className="flex flex-col space-y-6">
            <div className="flex items-center justify-between">
              <h3 className="text-lg font-semibold">Monthly Claims</h3>
            </div>

            <div className="grid grid-cols-2 gap-8 pb-4 border-b">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Chart Type
                </label>
                <RadioButtonSelect
                  control={control}
                  name="chartType"
                  options={[
                    {
                      label: 'Stacked',
                      key: 'stacked',
                      icon: BarChart2,
                    },
                    {
                      label: 'Line',
                      key: 'line',
                      icon: LineChart,
                    },
                  ]}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Data View
                </label>
                <RadioButtonSelect
                  control={control}
                  name="showAllClaims"
                  options={[
                    {
                      label: 'All Claims',
                      key: 'all',
                      icon: ChartPie,
                    },
                    {
                      label: 'By Offer',
                      key: 'select',
                      icon: BarChart2,
                    },
                  ]}
                />
              </div>
            </div>
          </div>

          {/* Chart */}
          <div className="h-96 mt-6">
            <ChartComponent
              data={{
                labels: filteredData.map((d: MonthlyTrendData) => {
                  const [year, month] = d.month.split('-')
                  const date = new Date(parseInt(year), parseInt(month) - 1, 1)
                  return format(date, 'MMM yyyy')
                }),
                datasets: isShowingAllClaims
                  ? [
                      {
                        label: 'All Claims',
                        data: filteredData.map((d: MonthlyTrendData) => {
                          if (!d.aggregate) return 0
                          return isShowingValue
                            ? d.aggregate.value
                            : d.aggregate.count
                        }),
                        backgroundColor: CHART_COLORS[0],
                        borderColor: CHART_COLORS[0],
                        borderWidth: chartType === 'line' ? 2 : 1,
                        tension: chartType === 'line' ? 0.4 : 0,
                        pointRadius: chartType === 'line' ? 4 : 0,
                      },
                    ]
                  : activeOffers.map((offer: string, index: number) => ({
                      label: offer,
                      data: filteredData.map((d: MonthlyTrendData) => {
                        const offerData = d[offer] as
                          | { count: number; value: number }
                          | undefined
                        if (!offerData) return 0
                        return isShowingValue
                          ? offerData.value
                          : offerData.count
                      }),
                      backgroundColor:
                        CHART_COLORS[index % CHART_COLORS.length],
                      borderColor: CHART_COLORS[index % CHART_COLORS.length],
                      borderWidth: chartType === 'line' ? 2 : 1,
                      tension: chartType === 'line' ? 0.4 : 0,
                      pointRadius: chartType === 'line' ? 4 : 0,
                      stack: chartType === 'stacked' ? 'stack0' : undefined,
                    })),
              }}
              options={{
                maintainAspectRatio: false,
                responsive: true,
                interaction: {
                  mode: 'index' as const,
                  intersect: false,
                },
                scales: {
                  x: {
                    stacked: chartType === 'stacked',
                  },
                  y: {
                    stacked: chartType === 'stacked',
                    beginAtZero: true,
                    ticks: {
                      callback: (value) =>
                        isShowingValue
                          ? formatUsd(value as number)
                          : formatInteger(value as number),
                    },
                  },
                },
                plugins: {
                  tooltip: {
                    callbacks: {
                      label: (context) => {
                        const value = context.parsed.y
                        return isShowingValue
                          ? `${context.dataset.label}: ${formatUsd(value)}`
                          : `${context.dataset.label}: ${formatInteger(
                              value
                            )} claims`
                      },
                    },
                  },
                  legend: {
                    position: 'bottom' as const,
                    labels: {
                      boxWidth: 12,
                      padding: 15,
                    },
                  },
                },
              }}
            />
          </div>
        </div>

        {/* Recent Claims */}
        <div className="w-full bg-white rounded-lg shadow p-6">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-lg font-semibold">Recent Claims</h3>
          </div>
          <RebateClaimsTable
            limit={10}
            isPaginationEnabled={false}
            tableKey="dashboard-recent-claims"
          />
        </div>
      </div>
    </div>
  )
}
